import { defineComponent as _defineComponent } from 'vue'
import { GeopointValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeopointValue',
  props: {
    value: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { value } = toRefs(props);

const linkUrl = computed(function () {
  const { lat, lon } = value.value.value; // uhh
  return `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lon}&zoom=17`;
});

const __returned__ = { props, value, linkUrl }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})