import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import useGraph from "@/common/composables/useGraph";
import useKnowledge from "@/common/composables/useKnowledge";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, ref, toRefs } from "vue";
import { MISSING_CONCEPT_COLOR } from "@/common/lib/conceptColors";


export default /*@__PURE__*/_defineComponent({
  __name: 'Metaconcept',
  props: {
    conceptType: { type: null, required: true },
    positionX: { type: Number, required: true },
    positionY: { type: Number, required: true }
  },
  emits: ["move"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { conceptType, positionX, positionY } = toRefs(props);

const exploreStore = useExploreStore();

const emit = __emit;

const dragStartX = ref(0);
const dragStartY = ref(0);
const preDragPositionX = ref(0);
const preDragPositionY = ref(0);
const isDragging = ref(false);

const { getConceptIconName, typeLabel } = useKnowledge();
const { getConceptsOfType } = useGraph(() => exploreStore.metagraph);

const name = computed(() => typeLabel(conceptType.value));
const iconName = computed(() => getConceptIconName(conceptType.value));

const top = computed(() => `${positionY.value}px`);
const left = computed(() => `${positionX.value}px`);

const isRoot = computed(() => exploreStore.root_concept_type === conceptType.value);
const propertyCount = computed(
  () => (getConceptsOfType(conceptType.value)[0].properties ?? []).length
);
const color = computed(
  () => exploreStore.conceptColors[conceptType.value] ?? MISSING_CONCEPT_COLOR
);

function startDragging(event: MouseEvent) {
  if (event.button != 0) return; // Only start dragging on left click
  dragStartX.value = event.pageX;
  dragStartY.value = event.pageY;
  preDragPositionX.value = positionX.value;
  preDragPositionY.value = positionY.value;
  document.addEventListener("mousemove", continueDragging);
  document.addEventListener("mouseup", finishDragging);
}

function continueDragging(event: MouseEvent) {
  const deltaX = event.pageX - dragStartX.value;
  const deltaY = event.pageY - dragStartY.value;
  if (deltaX != 0 || deltaY != 0) isDragging.value = true;
  emit("move", preDragPositionX.value + deltaX, preDragPositionY.value + deltaY);
  event.preventDefault();
}

function finishDragging(event: MouseEvent) {
  document.removeEventListener("mouseup", finishDragging);
  document.removeEventListener("mousemove", continueDragging);
  continueDragging(event); // Final position update
}

function click(event: MouseEvent) {
  if (isDragging.value) {
    // The 'click' at the end of a drag should have no effect
    event.stopImmediatePropagation();
    isDragging.value = false;
    return;
  }
  exploreStore.setRootConceptType(conceptType.value);
}

const __returned__ = { props, conceptType, positionX, positionY, exploreStore, emit, dragStartX, dragStartY, preDragPositionX, preDragPositionY, isDragging, getConceptIconName, typeLabel, getConceptsOfType, name, iconName, top, left, isRoot, propertyCount, color, startDragging, continueDragging, finishDragging, click, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})