import { defineComponent as _defineComponent } from 'vue'
import TextButton from "@/common/components/TextButton.vue";
import { asyncResultOr } from "@/common/lib/async";
import { sleep } from "@/common/lib/asynchronous";
import { useExploreStore } from "@/reader/stores/explore";
import { format as sql_format } from "sql-formatter";
import { getSegments, Segment } from "sql-highlight";
import { computed, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SqlView',
  setup(__props, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();

const displaySnackbar = ref(false);

const sqlData = computed(() => {
  const sql = asyncResultOr(exploreStore.sqlData, "");
  try {
    return sql_format(sql, {
      language: "spark",
      tabWidth: 4,
      keywordCase: "upper",
      linesBetweenQueries: 2,
    });
  } catch (error: unknown) {
    return sql;
  }
});

const sqlLines = computed(() => formatSql(sqlData.value));

function formatSql(sql: string): [Segment[], string][] {
  return sql.split(/\n/).map((line) => {
    const trimmed = line.trimStart();
    const indent = line.length - trimmed.length;
    const tokenized = getSegments(trimmed);
    return [tokenized, `${indent * 4}px`];
  });
}

async function copyContents() {
  await navigator.clipboard.writeText(sqlData.value);
  displaySnackbar.value = true;
  await sleep(2000);
  displaySnackbar.value = false;
}

const __returned__ = { exploreStore, displaySnackbar, sqlData, sqlLines, formatSql, copyContents, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})