import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-0 z-[1000]" }
const _hoisted_2 = { class: "absolute inset-0 flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "border border-gray-200 bg-white p-15 shadow-modal dark:border-gray-700 dark:bg-gray-900",
          style: _normalizeStyle($props.style)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 4 /* STYLE */)
      ])
    ])
  ]))
}