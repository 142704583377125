import axios from "axios";
import { defineStore } from "pinia";
import { isAxiosError } from "../http/http";
import { User } from "../lib/user";

export interface ServerLoginResponse {
  auth: {
    access_token: string;
    refresh_token?: string;
  };
  user?: User;
}

export type LoginResponse =
  | { success: true; response: ServerLoginResponse }
  | { success: false; error: string };

export interface RefreshTokenResponse {
  access_token: string;
}

export interface ResetPasswordResponse {
  email: string;
}

export const useBackendAuth = defineStore("backend-auth", {
  actions: {
    async login(email: string, password: string): Promise<LoginResponse> {
      const body = { email, password };
      try {
        const loginResponse = await axios.post<ServerLoginResponse>("/api/auth/login", body);
        return { success: true, response: loginResponse?.data };
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          return { success: false, error: error.response?.data.cause };
        }
        throw error;
      }
    },
    async refreshToken(refreshToken: string): Promise<RefreshTokenResponse> {
      const body = { refresh_token: refreshToken };
      // Use axios directly so we don't retry this.
      const response = await axios.post("/api/auth/refresh-token", body);
      return response?.data;
    },
    async resetPassword(email: string): Promise<ResetPasswordResponse> {
      const body = { email };
      // Use axios directly so we don't retry this.
      const response = await axios.post("/api/auth/reset-password", body);
      return response?.data;
    },
  },
});
