import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Dropdown"], {
    placement: "right",
    shown: $setup.isEditing,
    "onUpdate:shown": _cache[3] || (_cache[3] = ($event: any) => (($setup.isEditing) = $event)),
    onApplyShow: $setup.focusFieldIfPresent
  }, {
    popper: _withCtx(() => [
      _createVNode($setup["FilterEditor"], {
        label: $setup.formLabel,
        onCancel: _cache[2] || (_cache[2] = ($event: any) => ($setup.isEditing = false)),
        onCommit: $setup.commit,
        valid: $setup.isValid
      }, {
        default: _withCtx(() => [
          ($setup.valueType === $setup.GraphValueType.String)
            ? (_openBlock(), _createBlock($setup["Textbox"], {
                key: 0,
                label: "Values",
                "helper-text": "Enter one value per line",
                textarea: "",
                modelValue: $setup.stringValueUnderEdit,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.stringValueUnderEdit) = $event)),
                ref: "valueInputEl"
              }, null, 8 /* PROPS */, ["modelValue"]))
            : _createCommentVNode("v-if", true),
          ($setup.valueType === $setup.GraphValueType.Bool)
            ? (_openBlock(), _createBlock($setup["SelectStrip"], {
                key: 1,
                options: [
            { label: 'True', value: true },
            { label: 'False', value: false },
          ],
                modelValue: $setup.booleanValueUnderEdit,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.booleanValueUnderEdit) = $event)),
                class: "mb-5 h-[28px]"
              }, null, 8 /* PROPS */, ["modelValue"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "valid"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "cursor-pointer border-b border-b-transparent hover:border-b-gray-500",
        onClick: $setup.startEditing
      }, [
        _createTextVNode(_toDisplayString($setup.opDescription) + " ", 1 /* TEXT */),
        _createElementVNode("span", _hoisted_1, _toDisplayString($setup.valueList), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["shown"]))
}