import { defineComponent as _defineComponent } from 'vue'
import { useAuthProvider } from "@/common/auth/authStore";
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeUserButton',
  props: {
    modelValue: { type: Boolean, required: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

const selected = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const auth = useAuthProvider();
const userStore = useUserStore();
const userStoreRefs = storeToRefs(userStore);

const user = computed(() => userStoreRefs.user?.value);

function login() {
  auth.loginWithRedirect();
}

function toggle() {
  selected.value = !selected.value;
}
onMounted(() => {
  userStore.reloadUser();
});

const __returned__ = { props, emit, selected, auth, userStore, userStoreRefs, user, login, toggle, Icon, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})