import { defineComponent as _defineComponent } from 'vue'
import Textbox from "@/common/components/Textbox.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";
import { BackendAuthProvider } from "@/common/auth/backendAuthProvider";
import { useBackendAuth } from "@/common/auth/backendAuth";
import { useAuthProvider } from "@/common/auth/authStore";
import { isEmpty } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordPage',
  setup(__props, { expose: __expose }) {
  __expose();

const backendAuth = useBackendAuth();
const backendProvider = useAuthProvider();
const connecting = ref(false);

const mode = ref<"email" | "check">("email");
const email = ref("");

const router = useRouter();

function goToLogin() {
  router.push("/login");
}

async function resetPassword() {
  connecting.value = true;
  try {
    if (!(backendProvider instanceof BackendAuthProvider)) {
      throw new Error("Login page required backend auth provider");
    }
    if (isEmpty(email.value)) {
      return;
    }
    // Send login request to backend
    await backendAuth.resetPassword(email.value);
    mode.value = "check";
  } finally {
    connecting.value = false;
  }
}

const formValidated = computed(() => {
  if (email.value.trim().length <= 3 || !email.value.includes("@")) {
    return false;
  }
  return true;
});

const __returned__ = { backendAuth, backendProvider, connecting, mode, email, router, goToLogin, resetPassword, formValidated, Textbox, Start, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})