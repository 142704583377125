import { defineComponent as _defineComponent } from 'vue'
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from "vue";
import { getIconSizePx, IconSize } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'Spinner',
  props: {
    chill: { type: Boolean, required: false },
    size: { type: String, required: false, default: "xl" }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { chill, size } = toRefs(props);

const sizePx = computed(() => getIconSizePx(size.value));

const CHARACTERS = ["◴", "◷", "◶", "◵"];

const characterIndex = ref(0);
let intervalId: number;

function cycleCharacter() {
  if (characterIndex.value === CHARACTERS.length - 1) {
    characterIndex.value = 0;
  } else {
    characterIndex.value += 1;
  }
}

const character = computed(() => CHARACTERS[characterIndex.value]);

onMounted(() => (intervalId = setInterval(cycleCharacter, 200)));
onBeforeUnmount(() => clearInterval(intervalId));

const __returned__ = { props, chill, size, sizePx, CHARACTERS, characterIndex, get intervalId() { return intervalId }, set intervalId(v) { intervalId = v }, cycleCharacter, character }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})