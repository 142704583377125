<template>
  <template v-for="(row, rowIndex) in rows" :key="rowIndex">
    <tr
      :class="{
        'odd:bg-gray-100': row.contents == null && !row.highlight,
        'cursor-pointer hover:bg-[#bbbbfa]': row.contents != null,
        'bg-[#e3e3ff] odd:bg-[#d5d5ff]': row.highlight,
      }"
      @click="row.contents != null && toggle(expanded, rowIndex)"
    >
      <td>
        <div class="flex flex-row items-center text-nowrap font-bold" :style="indentStyle">
          <Icon
            v-if="row.contents != null"
            :name="expanded.has(rowIndex) ? 'md:keyboard_arrow_down' : 'md:keyboard_arrow_right'"
            color="medium-gray"
            size="xl"
          />
          <div v-else class="w-[25px]"></div>
          {{ row.label }}
        </div>
      </td>
      <template v-for="(colName, colIndex) in columns" :key="colName">
        <td class="p-10 align-baseline">
          <div :style="colIndex == 0 ? indentStyle : undefined">
            <div class="text-nowrap text-right" v-if="row.cells[colIndex].value != null">
              <GraphValueComponent :value="row.cells[colIndex].value.formattedValue" />
            </div>
            <div class="text-right opacity-50" v-else>-</div>
          </div>
        </td>
        <td v-if="colIndex > 0" class="p-10 align-baseline">
          <div
            class="text-nowrap text-right opacity-50"
            v-if="row.cells[colIndex].overPreviousPeriod != null"
          >
            <GraphValueComponent :value="row.cells[colIndex].overPreviousPeriod?.formattedValue" />
          </div>
          <div class="text-right opacity-50" v-else>-</div>
        </td>
      </template>

      <template v-for="(colName, colIndex) in trailingColumns" :key="colName">
        <td class="p-10 align-baseline">
          <div :style="colIndex == 0 ? indentStyle : undefined">
            <div
              class="text-nowrap text-right"
              v-if="row.cells[colIndex + columns.length].value?.formattedValue"
            >
              <GraphValueComponent
                :value="row.cells[colIndex + columns?.length].value?.formattedValue"
              />
            </div>
            <div class="text-right opacity-50" v-else>-</div>
          </div>
        </td>
      </template>
    </tr>
    <FinancialSubtable
      v-if="row.contents != null && expanded.has(rowIndex)"
      :rows="row.contents"
      :columns="columns"
      :depth="depth + 1"
      :trailingColumns="trailingColumns"
    />
  </template>
</template>

<script lang="ts" setup>
import { FinComputedRow } from "../FinancialStatement.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { toggle } from "@/common/lib/set";
import { computed, ref, Ref, toRefs } from "vue";

const props = defineProps<{
  rows: FinComputedRow[];
  columns: string[];
  trailingColumns: string[];
  depth: number;
}>();
const { depth } = toRefs(props);

const expanded: Ref<Set<number>> = ref(new Set());

const indentStyle = computed(() => ({ marginLeft: `${15 * depth.value}px` }));
</script>
