import { defineComponent as _defineComponent } from 'vue'
import { propertyName, propertyValueType } from "@/common/lib/derived";
import { EqualityFilter } from "@/common/lib/fetchApi";
import { QueryFilter } from "@/common/lib/query";
import { GraphValueType, stringifyValue, toNative, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, onMounted, Ref, ref, toRefs } from "vue";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import Textbox from "@/common/components/Textbox.vue";
import SelectStrip from "@/editor/components/SelectStrip.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EqualityFilter',
  props: {
    filter: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { filter } = toRefs(props);

const isEditing: Ref<boolean> = ref(false);
const valueUnderEdit: Ref<string | boolean | undefined> = ref(undefined);
const stringValueUnderEdit = valueUnderEdit as Ref<string | undefined>;
const booleanValueUnderEdit = valueUnderEdit as Ref<boolean>;
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);

function negatedDescription() {
  return filter.value.negated ? "is not" : "is";
}

const valueType = computed(() => propertyValueType(filter.value.property_type) as GraphValueType);

const opDescription = computed(function () {
  let desc = negatedDescription();
  if (filter.value.values.length > 1) desc = desc + " any of";
  return desc;
});

const formLabel = computed(function () {
  const bits = [propertyName(filter.value.property_type), negatedDescription()];
  if (valueType.value === GraphValueType.String) bits.push("any of");
  return bits.join(" ");
});

const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);

const isValid = computed(
  () => valueType.value === GraphValueType.Bool || cleanStringInput().length > 0
);

const exploreStore = useExploreStore();

function startEditing() {
  if (valueType.value === GraphValueType.Bool) {
    if (filter.value.values.length > 0) {
      valueUnderEdit.value = toNative(filter.value.values[0].value) as boolean;
    } else {
      valueUnderEdit.value = true; // Arbitrary default
    }
  } else {
    valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
  }
  isEditing.value = true;
}

function cleanStringInput() {
  return stringValueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  if (valueType.value === GraphValueType.Bool) {
    filter.value.values = [{ value: toValue(booleanValueUnderEdit.value) }];
  } else {
    filter.value.values = cleanStringInput().map((v) => ({ value: toValue(v) }));
  }
  isEditing.value = false;
  exploreStore.load();
}

function focusFieldIfPresent() {
  setTimeout(() => valueInputEl.value?.focus(), 50); // Boy do I hate this
}

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});

const __returned__ = { props, filter, isEditing, valueUnderEdit, stringValueUnderEdit, booleanValueUnderEdit, valueInputEl, negatedDescription, valueType, opDescription, formLabel, valueList, isValid, exploreStore, startEditing, cleanStringInput, commit, focusFieldIfPresent, get GraphValueType() { return GraphValueType }, get Dropdown() { return Dropdown }, FilterEditor, Textbox, SelectStrip }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})