import { defineComponent as _defineComponent } from 'vue'
import { useAuthProvider } from "@/common/auth/authStore";
import Dialog from "@/common/components/Dialog.vue";
import TabSelect from "@/common/components/TabSelect.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import { useSettingsStore, Routes } from "@/common/stores/settingsStore";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import ConnectionSettings from "@/common/components/settings/ConnectionSettings.vue";
import { useRouter } from "vue-router";
import MessageBar from "./MessageBar.vue";
import AdminPage from "@/common/components/admin/AdminPage.vue";

interface ChangePassword {
  newPassword: string;
  confirmNewPassword: string;
  error?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserSettings',
  setup(__props, { expose: __expose }) {
  __expose();

const auth = useAuthProvider();
const editingUserName = ref<string | undefined>();
const editingHomePage = ref<string | undefined>();
const editingChangePassword = ref<ChangePassword | undefined>();

const userStore = useUserStore();
const router = useRouter();
const { user, userSettings } = storeToRefs(userStore);

const { availableTabs, selectedTab } = storeToRefs(useSettingsStore());

async function resync() {
  await userStore.resyncUser();
}

function logout() {
  auth.logout(window.location.origin + environment.require("AUTH0_LOGOUT_PATH"));
}

function editUserName() {
  editingUserName.value = user?.value?.name;
}

async function finishEditUserName() {
  if (!editingUserName.value) {
    return;
  }
  const success = await userStore.updateUserName(editingUserName.value);
  if (!success) {
    return;
  }
  editingUserName.value = undefined;
}

async function finishChangePassword() {
  if (!editingChangePassword.value) {
    return;
  }
  editingChangePassword.value.error = undefined;
  const result = await userStore.updateUserPassword(editingChangePassword.value.newPassword);
  if (result.error) {
    editingChangePassword.value.error = result.error;
    return;
  }
  editingChangePassword.value = undefined;
}

function editHomePage() {
  editingHomePage.value = userSettings?.value?.default_homepage ?? "";
}

async function finishEditHomePage() {
  userSettings.value ??= {};
  userSettings.value.default_homepage =
    editingHomePage.value?.trim() === "" ? undefined : editingHomePage.value;
  const success = await userStore.updateUserSettings(userSettings.value);
  if (!success) {
    return;
  }
  editingHomePage.value = undefined;
}

watch(selectedTab, (newValue) => {
  switch (newValue) {
    case "connection":
      router.replace({ name: Routes.Connections });
      break;
    case "preferences":
      router.replace({ name: Routes.Preferences });
      break;
    case "admin":
      router.replace({ name: Routes.Admin });
      break;
    default:
      router.replace({ name: Routes.UserSettings });
      break;
  }
});
onMounted(() => {
  userStore.reloadUser();
});

const __returned__ = { auth, editingUserName, editingHomePage, editingChangePassword, userStore, router, user, userSettings, availableTabs, selectedTab, resync, logout, editUserName, finishEditUserName, finishChangePassword, editHomePage, finishEditHomePage, Dialog, TabSelect, Textbox, TextButton, ConnectionSettings, MessageBar, AdminPage }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})