import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { DerivedPropertyTerm, SubtractPropertyType, PropertyOpType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import MultiTerm from "./MultiTerm.vue";
import { isNull } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'SubtractPropertyDef',
  props: {
    "modelValue": { type: [Object, null], ...{ required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const OP = PropertyOpType.Subtract;

const terms: Ref<(DerivedPropertyTerm | null)[]> = ref([null, null]);

const model = _useModel<SubtractPropertyType | null>(__props, "modelValue");

watchEffect(function () {
  if (terms.value.some((t) => isNull(t))) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      terms: terms.value as DerivedPropertyTerm[],
    };
  }
});

const __returned__ = { OP, terms, model, MultiTerm }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})