import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-10" }
const _hoisted_2 = { class: "text-lg text-white" }
const _hoisted_3 = { class: "text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock($setup["Start"], { title: "Create your claritype account" }, {
    footer: _withCtx(() => [
      _createVNode($setup["TextButton"], { label: "Create" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", null, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-lg text-white" }, " Please provide an email address and a unique password for your claritype account. ", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createTextVNode(" Already have claritype account? ")),
            _createVNode(_component_RouterLink, {
              to: "/login",
              class: "text-orange"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Log in")
              ])),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode($setup["Textbox"], {
              label: "Name",
              autocomplete: "name",
              id: "name-field"
            }),
            _createVNode($setup["Textbox"], {
              label: "Email",
              type: "email",
              autocomplete: "email",
              id: "email-field"
            }),
            _createVNode($setup["Textbox"], {
              label: "Create password",
              type: "password",
              autocomplete: "new-password",
              id: "password-field"
            }),
            _createVNode($setup["Textbox"], {
              label: "Retype password",
              type: "password",
              autocomplete: "new-password",
              id: "confirm-password-field"
            }),
            _createVNode($setup["Textbox"], {
              label: "Company name (optional)",
              autocomplete: "organization",
              id: "company-field"
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["Checkbox"], {
              label: "Get all the pro tips, tricks, and updates with our newsletter.*",
              "model-value": true,
              id: "newsletter-checkbox"
            }),
            _createVNode($setup["Checkbox"], {
              label: "I’d like a demo to find out more.",
              "model-value": false,
              id: "demo-checkbox"
            })
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col gap-20 text-sm text-gray-500" }, [
            _createElementVNode("div", null, "By submitting this form, you agree to the terms of service and privacy policy."),
            _createElementVNode("div", null, " *By checking the box and entering your email address above, you consent to receive marketing emails (such as newsletters, blog posts, webinars, event invitations and new product updates) from claritype from time to time. You can unsubscribe at any time by clicking on the “Unsubscribe” link at the bottom of our emails. For more information on how we process your personal information and what rights you have in this respect, please see our privacy policy. ")
          ], -1 /* HOISTED */))
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}