import { defineComponent as _defineComponent } from 'vue'
import { stringifyValue, toNative, toTypedValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { isNumber, isString } from "lodash";
import { computed, onMounted, Ref, ref, toRefs } from "vue";
import { GraphValueType } from "@/common/lib/value";
import { propertyName, propertyValueType } from "@/common/lib/derived";
import { QueryFilter } from "@/common/lib/query";
import { RangeFilter } from "@/common/lib/fetchApi";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import Textbox from "@/common/components/Textbox.vue";
import { DateTime } from "luxon";


export default /*@__PURE__*/_defineComponent({
  __name: 'RangeFilter',
  props: {
    filter: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { filter } = toRefs(props);

const exploreStore = useExploreStore();

const lowerBoundUnderEdit: Ref<number | string | "" | undefined> = ref(undefined);
const upperBoundUnderEdit: Ref<number | string | "" | undefined> = ref(undefined);
const lowerBoundInputEl: Ref<HTMLInputElement | null> = ref(null);
const isEditing: Ref<boolean> = ref(false);

const opDescription = computed(() => (filter.value.negated ? "is not" : "is"));
const formLabel = computed(
  () => `${propertyName(filter.value.property_type)} ${opDescription.value} between`
);

const filterType = computed(() => {
  switch (propertyValueType(filter.value.property_type)) {
    case GraphValueType.Date:
      return "date";
    case GraphValueType.Datetime:
      return "datetime-local";
    default:
      return "number";
  }
});

function startEditing() {
  if (lowerBound.value == null) {
    lowerBoundUnderEdit.value = "";
  } else if (filterType.value === "number") {
    lowerBoundUnderEdit.value = toNative(lowerBound.value) as number;
  } else {
    lowerBoundUnderEdit.value = lowerBound.value.value as string;
  }
  if (upperBound.value == null) {
    upperBoundUnderEdit.value = "";
  } else if (filterType.value === "number") {
    upperBoundUnderEdit.value = toNative(upperBound.value) as number;
  } else {
    upperBoundUnderEdit.value = upperBound.value.value as string;
  }
  isEditing.value = true;
}

function focusFirstField() {
  setTimeout(() => lowerBoundInputEl.value?.focus(), 50); // Boy do I hate this
}

function commit() {
  const rangeFilter: Partial<RangeFilter> = {};
  if (isNumber(lowerBoundUnderEdit.value)) {
    rangeFilter.gte = toTypedValue(lowerBoundUnderEdit.value, valueType.value);
  }
  if (isNumber(upperBoundUnderEdit.value)) {
    rangeFilter.lte = toTypedValue(upperBoundUnderEdit.value, valueType.value);
  }
  if (isString(lowerBoundUnderEdit.value) && lowerBoundUnderEdit.value !== "") {
    rangeFilter.gte = {
      _type: filterType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: lowerBoundUnderEdit.value,
    };
  }
  if (isString(upperBoundUnderEdit.value) && upperBoundUnderEdit.value !== "") {
    rangeFilter.lte = {
      _type: filterType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: upperBoundUnderEdit.value,
    };
  }
  filter.value.values = [rangeFilter];
  isEditing.value = false;
  exploreStore.load();
}

const lowerBound = computed(() => filter.value.values[0]?.gte ?? filter.value.values[0]?.gt);
const upperBound = computed(() => filter.value.values[0]?.lte ?? filter.value.values[0]?.lt);
const isExact = computed(() => lowerBound.value?.value === upperBound.value?.value);
const isEditValid = computed(function () {
  // At least one bound must be present
  let lowerIsSet, upperIsSet;
  if (isDate.value) {
    lowerIsSet = !!lowerBoundUnderEdit.value;
    upperIsSet = !!upperBoundUnderEdit.value;
  } else {
    lowerIsSet = isNumber(lowerBoundUnderEdit.value);
    upperIsSet = isNumber(upperBoundUnderEdit.value);
  }
  if (!lowerIsSet && !upperIsSet) return false;

  // If both bounds are present, bounds must not be inverted
  if (lowerIsSet && upperIsSet) {
    if (isDate.value) {
      const lowerDate = DateTime.fromISO(lowerBoundUnderEdit.value as string);
      const upperDate = DateTime.fromISO(upperBoundUnderEdit.value as string);
      if (lowerDate > upperDate) return false;
    } else {
      if (lowerBoundUnderEdit.value! > upperBoundUnderEdit.value!) return false;
    }
  }

  return true;
});

const valueType = computed(() => propertyValueType(filter.value.property_type) as GraphValueType);

const isDate = computed(() =>
  [GraphValueType.Date, GraphValueType.Datetime].includes(valueType.value)
);

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});

const __returned__ = { props, filter, exploreStore, lowerBoundUnderEdit, upperBoundUnderEdit, lowerBoundInputEl, isEditing, opDescription, formLabel, filterType, startEditing, focusFirstField, commit, lowerBound, upperBound, isExact, isEditValid, valueType, isDate, get stringifyValue() { return stringifyValue }, get Dropdown() { return Dropdown }, FilterEditor, Textbox }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})