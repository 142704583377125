import { defineComponent as _defineComponent } from 'vue'
import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Icon from "./Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Menu',
  props: {
    items: { type: Array, required: true },
    minWidth: { type: String, required: false }
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

const useIcons = props.items.find((item) => !!item.icon) ?? false;

function select(item: MenuItem) {
  if (!item.disabled) {
    item.action?.();
    emit("select");
  }
}

const __returned__ = { props, emit, useIcons, select, get Dropdown() { return Dropdown }, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})