import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import { computed, Ref, ref } from "vue";
import Textbox from "@/common/components/Textbox.vue";
import { useSentry } from "@/common/monitoring/sentry/sentryStore";
import { environment } from "@/common/environments/environmentLoader";
import { storeToRefs } from "pinia";
import { useFailureStore } from "../stores/failureStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'FailureDialog',
  setup(__props, { expose: __expose }) {
  __expose();

const copyableText: Ref<HTMLTextAreaElement | undefined> = ref();
const showErrorDetails = ref(false);
const showBugReporter = ref(false);

const failureStore = useFailureStore();
const { failure, additionalContext, canUndo, undo } = storeToRefs(failureStore);

const userBugReport = ref("");

const details = computed(() => {
  if (failure?.value === undefined) {
    return undefined;
  }
  const components = {
    "Error Type": failure.value.type,
    Description: failure.value.description,
    Endpoint: failure.value.endpoint,
    "Exception Name": failure.value.exceptionName,
    "Exception Message": failure.value.message,
    "Exception Cause": failure.value.exceptionCause,
    "Exception Stack": failure.value.exceptionStack,
    Request: JSON.stringify(failure.value.request, undefined, 2),
    Response: JSON.stringify(failure.value.response, undefined, 2),
  };
  if (additionalContext?.value) {
    Object.assign(components, additionalContext.value());
  }
  return Object.entries(components)
    .filter((entry) => entry[1] !== undefined)
    .map(([key, value]) => `## ${key}\n\n${value}\n\n`)
    .join("");
});

function copyReport() {
  copyableText.value?.select();
  document.execCommand("copy");
}

function submitBugReport(eventId: string) {
  if (failure?.value == undefined) {
    return;
  }
  useSentry().sentry?.captureUserFeedback(failure.value.source, eventId, userBugReport.value);
  close();
}

function handleUndo() {
  undo.value();
  close();
}

function close() {
  failureStore.clearFailure();
}

const __returned__ = { copyableText, showErrorDetails, showBugReporter, failureStore, failure, additionalContext, canUndo, undo, userBugReport, details, copyReport, submitBugReport, handleUndo, close, Icon, TextButton, Textbox, get environment() { return environment } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})