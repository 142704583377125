import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import EditConnectionSettings from "@/common/components/settings/EditConnectionSettings.vue";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import { onMounted, ref, inject, Ref, computed, watch } from "vue";
import Dialog from "@/common/components/Dialog.vue";
import { DarkMode } from "@/common/lib/keys";
import { storeToRefs } from "pinia";
import { asyncResultOr, AsyncStatus, asyncValue } from "@/common/lib/async";
import WorkspaceChooser from "../WorkspaceChooser.vue";
import WarehouseChooser from "../WarehouseChooser.vue";
import { isNil } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectionSettings',
  setup(__props, { expose: __expose }) {
  __expose();

const workspaceStore = useWorkspaceStore();
const { currentWorkspace, currentWorkspaceId, workspaces } = storeToRefs(workspaceStore);

const mode = ref<"view" | "edit" | "create">("view");
const deletingWorkspace = ref(false);

function updateWorkspace() {
  mode.value = "view";
}

async function deleteWorkspace() {
  await workspaceStore.deleteCurrentWorkspace();
  deletingWorkspace.value = false;
}

watch(
  () => workspaces.value,
  (workspacesAsync) => {
    const workspaces = asyncValue(workspacesAsync);
    if (isNil(workspaces)) {
      return;
    }
    if (workspaces.length === 0) {
      mode.value = "create";
    }
  },
  { immediate: true }
);

onMounted(async () => {
  await workspaceStore.initializeWorkspaceState();
});

const darkMode = inject(DarkMode) as Ref<boolean>;
const iconColor = computed(() => (darkMode.value ? "white" : "black"));

const __returned__ = { workspaceStore, currentWorkspace, currentWorkspaceId, workspaces, mode, deletingWorkspace, updateWorkspace, deleteWorkspace, darkMode, iconColor, Icon, TextButton, EditConnectionSettings, Dialog, get asyncResultOr() { return asyncResultOr }, get AsyncStatus() { return AsyncStatus }, WorkspaceChooser, WarehouseChooser }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})