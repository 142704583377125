import { resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "file-menu",
  "data-test": "project-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock($setup["Dropdown"], { class: "flex justify-start self-center" }, {
    popper: _withCtx(() => [
      _withDirectives(_createVNode($setup["BookmarksList"], {
        onUpdateBookmark: $setup.updateBookmark,
        onDisplayError: $setup.displayError,
        onDeleteBookmark: $setup.deleteBookmark,
        projectId: $setup.props.projectId
      }, null, 8 /* PROPS */, ["projectId"]), [
        [_directive_close_popper]
      ])
    ]),
    default: _withCtx(() => [
      _createVNode($setup["Tooltip"], { delay: { show: 1000, hide: 100 } }, {
        popper: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Show bookmarks")
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["IconButton2"], {
              name: "md:folder_special",
              size: "xl"
            })
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}