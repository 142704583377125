import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 1,
  class: "w-[25px]"
}
const _hoisted_3 = { class: "p-10 align-baseline" }
const _hoisted_4 = {
  key: 0,
  class: "text-nowrap text-right"
}
const _hoisted_5 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_6 = {
  key: 0,
  class: "p-10 align-baseline"
}
const _hoisted_7 = {
  key: 0,
  class: "text-nowrap text-right opacity-50"
}
const _hoisted_8 = {
  key: 1,
  class: "text-right opacity-50"
}
const _hoisted_9 = {
  key: 0,
  class: "text-nowrap text-right"
}
const _hoisted_10 = {
  key: 1,
  class: "text-right opacity-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FinancialSubtable = _resolveComponent("FinancialSubtable", true)!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.rows, (row, rowIndex) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: rowIndex }, [
      _createElementVNode("tr", {
        class: _normalizeClass({
        'odd:bg-gray-100': row.contents == null && !row.highlight,
        'cursor-pointer hover:bg-[#bbbbfa]': row.contents != null,
        'bg-[#e3e3ff] odd:bg-[#d5d5ff]': row.highlight,
      }),
        onClick: ($event: any) => (row.contents != null && $setup.toggle($setup.expanded, rowIndex))
      }, [
        _createElementVNode("td", null, [
          _createElementVNode("div", {
            class: "flex flex-row items-center text-nowrap font-bold",
            style: _normalizeStyle($setup.indentStyle)
          }, [
            (row.contents != null)
              ? (_openBlock(), _createBlock($setup["Icon"], {
                  key: 0,
                  name: $setup.expanded.has(rowIndex) ? 'md:keyboard_arrow_down' : 'md:keyboard_arrow_right',
                  color: "medium-gray",
                  size: "xl"
                }, null, 8 /* PROPS */, ["name"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_2)),
            _createTextVNode(" " + _toDisplayString(row.label), 1 /* TEXT */)
          ], 4 /* STYLE */)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (colName, colIndex) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: colName }, [
            _createElementVNode("td", _hoisted_3, [
              _createElementVNode("div", {
                style: _normalizeStyle(colIndex == 0 ? $setup.indentStyle : undefined)
              }, [
                (row.cells[colIndex].value != null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode($setup["GraphValueComponent"], {
                        value: row.cells[colIndex].value.formattedValue
                      }, null, 8 /* PROPS */, ["value"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, "-"))
              ], 4 /* STYLE */)
            ]),
            (colIndex > 0)
              ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                  (row.cells[colIndex].overPreviousPeriod != null)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode($setup["GraphValueComponent"], {
                          value: row.cells[colIndex].overPreviousPeriod?.formattedValue
                        }, null, 8 /* PROPS */, ["value"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, "-"))
                ]))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.trailingColumns, (colName, colIndex) => {
          return (_openBlock(), _createElementBlock("td", {
            key: colName,
            class: "p-10 align-baseline"
          }, [
            _createElementVNode("div", {
              style: _normalizeStyle(colIndex == 0 ? $setup.indentStyle : undefined)
            }, [
              (row.cells[colIndex + $props.columns.length].value?.formattedValue)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode($setup["GraphValueComponent"], {
                      value: row.cells[colIndex + $props.columns?.length].value?.formattedValue
                    }, null, 8 /* PROPS */, ["value"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, "-"))
            ], 4 /* STYLE */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 10 /* CLASS, PROPS */, _hoisted_1),
      (row.contents != null && $setup.expanded.has(rowIndex))
        ? (_openBlock(), _createBlock(_component_FinancialSubtable, {
            key: 0,
            rows: row.contents,
            columns: $props.columns,
            depth: $setup.depth + 1,
            trailingColumns: $props.trailingColumns
          }, null, 8 /* PROPS */, ["rows", "columns", "depth", "trailingColumns"]))
        : _createCommentVNode("v-if", true)
    ], 64 /* STABLE_FRAGMENT */))
  }), 128 /* KEYED_FRAGMENT */))
}