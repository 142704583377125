import { defineComponent as _defineComponent } from 'vue'
import MenuListItem from "@/common/components/MenuListItem.vue";
import SourceBrowser from "@/common/components/SourceBrowser.vue";
import TextButton from "@/common/components/TextButton.vue";
import Textbox from "@/common/components/Textbox.vue";
import { useNavigation } from "@/common/composables/useNavigation";
import { environment } from "@/common/environments/environmentLoader";
import { AsyncStatus, asyncSucceeded } from "@/common/lib/async";
import { MapAction } from "@/common/lib/mapActions";
import { useWorkspaceStore, WorkspacesState } from "@/common/stores/workspaceStore";
import { SourceSelection } from "@/common/stores/sourceBrowser";
import { useSettingsStore, Routes } from "@/common/stores/settingsStore";
import UserButton from "@/home/components/user-home/HomeUserButton.vue";

import UserSettings from "@/common/components/UserSettings.vue";
import Modules from "@/home/components/user-home/Modules.vue";
import { ModuleMetadata, ModuleType, useUserModuleStore } from "@/common/stores/userModuleStore";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import HomeTitleBar from "../components/user-home/HomeTitleBar.vue";
import { capitalize, isEmpty, uniq } from "lodash";
import { useResizeListener } from "@/common/composables/useResizeListener";
import { useRouter } from "vue-router";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { KeyCommand } from "@/common/lib/modifierKeys";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserHome',
  setup(__props, { expose: __expose }) {
  __expose();

const userStore = useUserModuleStore();
const navigation = useNavigation();
const settingStore = useSettingsStore();
const workspaceStore = useWorkspaceStore();
const router = useRouter();

const { workspacesState } = storeToRefs(workspaceStore);
const { subPage, userSelected } = storeToRefs(settingStore);
const { currentModuleType } = storeToRefs(userStore);

const activeFilter = computed(() => currentModuleType.value);
const sourceBrowserExpanded = ref(true);
const projectSearchRef = ref("");
const parentDiv = ref<HTMLElement | null>(null);
const divHeight = ref(0);

const calculateDivHeight = () => {
  divHeight.value = parentDiv.value?.offsetHeight ?? 0;
};

const modules = computed(() => {
  const modules = userStore.modules;
  if (modules.status !== AsyncStatus.Succeeded) {
    return modules;
  }
  return asyncSucceeded(
    modules.result.filter((m) => {
      const searchTerm = projectSearchRef.value.toLowerCase();
      return (
        m.name.toLowerCase().includes(searchTerm) ||
        (activeFilter.value === ModuleType.Published &&
          m.owner_name?.toLowerCase().includes(searchTerm))
      );
    })
  );
});

const searchProjectsBox = ref<typeof Textbox | undefined>(undefined);
const findKeyCommand = KeyCommand.readKeyCommand(
  environment.require("KEY_COMMAND_HOME_SEARCH_PROJECTS")
);
useKeyHandler().registerHandler(
  findKeyCommand,
  () => searchProjectsBox.value?.focus(),
  true,
  false
);

watch(userSelected, (newValue) => {
  if (newValue === false) {
    router.replace({ name: Routes.Home });
  } else {
    if (settingStore.selectedTab === "connection") {
      router.replace({ name: Routes.Connections });
    } else if (settingStore.selectedTab === "admin") {
      router.replace({ name: Routes.Admin });
    } else {
      router.replace({ name: Routes.UserSettings });
    }
  }
});

function setModuleType(moduleType: ModuleType) {
  userSelected.value = false;
  userStore.setModuleType(moduleType);
}

async function sourceBrowserTablesSelected(selections: SourceSelection[]) {
  const action = new MapAction({});
  selections.forEach((s) => action.addDbxTable(s.table));
  const moduleId = await userStore.newModule();
  let name = undefined;
  if (environment.requireBoolean("USE_TABLE_NAME_AS_PROJECT_NAME")) {
    name = autogenerateProjectName(selections);
  }
  await userStore.updateModule(moduleId, { name, map: action.map });
  navigation.openProject(moduleId, true);
}

async function newProject() {
  const moduleId = await userStore.newModule();
  await userStore.updateModule(moduleId, { map: { in_connections: {} } });
  navigation.openProject(moduleId, true);
}

async function openModule(module: ModuleMetadata) {
  switch (activeFilter.value) {
    case ModuleType.User: {
      navigation.openProject(module.id);
      break;
    }
    case ModuleType.Published: {
      // Load published module
      const copyId = await userStore.duplicatePublishedModule(module.id);
      navigation.openProject(copyId);
      break;
    }
    case ModuleType.Local: {
      // Load local module
      const copyId = await userStore.duplicateLocalModule(module.id);
      navigation.openProject(copyId);
      break;
    }
    case ModuleType.Reader: {
      const moduleId = module.tag ?? module.id;
      navigation.goToUrl(`/reader/modules/${moduleId}/explore`);
    }
  }
}

async function deleteModule(modules: ModuleMetadata[]) {
  switch (activeFilter.value) {
    case ModuleType.User: {
      await Promise.all(modules.map((m) => userStore.deleteModule(m.id)));
      break;
    }
    case ModuleType.Published: {
      await Promise.all(modules.map((m) => userStore.deletePublishedModule(m.id)));
      await userStore.loadPublished();
      break;
    }
  }
}

function showFilter(moduleType: ModuleType): boolean {
  if (userSelected.value) {
    return false;
  }
  return activeFilter.value === moduleType;
}

function adjustColumnWidth(expanded: boolean) {
  sourceBrowserExpanded.value = expanded;
}

function autogenerateProjectName(selections: SourceSelection[]): string | undefined {
  if (isEmpty(selections)) {
    return undefined;
  }
  if (selections.length > 1) {
    // Try schema name if multiple selections are all in the same schema
    const schemas = uniq(selections.map((sel) => sel.table.schema));
    if (schemas.length === 1) {
      return prettifyTableName(schemas[0]);
    }
  }
  return prettifyTableName(selections[0].table.name);
}

function prettifyTableName(tableName: string): string {
  return tableName.replaceAll(/[_-]/g, " ").split(" ").map(capitalize).join(" ");
}

useResizeListener(calculateDivHeight);

onMounted(() => {
  calculateDivHeight();
});

watch(
  () => workspaceStore.currentWorkspace,
  (workspace) => {
    if (workspace.status !== AsyncStatus.Succeeded) {
      userStore.setStatus(workspace);
      return;
    }
    if (workspace.result?.connected) {
      userStore.boot(workspace.result.id, ModuleType.User);
    } else {
      router.replace({ name: Routes.Connections });
    }
  }
);

const __returned__ = { userStore, navigation, settingStore, workspaceStore, router, workspacesState, subPage, userSelected, currentModuleType, activeFilter, sourceBrowserExpanded, projectSearchRef, parentDiv, divHeight, calculateDivHeight, modules, searchProjectsBox, findKeyCommand, setModuleType, sourceBrowserTablesSelected, newProject, openModule, deleteModule, showFilter, adjustColumnWidth, autogenerateProjectName, prettifyTableName, MenuListItem, SourceBrowser, TextButton, Textbox, get environment() { return environment }, get WorkspacesState() { return WorkspacesState }, UserButton, UserSettings, Modules, get ModuleType() { return ModuleType }, HomeTitleBar }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})