import { defineComponent as _defineComponent } from 'vue'
import { DerivedPropertyTerm, propertyName } from "@/common/lib/derived";
import TextButton from "@/common/components/TextButton.vue";
import { useExploreStore } from "@/reader/stores/explore";
import { v4 as uuidv4 } from "uuid";
import { computed, Ref, ref } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import { expandTreePath } from "@/reader/lib/exploreTree";


export default /*@__PURE__*/_defineComponent({
  __name: 'CalculationForm',
  setup(__props, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();

const derivedTerm: Ref<DerivedPropertyTerm | null> = ref(null);

const isInvalid = computed(() => derivedTerm.value == null);

const description = computed(function () {
  if (derivedTerm.value == null) return "Please complete all fields above.";
  return "Property Name: " + propertyName(derivedTerm.value);
});

function commit() {
  const term = derivedTerm.value;
  if (term == null) return;
  exploreStore.addColumn({
    alias: uuidv4(),
    property_type: term,
    path: expandTreePath(exploreStore.creatingCalculation!),
  });
  exploreStore.creatingCalculation = undefined;
}

const __returned__ = { exploreStore, derivedTerm, isInvalid, description, commit, TextButton, DerivedPropertyDef }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})