import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "main" }
const _hoisted_2 = { id: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { id: "modal-container" }, null, -1 /* HOISTED */)),
    _createVNode($setup["FailureDialog"])
  ], 64 /* STABLE_FRAGMENT */))
}