import { defineComponent as _defineComponent } from 'vue'
import TextButton from "@/common/components/TextButton.vue";
import Dialog from "@/common/components/Dialog.vue";
import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SaveButtons',
  props: {
    mode: { type: String, required: false, default: "edit" },
    needsSave: { type: Boolean, required: false },
    canSave: { type: Boolean, required: false, default: true },
    label: { type: String, required: false }
  },
  emits: ["save", "cancelled"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;
const showConfirmDialog = ref(false);

function cancel() {
  if (props.needsSave) {
    showConfirmDialog.value = true;
  } else {
    emit("cancelled");
  }
}

function handleCancelClose() {
  showConfirmDialog.value = false;
}

function handleConfirmClose() {
  showConfirmDialog.value = false;
  emit("cancelled");
}

const __returned__ = { props, emit, showConfirmDialog, cancel, handleCancelClose, handleConfirmClose, TextButton, Dialog }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})