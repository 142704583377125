<template>
  <template v-for="group in subtable.groups ?? []" :key="group.id">
    <tr>
      <td
        class="cursor-pointer hover:bg-gray-100"
        :colspan="columns.length"
        @click="toggle(collapsed, group.id)"
        :style="indentStyle"
      >
        <div class="flex flex-row items-center font-bold">
          <Icon
            :name="collapsed.has(group.id) ? 'md:keyboard_arrow_up' : 'md:keyboard_arrow_down'"
            color="medium-gray"
            size="xl"
          />
          <div class="px-5 py-10">
            <GraphValueComponent :value="group.name" />
          </div>
        </div>
      </td>
    </tr>
    <Subtable
      v-if="!collapsed.has(group.id)"
      :subtable="group.contents"
      :columns="columns"
      :depth="depth + 1"
    />
  </template>
  <tr
    v-for="(row, rowIndex) in subtable.rows ?? []"
    :key="rowIndex"
    class="group light:hover:bg-gray-100 dark:odd:bg-gray-800 dark:odd:text-gray-100"
  >
    <td class="p-10 align-baseline" v-for="(colDetails, colIndex) in columns" :key="colIndex">
      <div :style="colIndex == 0 ? indentStyle : undefined">
        <div :class="{ 'text-right': colDetails.isQuantitative }" v-if="row[colIndex] != null">
          <div :class="{ 'flex flex-row justify-end': showMinibar(row[colIndex]) }">
            <GraphValueComponent :value="row[colIndex].formattedValue" />
            <div v-if="showMinibar(row[colIndex])" class="pl-10">
              <MiniBar :value="row[colIndex].originalValue" :max="getMaxValueForColumn(colIndex)" />
            </div>
          </div>
        </div>
        <div class="opacity-50" :class="{ 'text-right': colDetails.isQuantitative }" v-else>-</div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import { Subtable as SubtableType, Column } from "../Table.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { toggle } from "@/common/lib/set";
import { computed, ref, Ref, toRefs } from "vue";
import MiniBar from "../../MiniBar.vue";
import { GraphValueType, toNative } from "@/common/lib/value";
import { ValueWithFormattedValue } from "@/common/lib/format";

const props = defineProps<{ subtable: SubtableType; columns: Column[]; depth: number }>();
const { depth } = toRefs(props);

const collapsed: Ref<Set<string>> = ref(new Set());

const indentStyle = computed(() => ({ marginLeft: `${20 * depth.value}px` }));

const getMaxValueForColumn = (colIndex: number) => {
  const validValues: number[] = (props.subtable.rows ?? [])
    .map((row) => row[colIndex]?.originalValue)
    .filter((originalValue) => originalValue && originalValue._type === GraphValueType.Integer)
    .map((originalValue) => toNative(originalValue));
  validValues.push(-Infinity);
  return Math.max(...validValues);
};
const showMinibar = (row: ValueWithFormattedValue) => {
  return row.originalValue._type == GraphValueType.Integer;
};
</script>
