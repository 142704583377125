<template>
  <div class="flex flex-col items-start gap-5 border-t border-t-gray-900 pt-10">
    <div class="flex w-full flex-row items-center justify-between gap-10">
      <span class="font-bold text-gray-500">{{ label }}</span>
      <template v-if="!readonly">
        <TextButton label="Update" v-if="!loading" @click="openDialog" />
        <Spinner v-else />
      </template>
      <TextButton label="Copy" v-if="copyable" />
    </div>
    <span v-if="!isNil(value)" :class="{ 'font-extralight': readonly }">{{ formattedValue }}</span>
    <span v-else class="italic">Not set</span>
  </div>

  <Dialog v-if="editMode" :title="`Edit ${label}`" @succeeded="saveDialog" @cancelled="closeDialog">
    <Textbox v-model="valueToEdit" />
  </Dialog>
</template>

<script lang="ts" setup>
import { isNil } from "lodash";
import { computed, ref } from "vue";
import TextButton from "../TextButton.vue";
import Dialog from "../Dialog.vue";
import Textbox from "../Textbox.vue";
import Spinner from "../Spinner.vue";

const props = withDefaults(
  defineProps<{
    label: string;
    value?: string;
    readonly?: boolean;
    copyable?: boolean;
    loading?: boolean;
  }>(),
  {
    readonly: false,
    copyable: false,
    loading: false,
  }
);

const emit = defineEmits<{
  update: [value: string];
}>();

const editMode = ref(false);
const valueToEdit = ref("");

const formattedValue = computed(() => props.value ?? "None");

function saveDialog() {
  emit("update", valueToEdit.value);
  closeDialog();
}

function openDialog() {
  editMode.value = true;
  valueToEdit.value = props.value ?? "";
}

function closeDialog() {
  editMode.value = false;
}
</script>
