import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "absolute inset-0 z-[1000]" }
const _hoisted_2 = { class: "absolute inset-0 flex items-center justify-center" }
const _hoisted_3 = { class: "flex min-h-[100px] min-w-[450px] max-w-[450px] flex-col gap-5 rounded-[5px] border border-gray-200 bg-white px-20 py-10 text-gray-500 shadow-modal dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200" }
const _hoisted_4 = { class: "mb-5 flex flex-row items-center gap-10 text-xxl font-bold leading-6" }
const _hoisted_5 = {
  key: 0,
  class: "-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white"
}
const _hoisted_6 = { class: "-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white" }
const _hoisted_7 = {
  key: 1,
  class: "-mr-[15px] max-h-[70vh] overflow-y-scroll pr-15 text-lg font-normal text-gray-500 dark:text-white"
}
const _hoisted_8 = {
  key: 2,
  class: "flex flex-row items-center justify-end gap-10 pt-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["Icon"], {
              name: $props.icon,
              color: $props.iconColor
            }, null, 8 /* PROPS */, ["name", "color"]),
            _createTextVNode(" " + _toDisplayString($props.title), 1 /* TEXT */)
          ]),
          (_ctx.$slots.header)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "header")
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default")
          ]),
          (_ctx.$slots.footer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "footer")
              ]))
            : _createCommentVNode("v-if", true),
          (!$props.skipDefaultFooter)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                ($props.cancelLabel)
                  ? (_openBlock(), _createBlock($setup["TextButton"], {
                      key: 0,
                      label: $props.cancelLabel,
                      secondary: true,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('cancelled')))
                    }, null, 8 /* PROPS */, ["label"]))
                  : _createCommentVNode("v-if", true),
                ($props.successLabel)
                  ? (_openBlock(), _createBlock($setup["TextButton"], {
                      key: 1,
                      label: $props.successLabel,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('succeeded'))),
                      "data-test": "success-button"
                    }, null, 8 /* PROPS */, ["label"]))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ])
  ]))
}