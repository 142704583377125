import { defineComponent as _defineComponent } from 'vue'
import { useWorkspaceStore, Warehouse } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import { AsyncStatus } from "../lib/async";
import Select from "./Select.vue";
import Spinner from "./Spinner.vue";
import Label from "./Label.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarehouseChooser',
  setup(__props, { expose: __expose }) {
  __expose();

const workspaceStore = useWorkspaceStore();
const { warehouses, currentWorkspaceSync: currentWorkspace } = storeToRefs(workspaceStore);

async function updateWarehouse() {
  if (!currentWorkspace?.value) {
    return;
  }
  if (!currentWorkspace.value.current_warehouse_id) {
    return;
  }
  await workspaceStore.setCurrentWarehouse(
    currentWorkspace.value.id,
    currentWorkspace.value.current_warehouse_id
  );
}

const __returned__ = { workspaceStore, warehouses, currentWorkspace, updateWarehouse, get AsyncStatus() { return AsyncStatus }, Select, Spinner, Label }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})