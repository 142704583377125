import { defineComponent as _defineComponent } from 'vue'
import TextButton from "@/common/components/TextButton.vue";
import Icon from "./Icon.vue";
import { useKeyHandler } from "../composables/useKeyHandler";
import { KeyCommand } from "../lib/modifierKeys";
import { IconColorSpec } from "@/common/lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'Dialog',
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: false, default: "md:deployed_code" },
    iconColor: { type: [Object, String], required: false },
    successLabel: { type: [String, null], required: false, default: "Ok" },
    cancelLabel: { type: [String, null], required: false, default: "Cancel" },
    skipDefaultFooter: { type: Boolean, required: false, default: false }
  },
  emits: ["succeeded", "cancelled"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const keyHandler = useKeyHandler();
if (!props.skipDefaultFooter && props.cancelLabel) {
  keyHandler.registerHandler(new KeyCommand("Escape"), () => emit("cancelled"), true, false);
}
if (!props.skipDefaultFooter && props.successLabel) {
  keyHandler.registerHandler(new KeyCommand("Enter"), () => emit("succeeded"), true, false);
}

const __returned__ = { props, emit, keyHandler, TextButton, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})