import { defineComponent as _defineComponent } from 'vue'
import IconButton from "@/common/components/IconButton.vue";
import { useExploreStore } from "@/reader/stores/explore";
import { ComputedRef, computed, toRefs } from "vue";
import EqualityFilterComponent from "./filter-types/EqualityFilter.vue";
import TextFilterComponent from "./filter-types/TextFilter.vue";
import RangeFilterComponent from "./filter-types/RangeFilter.vue";
import ExistenceFilterComponent from "./filter-types/ExistenceFilter.vue";
import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";
import Icon from "@/common/components/Icon.vue";
import { QueryFilter } from "@/common/lib/query";
import {
  FilterType,
  ExistenceFilter,
  EqualityFilter,
  RangeFilter,
  TextFilter,
} from "@/common/lib/fetchApi";


export default /*@__PURE__*/_defineComponent({
  __name: 'Filter',
  props: {
    filter: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { filter } = toRefs(props);

const exploreStore = useExploreStore();

const menuItems: ComputedRef<MenuItem[]> = computed(() => [
  {
    key: "negate",
    label: "Negate filter",
    action: () => exploreStore.toggleFilterNegated(filter.value.alias),
  },
  {
    key: "remove",
    label: "Remove filter",
    action: () => exploreStore.removeFilter(filter.value.alias),
  },
]);

const __returned__ = { props, filter, exploreStore, menuItems, IconButton, EqualityFilterComponent, TextFilterComponent, RangeFilterComponent, ExistenceFilterComponent, get Dropdown() { return Dropdown }, Menu, Icon, get FilterType() { return FilterType } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})