import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "neighborhood" }
const _hoisted_2 = {
  key: 0,
  class: "loading"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "neighbors" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.queryResults.status === $setup.AsyncStatus.InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["Spinner"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.queryResults.status === $setup.AsyncStatus.Failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.queryResults.message), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.queryResults.status === $setup.AsyncStatus.Succeeded && $setup.conceptCount > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["Icon"], {
              name: $setup.conceptIconName,
              color: { dark: 'white', light: 'black' }
            }, null, 8 /* PROPS */, ["name"]),
            _createElementVNode("div", _hoisted_6, _toDisplayString($setup.conceptTypeLabel), 1 /* TEXT */),
            _createVNode($setup["IconButton"], {
              name: "table",
              size: "l",
              tooltip: "View all in table",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('pivot')))
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.neighbors, (neighbor) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["neighbor", { '-with-link': neighbor.address != null }]),
                key: neighbor.key,
                onClick: ($event: any) => ($setup.clickLink(neighbor.address))
              }, _toDisplayString(neighbor.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_8))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}