import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Dropdown"], {
    placement: "right",
    shown: $setup.isEditing,
    "onUpdate:shown": _cache[4] || (_cache[4] = ($event: any) => (($setup.isEditing) = $event)),
    onApplyShow: $setup.focusFirstField
  }, {
    popper: _withCtx(() => [
      _createVNode($setup["FilterEditor"], {
        label: $setup.editingDescription,
        valid: $setup.isValid,
        onCancel: _cache[3] || (_cache[3] = ($event: any) => ($setup.isEditing = false)),
        onCommit: $setup.commit
      }, {
        default: _withCtx(() => [
          _createVNode($setup["Textbox"], {
            label: "Values",
            "helper-text": "Enter one value per line",
            textarea: "",
            modelValue: $setup.valueUnderEdit,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.valueUnderEdit) = $event)),
            ref: "valueInputEl"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode($setup["Select"], {
            modelValue: $setup.matchType,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.matchType) = $event)),
            label: "Matching mode",
            options: $setup.textFilterMatchOptions,
            "option-label": $setup.optionLabel,
            clearable: false
          }, null, 8 /* PROPS */, ["modelValue", "options"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["Checkbox"], {
              label: "Ignore case",
              modelValue: $setup.ignoreCase,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.ignoreCase) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label", "valid"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        onClick: $setup.startEditing,
        class: "cursor-pointer border-b border-b-transparent hover:border-b-gray-500"
      }, [
        _createTextVNode(_toDisplayString($setup.opDescription) + " ", 1 /* TEXT */),
        _createElementVNode("span", _hoisted_2, _toDisplayString($setup.valueList), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["shown"]))
}