import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center self-stretch" }
const _hoisted_2 = { class: "flex shrink-0 flex-grow basis-0 items-center gap-10 px-5 py-0 text-xxl font-bold text-gray-800 dark:text-gray-200" }
const _hoisted_3 = {
  key: 0,
  class: "flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
}
const _hoisted_4 = { class: "flex items-start justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100" }
const _hoisted_5 = { class: "flex flex-col items-start gap-10 self-stretch" }
const _hoisted_6 = { class: "flex items-center justify-end gap-10 self-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["Icon"], {
          name: "electrical_services",
          size: "xl",
          class: "icon",
          color: $setup.iconColor
        }, null, 8 /* PROPS */, ["color"]),
        _cache[4] || (_cache[4] = _createTextVNode(" Connect to workspace "))
      ])
    ]),
    (
      [$setup.AsyncStatus.Succeeded, $setup.AsyncStatus.Failed].includes($setup.currentWorkspace.status) &&
      $setup.asyncResultOr($setup.workspaces, []).length &&
      $setup.mode === 'view'
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[5] || (_cache[5] = _createElementVNode("span", null, " Select a workspace ", -1 /* HOISTED */)),
            _createVNode($setup["TextButton"], {
              label: "Add workspace",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.mode = 'create'))
            })
          ]),
          _createElementVNode("form", _hoisted_5, [
            _createVNode($setup["WorkspaceChooser"]),
            _createVNode($setup["WarehouseChooser"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            ($setup.currentWorkspaceId)
              ? (_openBlock(), _createBlock($setup["TextButton"], {
                  key: 0,
                  label: "Delete",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.deletingWorkspace = true))
                }))
              : _createCommentVNode("v-if", true),
            _createVNode($setup["TextButton"], {
              label: "Edit",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.mode = 'edit'))
            })
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.mode !== 'view')
      ? (_openBlock(), _createBlock($setup["EditConnectionSettings"], {
          key: 1,
          mode: $setup.mode,
          workspaceId: $setup.currentWorkspaceId,
          onSaveWorkspace: $setup.updateWorkspace
        }, null, 8 /* PROPS */, ["mode", "workspaceId"]))
      : _createCommentVNode("v-if", true),
    ($setup.deletingWorkspace && $setup.currentWorkspace.status === $setup.AsyncStatus.Succeeded)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 2,
          title: "Delete workspace?",
          "success-label": "Delete",
          onSucceeded: $setup.deleteWorkspace,
          onCancelled: _cache[3] || (_cache[3] = ($event: any) => ($setup.deletingWorkspace = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Are you sure you want to delete workspace " + _toDisplayString($setup.currentWorkspace.result?.nickname) + "? This cannot be undone. ", 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}