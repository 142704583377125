import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import { Tooltip } from "floating-vue";
import { getIconSizePx, IconSize, parseIconName } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'IconButtonV2',
  props: {
    name: { type: String, required: true },
    size: { type: String, required: false, default: "m" },
    label: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
    targetSize: { type: Number, required: false, default: 32 },
    darkHover: { type: Boolean, required: false, default: false },
    preText: { type: String, required: false },
    selected: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const sizePx = computed(() => getIconSizePx(props.size));
const iconSrc = computed(() => parseIconName(props.name));
const buttonSizePx = computed(() => Math.max(sizePx.value, props.targetSize) + "px");

const __returned__ = { props, sizePx, iconSrc, buttonSizePx, get Tooltip() { return Tooltip } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})