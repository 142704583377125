<template>
  <div class="m-10 max-w-[275px] text-black dark:text-white">
    {{ label }}:
    <div class="my-5">
      <slot />
    </div>
    <div class="flex flex-row justify-end">
      <TextButton label="Cancel" secondary @click="emit('cancel')" size="l" class="mr-5" />
      <TextButton label="Set Filter" @click="emit('commit')" :disabled="!valid" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import TextButton from "@/common/components/TextButton.vue";

defineProps<{ valid: boolean; label: string }>();

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "commit"): void;
}>();
</script>
