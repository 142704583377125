import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "border-t border-t-gray-900 pt-10" }
const _hoisted_2 = { class: "flex flex-row items-center justify-between gap-10" }
const _hoisted_3 = { class: "text-sm text-gray-300" }
const _hoisted_4 = {
  key: 1,
  class: "flex w-full flex-col gap-5"
}
const _hoisted_5 = {
  key: 1,
  class: "flex w-full flex-col"
}
const _hoisted_6 = { class: "flex flex-col items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.warehouses.status === $setup.AsyncStatus.Succeeded)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($setup.warehouses.result.length > 0)
            ? (_openBlock(), _createBlock($setup["Select"], {
                key: 0,
                label: "Warehouse",
                modelValue: $setup.currentWarehouseId,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => (($setup.currentWarehouseId) = $event)),
                  $setup.updateWarehouse
                ],
                options: $setup.warehouses.result,
                "option-label": (w) => w.name,
                "option-value": (w) => w.id,
                clearable: false
              }, {
                "option-data": _withCtx((warehouse) => [
                  _createElementVNode("span", _hoisted_2, [
                    _createElementVNode("span", null, _toDisplayString(warehouse.name), 1 /* TEXT */),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(warehouse.cluster_size), 1 /* TEXT */)
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "options", "option-label", "option-value"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode($setup["Label"], { label: "Warehouse" }),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-center text-gray-100 opacity-[0.6]" }, "No warehouses", -1 /* HOISTED */))
              ]))
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    ($setup.warehouses.status === $setup.AsyncStatus.InProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode($setup["Label"], { label: "Warehouse" }),
          _createElementVNode("div", _hoisted_6, [
            _createVNode($setup["Spinner"])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}