import { defineComponent as _defineComponent } from 'vue'
import { TextFilterMatch, textFilterMatchOptions } from "@/common/lib/query";
import { stringifyValue, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, onMounted, Ref, ref, toRefs } from "vue";
import Select from "@/common/components/Select.vue";
import Checkbox from "@/common/components/Checkbox.vue";
import { QueryFilter } from "@/common/lib/query";
import { TextFilter } from "@/common/lib/fetchApi";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import { propertyName } from "@/common/lib/derived";
import Textbox from "@/common/components/Textbox.vue";

interface TextFilterMatchDetails {
  label: string;
  description: string;
  negatedDescription: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextFilter',
  props: {
    filter: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { filter } = toRefs(props);

const isEditing: Ref<boolean> = ref(false);
const valueUnderEdit: Ref<string | undefined> = ref(undefined);
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);
const matchType = ref<TextFilterMatch>(TextFilterMatch.Full);
const ignoreCase = ref(true);

function optionDetails(option: TextFilterMatch): TextFilterMatchDetails {
  switch (option) {
    case TextFilterMatch.Contain:
      return {
        label: "Contains",
        description: "contains",
        negatedDescription: "doesn't contain",
      };
    case TextFilterMatch.Start:
      return {
        label: "Starts with",
        description: "starts with",
        negatedDescription: "doesn't start with",
      };
    case TextFilterMatch.End:
      return {
        label: "Ends with",
        description: "ends with",
        negatedDescription: "doesn't end with",
      };
    case TextFilterMatch.Full:
      return {
        label: "Exact match",
        description: "exactly matches",
        negatedDescription: "doesn't exactly match",
      };
  }
}

const opDescription = computed(() => {
  const value = filter.value.values[0];
  const match = (value?.match ?? TextFilterMatch.Full) as TextFilterMatch;
  return makeDescription(match, filter.value.values.length > 1);
});

const editingDescription = computed(
  () => `${propertyName(filter.value.property_type)} ${makeDescription(matchType.value, true)}`
);

function makeDescription(option: TextFilterMatch, multiple: boolean) {
  let desc = "";
  const details = optionDetails(option);
  desc = filter.value.negated ? details.negatedDescription : details.description;
  if (multiple) {
    desc = desc + " any of";
  }
  return desc;
}

function optionLabel(option: TextFilterMatch) {
  return optionDetails(option).label;
}

const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);
const isValid = computed(() => cleanEditedValue().length > 0);

const exploreStore = useExploreStore();

function focusFirstField() {
  setTimeout(() => valueInputEl.value?.focus(), 50); // Boy do I hate this
}

function startEditing() {
  const value = filter.value.values[0];
  valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
  matchType.value = (value?.match ?? TextFilterMatch.Full) as TextFilterMatch;
  ignoreCase.value = !(value?.case_sensitive ?? false);
  isEditing.value = true;
}

function cleanEditedValue() {
  return valueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  filter.value.values = cleanEditedValue().map((v) => ({
    value: toValue(v),
    match: matchType.value,
    case_sensitive: !ignoreCase.value,
  }));
  isEditing.value = false;
  exploreStore.load();
}

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});

const __returned__ = { props, filter, isEditing, valueUnderEdit, valueInputEl, matchType, ignoreCase, optionDetails, opDescription, editingDescription, makeDescription, optionLabel, valueList, isValid, exploreStore, focusFirstField, startEditing, cleanEditedValue, commit, get textFilterMatchOptions() { return textFilterMatchOptions }, Select, Checkbox, get Dropdown() { return Dropdown }, FilterEditor, Textbox }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})