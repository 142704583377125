import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "resize-wrapper",
    style: _normalizeStyle({
      width: $setup.width > 0 ? undefined : $setup.initialWidth + 'px',
      height: $setup.height > 0 ? undefined : $setup.initialHeight + 'px',
    })
  }, [
    ($setup.horizontal)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "bar-horiz",
          onMousedown: _withModifiers($setup.startResizeEW, ["prevent"]),
          style: _normalizeStyle({ right: $setup.horizontal == 'right' ? 0 : undefined })
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, null, -1 /* HOISTED */),
          _createElementVNode("span", null, null, -1 /* HOISTED */),
          _createElementVNode("span", null, null, -1 /* HOISTED */)
        ]), 36 /* STYLE, NEED_HYDRATION */))
      : _createCommentVNode("v-if", true),
    ($setup.vertical)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "bar-vert",
          onMousedown: _withModifiers($setup.startResizeNS, ["prevent"]),
          style: _normalizeStyle({ bottom: $setup.vertical == 'bottom' ? 0 : undefined })
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("span", null, null, -1 /* HOISTED */),
          _createElementVNode("span", null, null, -1 /* HOISTED */),
          _createElementVNode("span", null, null, -1 /* HOISTED */)
        ]), 36 /* STYLE, NEED_HYDRATION */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: "resize-content",
      style: _normalizeStyle({
        width: $setup.width > 0 ? $setup.width + 'px' : '100%',
        height: $setup.height > 0 ? $setup.height + 'px' : '100%',
      })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4 /* STYLE */)
  ], 4 /* STYLE */))
}