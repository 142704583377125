import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "absolute inset-0 overflow-auto px-15" }
const _hoisted_2 = { class: "flex min-h-[36px] flex-row content-center gap-10" }
const _hoisted_3 = {
  key: 0,
  class: "rounded-full bg-gray-900 px-20 py-10 text-gray-200 shadow-snackbar"
}
const _hoisted_4 = { class: "p-10 font-monospace dark:bg-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["TextButton"], {
        label: "Copy SQL",
        class: "copy-button",
        onClick: $setup.copyContents
      }),
      ($setup.displaySnackbar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Copied! "))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sqlLines, ([line, indent], index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          style: _normalizeStyle({ marginLeft: indent })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(line, (segment, segmentIndex) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `${index}:${segmentIndex}`,
              class: _normalizeClass(["select-text", 'sql-' + segment.name])
            }, _toDisplayString(segment.content), 3 /* TEXT, CLASS */))
          }), 128 /* KEYED_FRAGMENT */)),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1 /* HOISTED */))
        ], 4 /* STYLE */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}