import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Checkbox',
  props: {
    label: { type: String, required: true },
    modelValue: { type: Boolean, required: true },
    ctOrange: { type: Boolean, required: false },
    id: { type: String, required: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const __returned__ = { props, emit, value }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})