import { defineComponent as _defineComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ProviderButton',
  props: {
    imgSrc: { type: String, required: true },
    height: { type: String, required: true },
    static: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const __returned__ = {  }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})