import { defineComponent as _defineComponent } from 'vue'
import MessageBar from "@/common/components/MessageBar.vue";
import { asyncResultOr } from "@/common/lib/async";
import { useExploreStore } from "@/reader/stores/explore";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryProblems',
  setup(__props, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();
const problems = computed(() => asyncResultOr(exploreStore.problems, []));

const __returned__ = { exploreStore, problems, MessageBar }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})