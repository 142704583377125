import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "my-5 flex rounded-md bg-gray-300 dark:bg-gray-900" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, ({ label, value }) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["flex basis-full cursor-pointer items-center justify-center rounded-md px-10 text-gray-500", {
        'border border-gray-500 bg-white font-bold text-orange dark:border-black dark:bg-gray-800':
          value === $setup.model,
        'hover:text-orange': value !== $setup.model,
      }]),
        key: String(value),
        onClick: ($event: any) => ($setup.model = value)
      }, _toDisplayString(label), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}