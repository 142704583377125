import { defineComponent as _defineComponent } from 'vue'
import { FloatValue, IntegerValue, toNative } from "@/common/lib/value";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MiniBar',
  props: {
    value: { type: Object, required: true },
    max: { type: Number, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { value, max } = toRefs(props);

const barWidth = computed(function () {
  const thisValue = toNative(value.value);
  const percentage = (thisValue / max.value) * 100;
  return `${percentage}%`;
});

const __returned__ = { props, value, max, barWidth }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})