import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-1 flex-col justify-end" }
const _hoisted_2 = { class: "mt-[-2px] flex flex-row flex-nowrap items-center justify-between border-t-[2px] border-solid border-gray-900 pl-15 pr-[2px]" }
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-0 flex flex-col"
}
const _hoisted_4 = { class: "flex flex-row gap-[8px] py-[5px] pl-[12px] pr-[2px]" }
const _hoisted_5 = { class: "flex w-full flex-col gap-[8px]" }
const _hoisted_6 = { class: "flex min-h-[20px] flex-row items-center gap-[8px]" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "min-h-[20px]"
}
const _hoisted_10 = {
  key: 1,
  class: "text-center text-gray-100 opacity-[0.6]"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "pb-10 pt-5"
}
const _hoisted_13 = {
  key: 1,
  class: "text-center"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "flex flex-row flex-nowrap items-center justify-between border-t-[2px] border-solid border-gray-900 px-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.sourceBrowserExpanded)
        ? (_openBlock(), _createBlock($setup["ResizeWrapper"], {
            key: 0,
            vertical: "top",
            "onUpdate:height": $setup.handleHeightUpdate,
            "minimum-length": $setup.HEADER_HEIGHT,
            "maximum-length": $setup.maxHeight,
            initialHeight: String($setup.height),
            identifier: "SourceBrowser.resources"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-base font-bold" }, "Source Browser", -1 /* HOISTED */)),
                _createVNode($setup["IconButton"], {
                  name: "md:bottom_panel_close",
                  "data-test": "expand-sources",
                  onClick: $setup.toggleExpanded,
                  size: "l",
                  targetSize: 32
                })
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["relative", {
          'flex-grow transition-all duration-500 ease-[cubic-bezier(0,1,0.5,1)]':
            $setup.sourceBrowserExpanded,
          'flex-grow-0 duration-500': !$setup.sourceBrowserExpanded,
        }]),
                style: _normalizeStyle({
          height: $setup.height > 0 ? $setup.height + 'px' : '100%',
        })
              }, [
                ($setup.workspacesState !== $setup.WorkspacesState.Loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode($setup["IconButton"], {
                          name: $setup.showServerDetails ? 'md:collapse_all' : 'md:expand_all',
                          label: $setup.showServerDetails ? 'Collapse' : 'Expand',
                          size: "l",
                          targetSize: 24,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleServerDetails()))
                        }, null, 8 /* PROPS */, ["name", "label"]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            ($setup.workspaceIcon)
                              ? (_openBlock(), _createBlock($setup["Icon"], {
                                  key: 0,
                                  name: $setup.workspaceIcon
                                }, null, 8 /* PROPS */, ["name"]))
                              : _createCommentVNode("v-if", true),
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", null, _toDisplayString($setup.workspace?.nickname ?? "No current workspace"), 1 /* TEXT */),
                              ($setup.workspace && !$setup.workspace.connected)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(disconnected)"))
                                : _createCommentVNode("v-if", true)
                            ])
                          ]),
                          ($setup.workspace?.connected)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                ($setup.currentWarehouse)
                                  ? (_openBlock(), _createBlock($setup["WarehouseStatus"], {
                                      key: 0,
                                      warehouse: $setup.currentWarehouse
                                    }, null, 8 /* PROPS */, ["warehouse"]))
                                  : (
                    $setup.warehouses.status === $setup.AsyncStatus.Succeeded && $setup.warehouses.result.length === 0
                  )
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, " No warehouses "))
                                    : (_openBlock(), _createBlock($setup["Spinner"], { key: 2 }))
                              ]))
                            : _createCommentVNode("v-if", true),
                          ($setup.showServerDetails)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                ($props.allowWorkspaceSwitching)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                      _createVNode($setup["WorkspaceChooser"])
                                    ]))
                                  : _createCommentVNode("v-if", true),
                                ($setup.workspace)
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 1,
                                      class: _normalizeClass(["min-h-[58px]", { 'pt-10': !$props.allowWorkspaceSwitching }])
                                    }, [
                                      _createVNode($setup["WarehouseChooser"])
                                    ], 2 /* CLASS */))
                                  : _createCommentVNode("v-if", true)
                              ]))
                            : _createCommentVNode("v-if", true)
                        ]),
                        ($setup.workspace?.connected)
                          ? (_openBlock(), _createBlock($setup["IconButton"], {
                              key: 0,
                              name: "md:restart_alt",
                              label: "Refresh catalog",
                              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.refreshTables()), ["stop"])),
                              size: "l",
                              targetSize: 32
                            }))
                          : _createCommentVNode("v-if", true)
                      ]),
                      ($setup.workspaces.status === $setup.AsyncStatus.Failed)
                        ? (_openBlock(), _createBlock($setup["MessageBar"], {
                            key: 0,
                            mode: "error"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Error loading workspaces: " + _toDisplayString($setup.workspaces.message), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true),
                      ($setup.warehouses.status === $setup.AsyncStatus.Failed)
                        ? (_openBlock(), _createBlock($setup["MessageBar"], {
                            key: 1,
                            mode: "error"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Error loading warehouses: " + _toDisplayString($setup.warehouses.message), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true),
                      ($setup.currentWorkspace.status === $setup.AsyncStatus.Failed)
                        ? (_openBlock(), _createBlock($setup["MessageBar"], {
                            key: 2,
                            mode: "error"
                          }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode(" Error loading current workspace ")
                            ])),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true),
                      ($setup.workspace?.connected === false)
                        ? (_openBlock(), _createBlock($setup["MessageBar"], {
                            key: 3,
                            mode: "warning",
                            class: "mt-10"
                          }, {
                            actions: _withCtx(() => [
                              _createVNode($setup["TextButton"], {
                                label: "Reconnect",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.reconnect())),
                                mode: "warning"
                              })
                            ]),
                            default: _withCtx(() => [
                              _cache[7] || (_cache[7] = _createTextVNode(" Workspace is not connected "))
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true),
                      ($setup.currentWorkspaceId && $setup.workspace?.connected)
                        ? (_openBlock(), _createBlock($setup["SourceCatalog"], {
                            key: 4,
                            "workspace-id": $setup.currentWorkspaceId,
                            onSelectTables: $setup.selectTables
                          }, null, 8 /* PROPS */, ["workspace-id"]))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : ($setup.workspacesState == $setup.WorkspacesState.Loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode($setup["Spinner"])
                      ]))
                    : _createCommentVNode("v-if", true)
              ], 6 /* CLASS, STYLE */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["maximum-length", "initialHeight"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-base font-bold" }, "Source Browser", -1 /* HOISTED */)),
              _createVNode($setup["IconButton"], {
                name: "md:top_panel_close",
                onClick: $setup.toggleExpanded,
                size: "xl",
                targetSize: 32
              })
            ])
          ]))
    ]),
    ($setup.deletedWarehouseWarning)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          title: "DELETED WAREHOUSE",
          "success-label": "Go to settings",
          onSucceeded: _cache[3] || (_cache[3] = ($event: any) => ($setup.useNavigation().goToUrl($setup.CONNECTIONS_URL))),
          onCancelled: _cache[4] || (_cache[4] = ($event: any) => ($setup.deletedWarehouseWarning = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.currentWarehouse?.name) + " has been deleted. You must select a new warehouse to continue. ", 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}