import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0" }
const _hoisted_2 = { class: "flex flex-col items-start gap-10 self-stretch" }
const _hoisted_3 = { class: "flex items-center justify-end gap-10 self-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "item-start flex justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100" }, [
        _createElementVNode("span", null, " Enter your StarRocks credentials. ")
      ], -1 /* HOISTED */)),
      _createElementVNode("form", _hoisted_2, [
        _createVNode($setup["Textbox"], {
          label: "Workspace nickname",
          modelValue: $setup.workspace.metadata.nickname,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.workspace.metadata.nickname) = $event)),
          required: true,
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
        _createVNode($setup["Textbox"], {
          label: "Host",
          modelValue: $setup.workspace.credentials.host,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.workspace.credentials.host) = $event)),
          required: true,
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
        _createVNode($setup["Textbox"], {
          label: "Port",
          modelValue: $setup.workspace.credentials.port,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.workspace.credentials.port) = $event)),
          required: true,
          type: "number",
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
        _createVNode($setup["Textbox"], {
          label: "User",
          modelValue: $setup.workspace.credentials.user,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.workspace.credentials.user) = $event)),
          required: true,
          autocomplete: "username",
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
        _createVNode($setup["Textbox"], {
          label: "Password",
          modelValue: $setup.workspace.credentials.password,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.workspace.credentials.password) = $event)),
          required: true,
          type: "password",
          autocomplete: "current-password",
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
        _createVNode($setup["Textbox"], {
          label: "Personal access token",
          modelValue: $setup.workspace.credentials.personal_access_token,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.workspace.credentials.personal_access_token) = $event)),
          required: true,
          type: "password",
          autocomplete: "off",
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["TextButton"], {
          label: "Cancel",
          onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.cancelEditWorkspace())),
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["disabled"]),
        (!$setup.saving)
          ? (_openBlock(), _createBlock($setup["TextButton"], {
              key: 0,
              label: $setup.commitLabel,
              disabled: !$setup.saveEnabled || $setup.saving,
              onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.saveWorkspace()))
            }, null, 8 /* PROPS */, ["label", "disabled"]))
          : (_openBlock(), _createBlock($setup["SpinnerButton"], {
              key: 1,
              label: $setup.commitLabel
            }, null, 8 /* PROPS */, ["label"]))
      ])
    ]),
    ($setup.errorDialogMessage)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          "cancel-label": undefined,
          title: "Connection Error",
          icon: "error",
          onSucceeded: _cache[8] || (_cache[8] = ($event: any) => ($setup.errorDialogMessage = undefined))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.errorDialogMessage), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}