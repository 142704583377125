import { defineComponent as _defineComponent } from 'vue'
import Icon from "./Icon.vue";
import { Tab } from "./tab";


export default /*@__PURE__*/_defineComponent({
  __name: 'TabSelect',
  props: {
    tabs: { type: Array, required: true },
    modelValue: { type: String, required: true }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

const __returned__ = { props, emit, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})