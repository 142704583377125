import { defineComponent as _defineComponent } from 'vue'
import Textbox from "@/common/components/Textbox.vue";
import Checkbox from "@/common/components/Checkbox.vue";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SignupPage',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { Textbox, Checkbox, Start, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})