import { environment } from "@/common/environments/environmentLoader";
import { defineStore } from "pinia";
import { RouteRecordNameGeneric } from "vue-router";
import { useUserStore } from "./userStore";

// TODO: combine with the Routes below
export type SelectedTab = "user-settings" | "connection" | "preferences" | "admin";
export type SubPage = "modules" | "user-settings";

export enum Routes {
  Home = "home",
  Connections = "connections",
  UserSettings = "user-settings",
  Preferences = "preferences",
  Admin = "admin",
}

interface State {
  selectedTab: SelectedTab;
  userSelected: boolean;
}

const canEditDatabricksConnection = environment.requireBoolean("EDIT_DATABRICKS_CONNECTION");
const showAdminPage = environment.requireBoolean("ADMIN_ENABLED");

export const useSettingsStore = defineStore("settings", {
  state: (): State => ({
    selectedTab: "user-settings",
    userSelected: false,
  }),
  getters: {
    subPage: (state) => subPage(state.userSelected),
    availableTabs: () => {
      const availableTabs = [{ label: "User settings", key: "user-settings" }];
      availableTabs.push({ label: "Preferences", key: "preferences" });

      if (canEditDatabricksConnection) {
        availableTabs.push({ label: "Connection", key: "connection" });
      }
      if (showAdminPage && useUserStore().user?.global_admin) {
        availableTabs.push({ label: "Admin", key: "admin" });
      }
      return availableTabs;
    },
  },
  actions: {
    openConnectionPage() {
      if (!canEditDatabricksConnection) {
        return;
      }
      this.userSelected = true;
      this.selectedTab = "connection";
    },
    openUserSettings() {
      this.userSelected = true;
      this.selectedTab = "user-settings";
    },
    openPreferences() {
      this.userSelected = true;
      this.selectedTab = "preferences";
    },
    openAdminPage() {
      this.userSelected = true;
      this.selectedTab = "admin";
    },
    openHome() {
      this.userSelected = false;
    },
    handleRouteChange(routeName: RouteRecordNameGeneric) {
      switch (routeName) {
        case Routes.Connections:
          this.openConnectionPage();
          break;
        case Routes.UserSettings:
          this.openUserSettings();
          break;
        case Routes.Preferences:
          this.openPreferences();
          break;
        case Routes.Admin:
          this.openAdminPage();
          break;
        default:
          this.openHome();
          break;
      }
    },
  },
});

function subPage(userSelected: boolean): SubPage {
  if (userSelected) {
    return "user-settings";
  }
  return "modules";
}
