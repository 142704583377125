import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import Spinner from "@/common/components/Spinner.vue";
import useKnowledge from "@/common/composables/useKnowledge";
import { AsyncStatus, asyncHasValue } from "@/common/lib/async";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "@/common/lib/knowledge";
import useQuery from "@/reader/composables/useQuery";
import {
  ConceptAddress,
  buildConceptQuery,
  buildNeighborhoodQueries,
  readerConceptTitle,
} from "@/reader/lib/concept";
import { groupBy, mapValues } from "lodash";
import { computed, toRefs } from "vue";
import NeighborhoodSummary from "./NeighborhoodSummary.vue";
import ValueGroup from "./ValueGroup.vue";
import { useAppStore } from "@/reader/stores/app";
import { formatValue } from "@/common/lib/format";
import { Query, QueryFilter } from "@/common/lib/query";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConceptPage',
  props: {
    address: { type: Object, required: true },
    showCloseButton: { type: Boolean, required: false, default: false }
  },
  emits: ["navigate", "pivot", "close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { address } = toRefs(props);

const emit = __emit;

const { getConceptIconName, typeLabel } = useKnowledge();
const appStore = useAppStore();

const { queryResults, rootConcept } = useQuery(
  appStore.module!,
  () => buildConceptQuery(address.value),
  () => appStore.map
);

const neighborhoods = computed(() => buildNeighborhoodQueries(address.value));

const title = computed(function () {
  const conceptLabel = typeLabel(address.value.conceptType);
  if (asyncHasValue(queryResults.value)) {
    return readerConceptTitle(rootConcept()) ?? conceptLabel;
  } else {
    return `Loading ${conceptLabel}...`;
  }
});

const propertyValuesByType = computed(function () {
  const grouped = groupBy(rootConcept().properties ?? [], "type");
  return mapValues(grouped, (props, type) =>
    props.map((prop) => formatValue(type as PropertyKnowledgeRef, prop.value!))
  );
});

const iconName = computed(() => getConceptIconName(address.value.conceptType));

function pivotTo(query: Query) {
  emit("pivot", query.root_concept_type, query.filters);
}

const __returned__ = { props, address, emit, getConceptIconName, typeLabel, appStore, queryResults, rootConcept, neighborhoods, title, propertyValuesByType, iconName, pivotTo, Icon, Spinner, get AsyncStatus() { return AsyncStatus }, NeighborhoodSummary, ValueGroup, IconButtonV2 }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})