import { defineComponent as _defineComponent } from 'vue'
import { Async, AsyncStatus } from "@/common/lib/async";
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import { ModuleMetadata } from "@/common/stores/userModuleStore";
import Module from "./Module.vue";
import { computed, ref } from "vue";
import TextButton from "@/common/components/TextButton.vue";
import pluralize from "pluralize";
import Dialog from "@/common/components/Dialog.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Modules',
  props: {
    modules: { type: null, required: true },
    filtered: { type: Boolean, required: true },
    allowDeletes: { type: Boolean, required: false }
  },
  emits: ["openModule", "deleteModule"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const deletingModules = ref(false);

const selectedModules = computed(() => {
  if (props.modules.status !== AsyncStatus.Succeeded) {
    return [];
  }
  return props.modules.result.filter((m) => m.selected);
});

function deselectModules() {
  if (props.modules.status !== AsyncStatus.Succeeded) {
    return [];
  }
  props.modules.result.forEach((m) => (m.selected = false));
}

const __returned__ = { props, emit, deletingModules, selectedModules, deselectModules, get AsyncStatus() { return AsyncStatus }, AsyncWrapper, Module, TextButton, get pluralize() { return pluralize }, Dialog }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})