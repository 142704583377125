import { defineComponent as _defineComponent } from 'vue'
import { FinComputedRow } from "../FinancialStatement.vue";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import Icon from "@/common/components/Icon.vue";
import { toggle } from "@/common/lib/set";
import { computed, ref, Ref, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FinancialSubtable',
  props: {
    rows: { type: Array, required: true },
    columns: { type: Array, required: true },
    trailingColumns: { type: Array, required: true },
    depth: { type: Number, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { depth } = toRefs(props);

const expanded: Ref<Set<number>> = ref(new Set());

const indentStyle = computed(() => ({ marginLeft: `${15 * depth.value}px` }));

const __returned__ = { props, depth, expanded, indentStyle, GraphValueComponent, Icon, get toggle() { return toggle } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})