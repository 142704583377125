import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2 ml-[22px] mr-5 flex items-center rounded-xl bg-gray-200 px-5 py-2 dark:bg-gray-900" }
const _hoisted_2 = { class: "mr-5 basis-full overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Icon"], {
      name: "md:filter_alt",
      color: "orange",
      class: "self-start"
    }),
    _createElementVNode("div", _hoisted_2, [
      ($setup.filter.type === $setup.FilterType.Equality)
        ? (_openBlock(), _createBlock($setup["EqualityFilterComponent"], {
            key: 0,
            filter: $setup.filter as QueryFilter<EqualityFilter>
          }, null, 8 /* PROPS */, ["filter"]))
        : _createCommentVNode("v-if", true),
      ($setup.filter.type === $setup.FilterType.Text)
        ? (_openBlock(), _createBlock($setup["TextFilterComponent"], {
            key: 1,
            filter: $setup.filter as QueryFilter<TextFilter>
          }, null, 8 /* PROPS */, ["filter"]))
        : _createCommentVNode("v-if", true),
      ($setup.filter.type === $setup.FilterType.Range)
        ? (_openBlock(), _createBlock($setup["RangeFilterComponent"], {
            key: 2,
            filter: $setup.filter as QueryFilter<RangeFilter>
          }, null, 8 /* PROPS */, ["filter"]))
        : _createCommentVNode("v-if", true),
      ($setup.filter.type === $setup.FilterType.Exists)
        ? (_openBlock(), _createBlock($setup["ExistenceFilterComponent"], {
            key: 3,
            filter: $setup.filter as QueryFilter<ExistenceFilter>
          }, null, 8 /* PROPS */, ["filter"]))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode($setup["Dropdown"], {
      placement: "right",
      class: "body"
    }, {
      popper: _withCtx(() => [
        _withDirectives(_createVNode($setup["Menu"], { items: $setup.menuItems }, null, 8 /* PROPS */, ["items"]), [
          [_directive_close_popper]
        ])
      ]),
      default: _withCtx(() => [
        _createVNode($setup["IconButton"], {
          name: "chevron-down",
          size: "xs"
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}