import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "py-15 pl-20 pr-15" }
const _hoisted_2 = { class: "max-h-fit flex-initial basis-full overflow-y-auto" }
const _hoisted_3 = { class: "overflow-y-auto" }
const _hoisted_4 = {
  class: "mx-0 my-10 max-h-fit select-none",
  "data-test": "system-resources"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex items-center gap-10 self-stretch" }
const _hoisted_7 = { class: "flex flex-[1_0_0] flex-row items-center justify-start gap-5 break-all font-medium text-gray-200" }
const _hoisted_8 = { class: "text-right text-sm text-gray-500" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "flex items-center gap-10 self-stretch" }
const _hoisted_11 = { class: "flex flex-[1_0_0] flex-row items-center justify-start gap-5 break-all text-base text-gray-200" }
const _hoisted_12 = { class: "text-right text-sm text-gray-500" }
const _hoisted_13 = ["data-test", "onClick"]
const _hoisted_14 = { class: "flex items-center gap-5 self-stretch break-all" }
const _hoisted_15 = { class: "max-w-[300px]" }
const _hoisted_16 = { class: "mb-5 break-all" }
const _hoisted_17 = { class: "flex flex-row justify-between" }
const _hoisted_18 = {
  key: 0,
  class: "pl-[25px] text-sm text-gray-500"
}
const _hoisted_19 = {
  key: 1,
  class: "pl-[25px] text-sm text-gray-500"
}
const _hoisted_20 = {
  key: 2,
  class: "pl-[25px] text-sm text-gray-500"
}
const _hoisted_21 = {
  key: 0,
  class: "p-10 text-center text-gray-500"
}
const _hoisted_22 = {
  key: 1,
  class: "p-10 text-center text-gray-500"
}
const _hoisted_23 = {
  key: 1,
  class: "text-center"
}
const _hoisted_24 = { key: 2 }
const _hoisted_25 = {
  key: 0,
  class: "sticky bottom-0 m-20 ml-30 flex items-center bg-gray-800"
}
const _hoisted_26 = { class: "flex-initial basis-full text-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["Textbox"], {
        modelValue: $setup.sourceBrowserStore.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.sourceBrowserStore.search) = $event)),
        type: "search",
        disabled: !$setup.hasSearch && $setup.catalogs.length === 0
      }, null, 8 /* PROPS */, ["modelValue", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          ($setup.status === $setup.AsyncStatus.Succeeded)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.catalogs, (catalog) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: catalog.name
                  }, [
                    _createElementVNode("div", {
                      class: "flex flex-col items-start justify-center px-10 py-5 hover:bg-gray-900",
                      onClick: ($event: any) => ($setup.toggleGroupCollapsed(catalog.name))
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode($setup["Disclosure"], {
                          class: "h-20 w-20 justify-center",
                          expanded: $setup.isGroupExpanded(catalog.name) || $setup.hasSearch
                        }, null, 8 /* PROPS */, ["expanded"]),
                        _createVNode($setup["Icon"], { name: "menu_book" }),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", null, _toDisplayString(catalog.name), 1 /* TEXT */)
                        ]),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(catalog.schemas.length) + " " + _toDisplayString($setup.pluralize("Schemas", catalog.schemas.length)), 1 /* TEXT */)
                      ])
                    ], 8 /* PROPS */, _hoisted_5),
                    ($setup.isGroupExpanded(catalog.name) || $setup.hasSearch)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(catalog.schemas, (schema) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: schema.name
                          }, [
                            _createElementVNode("div", {
                              class: "my-5 ml-20 mr-15 flex flex-col items-start justify-center hover:bg-gray-900",
                              onClick: ($event: any) => ($setup.toggleGroupCollapsed(`${catalog.name}:${schema.name}`))
                            }, [
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode($setup["Disclosure"], {
                                  class: "disclosure",
                                  expanded: $setup.isGroupExpanded(`${catalog.name}:${schema.name}`) || $setup.hasSearch
                                }, null, 8 /* PROPS */, ["expanded"]),
                                _createVNode($setup["Icon"], {
                                  name: "dataset",
                                  color: "white"
                                }),
                                _createElementVNode("div", _hoisted_11, [
                                  _createElementVNode("span", null, _toDisplayString(schema.name), 1 /* TEXT */)
                                ]),
                                _createElementVNode("div", _hoisted_12, _toDisplayString(schema.tables.length) + " " + _toDisplayString($setup.pluralize("Table", schema.tables.length)), 1 /* TEXT */)
                              ])
                            ], 8 /* PROPS */, _hoisted_9),
                            ($setup.isGroupExpanded(`${catalog.name}:${schema.name}`) || $setup.hasSearch)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.listSystemTables($setup.provider!, catalog, schema), (table) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(["flex flex-col items-start justify-center gap-5 py-5 pl-[60px] pr-15 hover:bg-gray-900", { '-selected': $setup.isSelected(table), 'cursor-pointer': !table.loaded }]),
                                    key: `${table.group}-${table.name}`,
                                    "data-test": `table-${table.name}`,
                                    onClick: ($event: any) => ($setup.toggleSelected(table))
                                  }, [
                                    _createElementVNode("div", _hoisted_14, [
                                      (table.loaded)
                                        ? (_openBlock(), _createBlock($setup["Icon"], {
                                            key: 0,
                                            name: "table",
                                            color: "orange"
                                          }))
                                        : ($setup.isSelected(table))
                                          ? (_openBlock(), _createBlock($setup["Icon"], {
                                              key: 1,
                                              name: "checkbox-selected",
                                              color: "none"
                                            }))
                                          : (_openBlock(), _createBlock($setup["Icon"], {
                                              key: 2,
                                              name: "table",
                                              color: "white"
                                            })),
                                      _createVNode($setup["Tooltip"], {
                                        placement: "right-start",
                                        delay: { show: 500, hide: 100 }
                                      }, {
                                        popper: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_15, [
                                            _createElementVNode("div", _hoisted_16, _toDisplayString(`${catalog.name}.${schema.name}.${table.name}`), 1 /* TEXT */),
                                            _createTextVNode(" " + _toDisplayString(table.table.comment), 1 /* TEXT */)
                                          ])
                                        ]),
                                        default: _withCtx(() => [
                                          _createElementVNode("span", {
                                            class: _normalizeClass({ 'text-orange': table.loaded })
                                          }, _toDisplayString(table.name), 3 /* TEXT, CLASS */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    _createElementVNode("div", _hoisted_17, [
                                      (table.table.column_count)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(table.table.column_count) + " " + _toDisplayString($setup.pluralize("col", table.table.column_count)), 1 /* TEXT */))
                                        : _createCommentVNode("v-if", true),
                                      (table.table.rows)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString($setup.rowCount(table.table.rows)), 1 /* TEXT */))
                                        : _createCommentVNode("v-if", true),
                                      (table.table.bytes)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString($setup.formatBytes(table.table.bytes)), 1 /* TEXT */))
                                        : _createCommentVNode("v-if", true)
                                    ])
                                  ], 10 /* CLASS, PROPS */, _hoisted_13))
                                }), 128 /* KEYED_FRAGMENT */))
                              : _createCommentVNode("v-if", true)
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                  ]))
                }), 128 /* KEYED_FRAGMENT */)),
                ($setup.hasSearch && $setup.catalogs.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, " No resources match your search "))
                  : _createCommentVNode("v-if", true),
                (!$setup.hasSearch && $setup.catalogs.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, " Catalog empty "))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          ($setup.status === $setup.AsyncStatus.InProgress)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode($setup["Spinner"])
              ]))
            : _createCommentVNode("v-if", true),
          ($setup.status === $setup.AsyncStatus.Failed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createVNode($setup["MessageBar"], { mode: "error" }, {
                  title: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Error loading tables ")
                  ])),
                  actions: _withCtx(() => [
                    _createVNode($setup["TextButton"], {
                      label: "Refresh",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.refreshTables())),
                      mode: "error"
                    })
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(" " + _toDisplayString(($setup.sourceBrowserStore.catalogs as AsyncFailed).message) + " ", 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    ($setup.selectionCount > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, _toDisplayString($setup.pluralize("data source", $setup.selectionCount, true)) + " selected ", 1 /* TEXT */),
          _createVNode($setup["TextButton"], {
            label: "Load",
            "data-test": "load",
            onClick: $setup.commitTables
          })
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}