import { defineComponent as _defineComponent } from 'vue'
import { environment } from "@/common/environments/environmentLoader";
import { getCookie } from "@/common/lib/cookie";
import { onMounted } from "vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'RedirectPage',
  setup(__props, { expose: __expose }) {
  __expose();

const userStore = useUserStore();
const { userSettings } = storeToRefs(userStore);

onMounted(async () => {
  await userStore.reloadUser();
  const defaultUrl = `${location.origin}${environment.require("DEFAULT_LANDING_PAGE")}`;
  const redirectUrl = getCookie("ct_redirect_url");
  let customUrl = userSettings?.value?.default_homepage;
  customUrl = !customUrl || customUrl === "/" ? undefined : `${location.origin}${customUrl}`;
  document.location.href = customUrl ?? redirectUrl ?? defaultUrl;
});

const __returned__ = { userStore, userSettings }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})