import { defineComponent as _defineComponent } from 'vue'
import IconButton from "@/common/components/IconButton.vue";
import Menu from "@/common/components/Menu.vue";
import { propertyName } from "@/common/lib/derived";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeDerivedColumn',
  props: {
    alias: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { alias } = toRefs(props);

const exploreStore = useExploreStore();

const column = () => exploreStore.columnByAlias(alias.value)!;

const label = computed(() => propertyName(column().property_type, undefined, column().displayName));
const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.Column, {
    columnAlias: alias.value,
  })
);

function removeColumn() {
  exploreStore.removeColumn(alias.value);
}

const __returned__ = { props, alias, exploreStore, column, label, menuItems, removeColumn, IconButton, Menu, get Dropdown() { return Dropdown } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})