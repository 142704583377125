import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { DerivedPropertyTerm, DividePropertyType, PropertyOpType } from "@/common/lib/derived";
import { Ref, ref, watchEffect } from "vue";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DividePropertyDef',
  props: {
    "modelValue": { type: [Object, null], ...{ required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const OP = PropertyOpType.Divide;

const divisor: Ref<DerivedPropertyTerm | null> = ref(null);
const dividend: Ref<DerivedPropertyTerm | null> = ref(null);

const model = _useModel<DividePropertyType | null>(__props, "modelValue");

watchEffect(function () {
  if (divisor.value == null || dividend.value == null) {
    model.value = null;
  } else {
    model.value = {
      op: OP,
      divisor: divisor.value,
      dividend: dividend.value,
    };
  }
});

const __returned__ = { OP, divisor, dividend, model, DerivedPropertyDef }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})