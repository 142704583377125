import { createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpinnerButton = _resolveComponent("SpinnerButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.mode === 'email')
      ? (_openBlock(), _createBlock($setup["Start"], {
          key: 0,
          icon: "md:lock_reset",
          title: "Reset password"
        }, {
          footer: _withCtx(() => [
            (!$setup.connecting)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode($setup["TextButton"], {
                    label: "Back",
                    secondary: true,
                    onClick: $setup.goToLogin
                  }),
                  _createVNode($setup["TextButton"], {
                    label: "Reset password",
                    onClick: $setup.resetPassword,
                    disabled: !$setup.formValidated
                  }, null, 8 /* PROPS */, ["disabled"])
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createBlock(_component_SpinnerButton, {
                  key: 1,
                  label: "Resetting password…"
                }))
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-lg text-white" }, " Enter the email address of your claritype user account and we’ll send you a link to reset your password. ", -1 /* HOISTED */)),
              _createElementVNode("div", null, [
                _createVNode($setup["Textbox"], {
                  label: "Email",
                  type: "email",
                  autocomplete: "email",
                  id: "email-field",
                  modelValue: $setup.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.mode === 'check')
      ? (_openBlock(), _createBlock($setup["Start"], {
          key: 1,
          icon: "md:mark_email_read",
          title: "Email sent"
        }, {
          footer: _withCtx(() => [
            _createVNode($setup["TextButton"], {
              label: "Done",
              onClick: $setup.goToLogin
            })
          ]),
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col gap-10" }, [
              _createElementVNode("div", { class: "text-lg text-white" }, "Check your inbox for next steps.")
            ], -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}