<template>
  <div class="border-t border-t-gray-900 pt-10">
    <template v-if="warehouses.status === AsyncStatus.Succeeded">
      <Select
        label="Warehouse"
        v-model="currentWarehouseId"
        v-if="warehouses.result.length > 0"
        :options="warehouses.result"
        :option-label="(w: Warehouse) => w.name"
        :option-value="(w: Warehouse) => w.id"
        @update:model-value="updateWarehouse"
        :clearable="false"
      >
        <template v-slot:option-data="warehouse: Warehouse">
          <span class="flex flex-row items-center justify-between gap-10">
            <span>{{ warehouse.name }}</span>
            <span class="text-sm text-gray-300">{{ warehouse.cluster_size }}</span>
          </span>
        </template>
      </Select>
      <div v-else class="flex w-full flex-col gap-5">
        <Label label="Warehouse" />

        <div class="text-center text-gray-100 opacity-[0.6]">No warehouses</div>
      </div>
    </template>
    <div v-if="warehouses.status === AsyncStatus.InProgress" class="flex w-full flex-col">
      <Label label="Warehouse" />
      <div class="flex flex-col items-center">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Warehouse } from "@/common/stores/workspaceStore";
import { Async, asyncNotStarted, AsyncStatus } from "@/common/lib/async";
import { useAdminStore } from "@/common/stores/adminStore";
import { onMounted, ref, toRefs } from "vue";
import { isNil } from "lodash";
import Select from "../Select.vue";
import Label from "../Label.vue";
import Spinner from "../Spinner.vue";

const props = defineProps<{
  userId: string;
  workspaceId: string;
  currentWarehouseId?: string;
}>();
const { userId, workspaceId } = toRefs(props);
const emits = defineEmits(["warehouseUpdated"]);
const currentWarehouseId = ref<string | undefined>();

const adminStore = useAdminStore();

const warehouses = ref<Async<Warehouse[]>>(asyncNotStarted());

async function updateWarehouse() {
  if (isNil(currentWarehouseId.value)) {
    return;
  }
  await adminStore.setCurrentWarehouse({
    userId: userId.value,
    workspaceId: workspaceId.value,
    warehouseId: currentWarehouseId.value,
  });
  emits("warehouseUpdated");
}

onMounted(async () => {
  currentWarehouseId.value = props.currentWarehouseId;
  warehouses.value = await adminStore.listWarehouses(workspaceId.value);
});
</script>
