import { defineComponent as _defineComponent } from 'vue'
import { StyleValue } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
    style: { type: [Boolean, null, String, Object, Array], required: false, skipCheck: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const __returned__ = {  }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})