import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { Ref, computed, ref, watchEffect } from "vue";
import IconButtonV2 from "./IconButtonV2.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ListBuilder',
  props: /*@__PURE__*/_mergeModels({
    title: { type: String, required: true }
  }, {
    "modelValue": { type: Array, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {
  __expose();

const list: Ref<Array<T | null>> = ref([]);


const model = _useModel<Array<T | null>>(__props, "modelValue");


function addNullItem() {
  list.value.push(null);
}

function removeItem(index: number) {
  list.value.splice(index, 1);
  updateModel();
}

function updateItem(index: number, newValue: T | null) {
  list.value[index] = newValue;
  updateModel();
}

function updateModel() {
  model.value = list.value.filter((v) => v != null);
}

const listHasNullItem = computed(() => list.value.includes(null));

watchEffect(() => (list.value = [...model.value]));

const __returned__ = { list, model, addNullItem, removeItem, updateItem, updateModel, listHasNullItem, IconButtonV2 }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})