import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
type Value = string | number | symbol | boolean;

interface Option {
  label: string;
  value: Value;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectStrip',
  props: /*@__PURE__*/_mergeModels({
    options: { type: Array, required: true }
  }, {
    "modelValue": { type: [String, Number, Symbol, Boolean], ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {
  __expose();



const model = _useModel<Value>(__props, "modelValue");

const __returned__ = { model }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})