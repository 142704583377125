import { defineComponent as _defineComponent } from 'vue'
import { computed, useSlots } from "vue";
import vSelect, { VueSelectInstance } from "vue-select";
import { isString, isFunction } from "lodash";
import { DropdownOption } from "../lib/comparators";


export default /*@__PURE__*/_defineComponent({
  __name: 'Select',
  props: {
    label: { type: String, required: false },
    modelValue: { type: [String, Object], required: false },
    placeholder: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    options: { type: Array, required: true },
    optionLabel: { type: [String, Function], required: false },
    optionValue: { type: Function, required: false },
    required: { type: Boolean, required: false },
    clearable: { type: Boolean, required: false, default: true },
    multiple: { type: Boolean, required: false, default: false },
    filter: { type: Function, required: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;
const slots = useSlots();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const requiredText = computed(() => {
  if (props.required) {
    return ':after:content-["*"] after:text-orange';
  } else {
    return "";
  }
});

const __returned__ = { props, emit, slots, value, requiredText, get vSelect() { return vSelect }, get isString() { return isString }, get isFunction() { return isFunction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})