<template>
  <Dropdown placement="right" v-model:shown="isEditing" @apply-show="focusFirstField">
    <template #popper>
      <FilterEditor
        :label="editingDescription"
        :valid="isValid"
        @cancel="isEditing = false"
        @commit="commit"
      >
        <Textbox
          label="Values"
          helper-text="Enter one value per line"
          textarea
          v-model="valueUnderEdit"
          ref="valueInputEl"
        />

        <Select
          v-model="matchType"
          label="Matching mode"
          :options="textFilterMatchOptions"
          :option-label="optionLabel"
          :clearable="false"
        />
        <div class="mt-5">
          <Checkbox label="Ignore case" v-model="ignoreCase" />
        </div>
      </FilterEditor>
    </template>
    <div
      @click="startEditing"
      class="cursor-pointer border-b border-b-transparent hover:border-b-gray-500"
    >
      {{ opDescription }}
      <span class="font-bold">{{ valueList }}</span>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
import { TextFilterMatch, textFilterMatchOptions } from "@/common/lib/query";
import { stringifyValue, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, onMounted, Ref, ref, toRefs } from "vue";
import Select from "@/common/components/Select.vue";
import Checkbox from "@/common/components/Checkbox.vue";
import { QueryFilter } from "@/common/lib/query";
import { TextFilter } from "@/common/lib/fetchApi";
import { Dropdown } from "floating-vue";
import FilterEditor from "../FilterEditor.vue";
import { propertyName } from "@/common/lib/derived";
import Textbox from "@/common/components/Textbox.vue";

const props = defineProps<{ filter: QueryFilter<TextFilter> }>();
const { filter } = toRefs(props);

const isEditing: Ref<boolean> = ref(false);
const valueUnderEdit: Ref<string | undefined> = ref(undefined);
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);
const matchType = ref<TextFilterMatch>(TextFilterMatch.Full);
const ignoreCase = ref(true);

interface TextFilterMatchDetails {
  label: string;
  description: string;
  negatedDescription: string;
}

function optionDetails(option: TextFilterMatch): TextFilterMatchDetails {
  switch (option) {
    case TextFilterMatch.Contain:
      return {
        label: "Contains",
        description: "contains",
        negatedDescription: "doesn't contain",
      };
    case TextFilterMatch.Start:
      return {
        label: "Starts with",
        description: "starts with",
        negatedDescription: "doesn't start with",
      };
    case TextFilterMatch.End:
      return {
        label: "Ends with",
        description: "ends with",
        negatedDescription: "doesn't end with",
      };
    case TextFilterMatch.Full:
      return {
        label: "Exact match",
        description: "exactly matches",
        negatedDescription: "doesn't exactly match",
      };
  }
}

const opDescription = computed(() => {
  const value = filter.value.values[0];
  const match = (value?.match ?? TextFilterMatch.Full) as TextFilterMatch;
  return makeDescription(match, filter.value.values.length > 1);
});

const editingDescription = computed(
  () => `${propertyName(filter.value.property_type)} ${makeDescription(matchType.value, true)}`
);

function makeDescription(option: TextFilterMatch, multiple: boolean) {
  let desc = "";
  const details = optionDetails(option);
  desc = filter.value.negated ? details.negatedDescription : details.description;
  if (multiple) {
    desc = desc + " any of";
  }
  return desc;
}

function optionLabel(option: TextFilterMatch) {
  return optionDetails(option).label;
}

const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);
const isValid = computed(() => cleanEditedValue().length > 0);

const exploreStore = useExploreStore();

function focusFirstField() {
  setTimeout(() => valueInputEl.value?.focus(), 50); // Boy do I hate this
}

function startEditing() {
  const value = filter.value.values[0];
  valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
  matchType.value = (value?.match ?? TextFilterMatch.Full) as TextFilterMatch;
  ignoreCase.value = !(value?.case_sensitive ?? false);
  isEditing.value = true;
}

function cleanEditedValue() {
  return valueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  filter.value.values = cleanEditedValue().map((v) => ({
    value: toValue(v),
    match: matchType.value,
    case_sensitive: !ignoreCase.value,
  }));
  isEditing.value = false;
  exploreStore.load();
}

onMounted(function () {
  if (filter.value.values.length === 0) startEditing();
});
</script>
