import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-10 w-[400px]" }
const _hoisted_2 = { class: "p-10 font-medium" }
const _hoisted_3 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-lg font-bold uppercase" }, "Add Calculation", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["DerivedPropertyDef"], {
        modelValue: $setup.derivedTerm,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.derivedTerm) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString($setup.description), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["TextButton"], {
        label: "Cancel",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.exploreStore.creatingCalculation = undefined), ["stop"])),
        secondary: true
      }),
      _createVNode($setup["TextButton"], {
        label: "Add",
        onClick: _withModifiers($setup.commit, ["stop"]),
        disabled: $setup.isInvalid
      }, null, 8 /* PROPS */, ["disabled"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}