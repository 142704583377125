import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "absolute inset-0 z-0 overflow-auto",
  "data-test": "table"
}
const _hoisted_2 = { class: "z-10 border-collapse" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "mx-auto" }
const _hoisted_5 = { class: "flex content-center items-center" }
const _hoisted_6 = {
  key: 0,
  class: "max-w-[400px] overflow-hidden overflow-ellipsis"
}
const _hoisted_7 = { class: "justify-end p-10 text-right align-baseline text-gray-200 group-hover:text-gray-500 dark:text-gray-500" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute left-0 right-0 top-0 z-0 h-[32px] bg-gray-200 dark:bg-gray-800" }, null, -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _cache[2] || (_cache[2] = _createElementVNode("th", null, [
              _createElementVNode("div", { class: "absolute inset-0 border-b border-r border-white dark:border-gray-900" })
            ], -1 /* HOISTED */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (colDetails) => {
              return (_openBlock(), _createElementBlock("th", {
                key: colDetails.column.alias,
                class: "align-baseline",
                onClick: ($event: any) => ($setup.toggleDropdownShown(colDetails.column.alias, true))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex items-center justify-between whitespace-nowrap border-b border-r border-white pl-10 dark:border-gray-900", { 'justify-end text-right': colDetails.isQuantitative }])
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, [
                      (colDetails.column.alias !== $setup.renamingColumnAlias)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(colDetails.label), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      (colDetails.column.alias !== $setup.renamingColumnAlias)
                        ? (_openBlock(), _createBlock($setup["Dropdown"], {
                            key: 1,
                            placement: "bottom",
                            class: "cursor-pointer flex-row",
                            shown: $setup.isDropdownShown(colDetails.column.alias),
                            "onUpdate:shown": ($event: any) => ($setup.toggleDropdownShown(colDetails.column.alias, $event)),
                            triggers: []
                          }, {
                            popper: _withCtx(() => [
                              _withDirectives(_createVNode($setup["Menu"], {
                                items: colDetails.menuItems
                              }, null, 8 /* PROPS */, ["items"]), [
                                [_directive_close_popper]
                              ])
                            ]),
                            default: _withCtx(() => [
                              _createVNode($setup["IconButton"], {
                                name: "chevron-down",
                                size: "xs"
                              })
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["shown", "onUpdate:shown"]))
                        : (_openBlock(), _createBlock($setup["Textbox"], {
                            key: 2,
                            autoselect: true,
                            modelValue: $setup.renamingColumn,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.renamingColumn) = $event)),
                            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => ($setup.deselectAndRename($event)), ["enter"])),
                            onBlur: ($event: any) => ($setup.rename(colDetails.column))
                          }, null, 8 /* PROPS */, ["modelValue", "onBlur"]))
                    ])
                  ]),
                  (colDetails.sort !== undefined)
                    ? (_openBlock(), _createBlock($setup["IconButton"], {
                        key: 0,
                        name: colDetails.sort.asc ? 'md:arrow_upward' : 'md:arrow_downward',
                        label: colDetails.sort.asc ? 'Switch to descending' : 'Switch to ascending',
                        preText: $setup.sortOrderLabel(colDetails.sort!.index),
                        size: "s",
                        onClick: _withModifiers(($event: any) => ($setup.toggleAscendingAt(colDetails.sort!.index)), ["stop"])
                      }, null, 8 /* PROPS */, ["name", "label", "preText", "onClick"]))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */)
              ], 8 /* PROPS */, _hoisted_3))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.table, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: index,
              class: "group light:hover:bg-gray-100 dark:odd:bg-gray-800 dark:odd:text-gray-100"
            }, [
              _createElementVNode("td", _hoisted_7, _toDisplayString($setup.formatWithLeadingZeros(index + 1, 2)), 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (colDetails) => {
                return (_openBlock(), _createElementBlock("td", {
                  class: "p-10 align-baseline",
                  key: colDetails.column.alias
                }, [
                  (row[colDetails.column.alias].values.length > 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row[colDetails.column.alias].values, (value, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createVNode($setup["PropertyValue"], {
                              value: value,
                              column: colDetails.column,
                              quantitative: colDetails.isQuantitative
                            }, null, 8 /* PROPS */, ["value", "column", "quantitative"])
                          ]))
                        }), 128 /* KEYED_FRAGMENT */)),
                        (row[colDetails.column.alias].isTruncated)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, "..."))
                          : _createCommentVNode("v-if", true)
                      ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["opacity-50", { 'text-right': colDetails.isQuantitative }])
                      }, " - ", 2 /* CLASS */))
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}