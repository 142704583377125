import { defineComponent as _defineComponent } from 'vue'
import TextButton from "@/common/components/TextButton.vue";
import { ref, onMounted } from "vue";
import DbxEditConnectionSettings from "./DbxEditConnectionSettings.vue";
import SnowflakeEditConnectionSettings from "./SnowflakeEditConnectionSettings.vue";
import {
  useWorkspaceStore,
  FullWorkspace,
  WorkspacesState,
  ProviderType,
} from "@/common/stores/workspaceStore";
import ProviderButton from "./ProviderButton.vue";
import { storeToRefs } from "pinia";
import MySqlEditConnectionSettings from "./MySqlEditConnectionSettings.vue";
import { environment } from "@/common/environments/environmentLoader";
import StarRocksConnectionSettings from "./StarRocksConnectionSettings.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EditConnectionSettings',
  props: {
    mode: { type: String, required: true },
    workspaceId: { type: String, required: false }
  },
  emits: ["saveWorkspace"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emits = __emit;

const enabledProviders: string[] = environment.requireJson("PROVIDER_ENABLED_SET");

const selectedProvider = ref<ProviderType | undefined>();
const workspaceStore = useWorkspaceStore();
const { workspacesState } = storeToRefs(workspaceStore);

const workspaceSettings = ref<FullWorkspace>();

function cancelEdit() {
  if (props.mode === "edit") {
    cancelEditWorkspace();
  } else {
    selectedProvider.value = undefined;
  }
}

function cancelEditWorkspace() {
  emits("saveWorkspace");
}

onMounted(async () => {
  if (props.mode === "create") {
    return;
  }
  if (props.workspaceId) {
    const workspace = await workspaceStore.loadFullWorkspace(props.workspaceId);
    workspaceSettings.value = workspace;
    selectedProvider.value = workspace.metadata?.provider;
  }
});

const __returned__ = { props, emits, enabledProviders, selectedProvider, workspaceStore, workspacesState, workspaceSettings, cancelEdit, cancelEditWorkspace, TextButton, DbxEditConnectionSettings, SnowflakeEditConnectionSettings, get WorkspacesState() { return WorkspacesState }, get ProviderType() { return ProviderType }, ProviderButton, MySqlEditConnectionSettings, StarRocksConnectionSettings }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})