import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { DerivedPropertyTerm, PropertyOpType } from "@/common/lib/derived";
import DerivedPropertyDef from "./DerivedPropertyDef.vue";
import IconButton from "@/common/components/IconButton.vue";
import { Ref, ref, watchEffect } from "vue";
import { v4 as uuidv4 } from "uuid";

interface MultiTerm {
  id: string;
  term: DerivedPropertyTerm | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MultiTerm',
  props: /*@__PURE__*/_mergeModels({
    parentOp: { type: String, required: false }
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {
  __expose();



const model = _useModel(__props, "modelValue");
const terms: Ref<MultiTerm[]> = ref([newTerm(), newTerm()]);

function newTerm() {
  return {
    id: uuidv4(),
    term: null,
  };
}

function setTerm(id: string, value: DerivedPropertyTerm | null) {
  terms.value.find((t) => t.id === id)!.term = value;
}

function deleteTerm(id: string) {
  terms.value.splice(
    terms.value.findIndex((t) => t.id === id),
    1
  );
}

function addTerm() {
  terms.value.push(newTerm());
}

watchEffect(() => (model.value = terms.value.map((t) => t.term)));

const __returned__ = { model, terms, newTerm, setTerm, deleteTerm, addTerm, DerivedPropertyDef, IconButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})