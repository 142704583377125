import { defineComponent as _defineComponent } from 'vue'
import IconButton from "@/common/components/IconButton.vue";
import Icon from "@/common/components/Icon.vue";
import Menu from "@/common/components/Menu.vue";
import { PropertyOpType } from "@/common/lib/derived";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import useKnowledge from "@/common/composables/useKnowledge";
import { buildSimpleColumn, findCurrentColumn } from "@/reader/lib/explore";
import { exploreMenu, ExploreMenuSubject } from "@/reader/lib/exploreMenus";
import { expandTreePath, ExploreTreePath } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { Dropdown } from "floating-vue";
import { computed, toRefs } from "vue";
import Filter from "../Filter.vue";
import TreeDerivedColumn from "./TreeDerivedColumn.vue";
import { GROUP_BY_ALL } from "@/common/lib/fetchApi";
import { isEqual } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'TreeProperty',
  props: {
    conceptPath: { type: Array, required: true },
    propertyType: { type: null, required: true },
    derivedAliases: { type: Array, required: true },
    isResolved: { type: Boolean, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { propertyType, conceptPath } = toRefs(props);

const exploreStore = useExploreStore();
const { typeLabel } = useKnowledge();

const label = computed(() => typeLabel(propertyType.value));
const isCurrentColumn = computed(() => !!findCurrentColumn(column()));
const menuItems = computed(() =>
  exploreMenu(ExploreMenuSubject.PropertyType, {
    conceptPath: conceptPath.value,
    propertyType: propertyType.value,
  })
);
const nonaggregateColumnsProhibited = computed(() => exploreStore.query!.group_by === GROUP_BY_ALL);

function column(op?: PropertyOpType) {
  return buildSimpleColumn(expandTreePath(conceptPath.value), propertyType.value, op);
}

function toggleBasicColumn(op?: PropertyOpType) {
  const currentColumn = findCurrentColumn(column(op));
  if (currentColumn != null) {
    exploreStore.removeColumn(findCurrentColumn(column(op))!);
  } else {
    exploreStore.addColumn(column(op));
  }
}

const relatedFilters = computed(function () {
  const asColumn = column();
  return exploreStore.query!.filters.filter(
    (f) => isEqual(f.path, asColumn.path) && f.property_type === asColumn.property_type
  );
});

const __returned__ = { props, propertyType, conceptPath, exploreStore, typeLabel, label, isCurrentColumn, menuItems, nonaggregateColumnsProhibited, column, toggleBasicColumn, relatedFilters, IconButton, Icon, Menu, get Dropdown() { return Dropdown }, Filter, TreeDerivedColumn }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})