import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex max-h-screen w-[450px] flex-col scroll-auto p-10" }
const _hoisted_2 = { class: "flex flex-row gap-5" }
const _hoisted_3 = {
  key: 0,
  class: "px-10 py-[7px] text-gray-500"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex flex-row items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Icon"], {
        name: "star_filled",
        size: "xl",
        class: "icon",
        color: $setup.iconColor
      }, null, 8 /* PROPS */, ["color"]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "self-center text-xxl text-gray-700 dark:text-gray-300" }, "Bookmarks", -1 /* HOISTED */))
    ]),
    (typeof $setup.bookmarks === 'string')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.bookmarks), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.bookmarks, (bookmark) => {
            return (_openBlock(), _createElementBlock("div", {
              key: bookmark.key,
              class: "flex items-center px-10"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["basis-full bg-white p-[7px] dark:bg-black", {
            'cursor-default text-gray-300 dark:text-gray-700': bookmark.disabled,
            'text-gray-800 hover:cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-900':
              !bookmark.disabled,
          }]),
                onClick: ($event: any) => ($setup.select(bookmark))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, _toDisplayString(bookmark.label), 1 /* TEXT */),
                  (bookmark.submenu.length > 0)
                    ? (_openBlock(), _createBlock($setup["Dropdown"], {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                      }, {
                        popper: _withCtx(() => [
                          _withDirectives(_createVNode($setup["Menu"], {
                            items: bookmark.submenu
                          }, null, 8 /* PROPS */, ["items"]), [
                            [
                              _directive_close_popper,
                              void 0,
                              void 0,
                              { all: true }
                            ]
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createVNode($setup["Tooltip"], { delay: { show: 1000, hide: 100 } }, {
                            popper: _withCtx(() => [
                              _createTextVNode("Manage " + _toDisplayString(bookmark.label), 1 /* TEXT */)
                            ]),
                            default: _withCtx(() => [
                              _createVNode($setup["IconButton"], {
                                name: bookmark.disabled ? 'md:error' : 'md:more_vert',
                                size: "m"
                              }, null, 8 /* PROPS */, ["name"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */))
                    : _createCommentVNode("v-if", true)
                ])
              ], 10 /* CLASS, PROPS */, _hoisted_5)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
  ]))
}