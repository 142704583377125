import { defineComponent as _defineComponent } from 'vue'
import { computed, inject, Ref, toRefs } from "vue";
import { getIconSizePx, IconColor, IconColorSpec, IconSize, parseIconName } from "../lib/icons";
import { DarkMode } from "../lib/keys";
import { isString } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'Icon',
  props: {
    name: { type: String, required: true },
    size: { type: String, required: false, default: "l" },
    color: { type: [Object, String], required: false, default: () => ({ dark: "white", light: "black" }) }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const { name, size, color } = toRefs(props);
const darkMode = inject(DarkMode) as Ref<boolean>;

const src = computed(() => parseIconName(name.value));
const sizePx = computed(() => getIconSizePx(size.value));
const colorClass = computed(function () {
  let effectiveColor: IconColor;
  if (isString(color.value)) {
    effectiveColor = color.value;
  } else {
    effectiveColor = darkMode.value ? color.value.dark : color.value.light;
  }
  return `svg-to-${effectiveColor}`;
});

const __returned__ = { props, name, size, color, darkMode, src, sizePx, colorClass }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})