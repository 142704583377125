import { defineComponent as _defineComponent } from 'vue'
import BookmarksList from "../explore/BookmarksList.vue";
import IconButton2 from "@/common/components/IconButtonV2.vue";
import { Bookmark } from "@/common/stores/userModuleStore";
import { Dropdown, Tooltip } from "floating-vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'BookmarkMenu',
  props: {
    projectId: { type: String, required: true }
  },
  emits: ["updateBookmark", "displayError", "deleteBookmark"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

function updateBookmark(bookmark: Bookmark) {
  emit("updateBookmark", bookmark);
}

function displayError(bookmark: Bookmark, errors: string[]) {
  emit("displayError", bookmark, errors);
}

function deleteBookmark(bookmark: Bookmark) {
  emit("deleteBookmark", bookmark);
}

const __returned__ = { props, emit, updateBookmark, displayError, deleteBookmark, BookmarksList, IconButton2, get Dropdown() { return Dropdown }, get Tooltip() { return Tooltip } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})