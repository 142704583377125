import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Start"], { title: "Logged out" }, {
    footer: _withCtx(() => [
      _createVNode($setup["TextButton"], {
        label: "Log back in",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goHome()))
      })
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("div", null, "You have logged out.", -1 /* HOISTED */))
    ]),
    _: 1 /* STABLE */
  }))
}