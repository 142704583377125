import { defineComponent as _defineComponent } from 'vue'
import { useTimer } from "@/common/composables/useTimer";
import { environment } from "@/common/environments/environmentLoader";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import Spinner from "./Spinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarehouseLoading',
  props: {
    spinner: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();


const workspaceStore = useWorkspaceStore();
const { currentWarehouse } = storeToRefs(workspaceStore);

const timer = useTimer(environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS"));

watch(
  () => currentWarehouse?.value,
  (warehouse) => {
    if (warehouse?.state !== "RUNNING") {
      timer.start(async () => await workspaceStore.refreshCurrentWarehouse());
    } else {
      timer.stop();
    }
  },
  { immediate: true }
);

const __returned__ = { workspaceStore, currentWarehouse, timer, Spinner }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})