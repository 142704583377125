import { defineComponent as _defineComponent } from 'vue'
import { useKnowledgeStore } from "@/common/stores/knowledgeStore";
import { computed, ComputedRef } from "vue";
import { ConceptType, knowledgeItemId, KnowledgeType } from "../lib/knowledge";
import Icon from "./Icon.vue";
import Select from "./Select.vue";
import { useExploreStore } from "@/reader/stores/explore";
import { storeToRefs } from "pinia";
import { knowledgeSortHeuristic, vsFilterSort } from "../lib/comparators";
import { IconColor } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConceptSelector',
  props: {
    label: { type: String, required: false },
    modelValue: { type: String, required: false },
    placeholder: { type: String, required: false },
    includeAdhoc: { type: Boolean, required: false },
    conceptIds: { type: Array, required: false },
    includeConceptId: { type: Boolean, required: false },
    iconColor: { type: String, required: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const { conceptColors } = storeToRefs(useExploreStore());

const knowledgeStore = useKnowledgeStore();

const props = __props;
const emit = __emit;

const concepts = computed(() => {
  let allConcepts = conceptMap.value;
  if (props.conceptIds) {
    const conceptSet = new Set(props.conceptIds);
    allConcepts = allConcepts.filter(([k, _]) => conceptSet.has(k));
  }

  const conceptTypes = allConcepts.map(([_, v]) => v);
  conceptTypes.sort(knowledgeSortHeuristic);
  return conceptTypes;
});

const conceptMap: ComputedRef<Array<[string, ConceptType]>> = computed(() => {
  if (props.includeAdhoc) {
    return Object.entries(knowledgeStore.combinedKnowledge).flatMap(([k, v]) =>
      v.type === KnowledgeType.Concept ? [[k, v]] : []
    );
  }
  return Object.entries(knowledgeStore.concepts);
});

function color(conceptType: string) {
  return conceptColors.value[conceptType];
}

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const __returned__ = { conceptColors, knowledgeStore, props, emit, concepts, conceptMap, color, value, get knowledgeItemId() { return knowledgeItemId }, Icon, Select, get vsFilterSort() { return vsFilterSort } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})