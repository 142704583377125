import { useNavigation } from "../composables/useNavigation";
import { environment } from "../environments/environmentLoader";
import { clearCookie, getCookie, setCookie } from "../lib/cookie";
import { User } from "../lib/user";
import { Sentry } from "../monitoring/sentry/sentry";
import { useUserStore } from "../stores/userStore";
import { AuthProvider } from "./authProvider";
import { useBackendAuth } from "./backendAuth";

const ACCESS_TOKEN_KEY = "ct_access_token";
const REFRESH_TOKEN_KEY = "ct_refresh_token";
const REDIRECT_URL_KEY = "ct_redirect_url";

/**
 * Auth Provider that proxies Auth0 calls through the backend.
 */
export class BackendAuthProvider implements AuthProvider {
  private _accessToken?: string;
  private loginUrl: string;
  private authRedirectPage: string;
  private defaultLandingPage: string;
  private currentRefresh: Promise<boolean> | undefined;

  constructor(private sentry?: Sentry) {
    this.loginUrl = environment.require("AUTH_BACKEND_LOGIN_URL");
    this.authRedirectPage = environment.require("AUTH_REDIRECT_PAGE");
    this.defaultLandingPage = environment.require("DEFAULT_LANDING_PAGE");
  }

  public set accessToken(accessToken: string | undefined) {
    setCookie(ACCESS_TOKEN_KEY, accessToken);
    this._accessToken = accessToken;
  }

  public get accessToken(): string | undefined {
    if (!this._accessToken) {
      this._accessToken = getCookie(ACCESS_TOKEN_KEY);
    }
    return this._accessToken;
  }

  public set refreshToken(token: string | undefined) {
    setCookie(REFRESH_TOKEN_KEY, token);
  }

  public get refreshToken(): string | undefined {
    return getCookie(REFRESH_TOKEN_KEY);
  }

  public authGuard(): Promise<boolean> {
    const success = this.accessToken != null;
    if (!success) {
      this.handleAuthenticationError();
    }
    // Run in background
    void useUserStore().loadUserOnce();
    return Promise.resolve(success);
  }

  public get isAuthenticated(): boolean {
    return this.accessToken !== undefined;
  }

  public async getAccessToken(): Promise<string> {
    const accessToken = this.accessToken;
    if (!accessToken) {
      await this.handleAuthenticationError();
      throw new Error("No access token!");
    }
    return accessToken;
  }

  public loginWithRedirect(): Promise<void> {
    useNavigation().goToUrl(this.loginUrl);
    return Promise.resolve();
  }

  public logout(returnTo: string): Promise<void> {
    this.sentry?.clearUser();
    clearCookie(ACCESS_TOKEN_KEY);
    clearCookie(REFRESH_TOKEN_KEY);
    useNavigation().goToUrl(returnTo);
    return Promise.resolve();
  }

  public get isLoading(): boolean {
    return false;
  }

  public get isEnabled(): boolean {
    return true;
  }

  public waitAuthenticated(): Promise<boolean> {
    return Promise.resolve(this.isAuthenticated);
  }

  public handleAuthenticationError(): Promise<void> {
    clearCookie(ACCESS_TOKEN_KEY);
    if (![this.loginUrl, this.authRedirectPage].includes(location.pathname)) {
      setCookie(REDIRECT_URL_KEY, location.href, 60);
    }
    return this.loginWithRedirect();
  }

  public finishLogin(options: { accessToken: string; refreshToken?: string; user?: User }) {
    this.accessToken = options.accessToken;
    this.refreshToken = options.refreshToken;
    this.updateUser(options.user);
    useNavigation().goToUrl(`${location.origin}${this.authRedirectPage}`);
  }

  public tryRefreshToken(): Promise<boolean> {
    const refreshToken = this.refreshToken;
    if (!refreshToken) {
      return Promise.resolve(false);
    }
    if (this.accessToken === undefined && this.currentRefresh) {
      return this.currentRefresh;
    }
    this.accessToken = undefined;
    this.currentRefresh = (async () => {
      const response = await useBackendAuth().refreshToken(refreshToken);
      this.accessToken = response.access_token;
      return true;
    })();
    return this.currentRefresh;
  }

  private updateUser(user?: User) {
    this.sentry?.setUser({
      id: user?.user_id,
      email: user?.email,
      name: user?.name,
    });
  }
}
