import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { CountPropertyType, PropertyOpType } from "@/common/lib/derived";
import { onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CountPropertyDef',
  props: {
    "modelValue": { type: [Object, null], ...{ required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const OP = PropertyOpType.Count;

const model = _useModel<CountPropertyType | null>(__props, "modelValue");

onMounted(() => (model.value = { op: OP, approx: false }));

const __returned__ = { OP, model }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})