import { defineComponent as _defineComponent } from 'vue'
import { blobPath } from "@/common/lib/api";
import { BytesValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageValue',
  props: {
    value: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const { value } = toRefs(props);

const path = computed(() => blobPath(value.value));

const __returned__ = { props, value, path }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})