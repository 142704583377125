import { defineComponent as _defineComponent } from 'vue'
import { ExploreTreeNode } from "@/reader/lib/exploreTree";
import { useExploreStore } from "@/reader/stores/explore";
import { computed } from "vue";
import TreeConcept from "./TreeConcept.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Metatree',
  setup(__props, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();
const rootPath = computed(function (): ExploreTreeNode[] {
  return [{ concept_type: exploreStore.query!.root_concept_type }];
});

const __returned__ = { exploreStore, rootPath, TreeConcept }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})