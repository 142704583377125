import { defineComponent as _defineComponent } from 'vue'
import { toRefs, computed, inject, Ref } from "vue";
import { parseIconName } from "../lib/icons";
import { DarkMode } from "../lib/keys";


export default /*@__PURE__*/_defineComponent({
  __name: 'TextButton',
  props: {
    label: { type: String, required: true },
    disabled: { type: Boolean, required: false },
    secondary: { type: Boolean, required: false },
    icon: { type: String, required: false },
    selected: { type: Boolean, required: false },
    darkHover: { type: Boolean, required: false },
    mode: { type: String, required: false },
    menu: { type: Boolean, required: false, default: false },
    textColor: { type: String, required: false },
    pill: { type: Boolean, required: false, default: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { icon, menu } = toRefs(props);

const darkMode = inject(DarkMode) as Ref<boolean>;

const iconSrc = computed(() => (icon.value ? parseIconName(icon.value) : undefined));
const sizePx = computed(() => (menu.value ? 20 : 16));
const textColor = computed(function () {
  if (props.disabled) {
    return darkMode.value ? "gray-700" : "gray-200";
  }
  if (props.textColor) {
    return props.textColor;
  }
  if (props.selected) {
    return darkMode.value ? "gray-200" : "white";
  }
  if (props.secondary) {
    return "gray-500";
  }
  if (icon.value) {
    return darkMode.value ? "gray-200" : "gray-700";
  }
  if (props.mode === "error") {
    return "red-500";
  }
  if (props.mode === "warning") {
    return "yellow-500";
  }
  return "orange";
});

const __returned__ = { props, icon, menu, darkMode, iconSrc, sizePx, textColor }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})