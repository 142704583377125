import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start gap-5 border-t border-t-gray-900 pt-10" }
const _hoisted_2 = { class: "flex w-full flex-row items-center justify-between gap-10" }
const _hoisted_3 = { class: "font-bold text-gray-500" }
const _hoisted_4 = {
  key: 1,
  class: "italic"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */),
        (!$props.readonly)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (!$props.loading)
                ? (_openBlock(), _createBlock($setup["TextButton"], {
                    key: 0,
                    label: "Update",
                    onClick: $setup.openDialog
                  }))
                : (_openBlock(), _createBlock($setup["Spinner"], { key: 1 }))
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true),
        ($props.copyable)
          ? (_openBlock(), _createBlock($setup["TextButton"], {
              key: 1,
              label: "Copy"
            }))
          : _createCommentVNode("v-if", true)
      ]),
      (!$setup.isNil($props.value))
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass({ 'font-extralight': $props.readonly })
          }, _toDisplayString($setup.formattedValue), 3 /* TEXT, CLASS */))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, "Not set"))
    ]),
    ($setup.editMode)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          title: `Edit ${$props.label}`,
          onSucceeded: $setup.saveDialog,
          onCancelled: $setup.closeDialog
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              modelValue: $setup.valueToEdit,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.valueToEdit) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["title"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}