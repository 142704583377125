import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-full bg-gray-200 dark:bg-gray-800" }
const _hoisted_2 = { class: "user-details" }
const _hoisted_3 = { class: "flex bg-gray-100 dark:bg-gray-900" }
const _hoisted_4 = {
  key: 0,
  class: "m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200"
}
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_6 = {
  key: 1,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_7 = { class: "flex flex-col gap-5" }
const _hoisted_8 = {
  key: 0,
  class: "text-500 italic"
}
const _hoisted_9 = {
  key: 2,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_10 = { class: "flex flex-col items-start gap-15" }
const _hoisted_11 = {
  key: 0,
  class: "m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200"
}
const _hoisted_12 = {
  key: 1,
  class: "m-30 text-gray-800 dark:text-gray-200"
}
const _hoisted_13 = {
  key: 0,
  class: "flex flex-col items-start gap-15"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "italic"
}
const _hoisted_16 = {
  key: 2,
  class: "m-30 text-gray-800 dark:text-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["TabSelect"], {
            tabs: $setup.availableTabs,
            modelValue: $setup.selectedTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedTab) = $event))
          }, null, 8 /* PROPS */, ["tabs", "modelValue"])
        ]),
        ($setup.selectedTab === 'user-settings')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              ($setup.user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-xl" }, "Name", -1 /* HOISTED */)),
                    _createElementVNode("div", null, _toDisplayString($setup.user.name), 1 /* TEXT */),
                    _createElementVNode("div", null, [
                      _createVNode($setup["TextButton"], {
                        label: "Update name",
                        onClick: $setup.editUserName
                      })
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              ($setup.user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-xl" }, "Email", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString($setup.user.email), 1 /* TEXT */),
                      ($setup.user.email_verified)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "verified"))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createVNode($setup["TextButton"], {
                      label: "Resync",
                      onClick: $setup.resync
                    })
                  ]))
                : _createCommentVNode("v-if", true),
              ($setup.user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-xl" }, "Password", -1 /* HOISTED */)),
                    _createElementVNode("div", null, [
                      _createVNode($setup["TextButton"], {
                        label: "Change password",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.editingChangePassword = { newPassword: '', confirmNewPassword: '' }))
                      })
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_10, [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-xl" }, "Session", -1 /* HOISTED */)),
                _createVNode($setup["TextButton"], {
                  label: "Logout",
                  onClick: $setup.logout
                })
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      ($setup.selectedTab === 'connection')
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode($setup["ConnectionSettings"])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.selectedTab === 'preferences')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            ($setup.user)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-xl" }, "Home Page", -1 /* HOISTED */)),
                  ($setup.userSettings?.default_homepage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString($setup.userSettings?.default_homepage), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("div", _hoisted_15, "Home page not set.")),
                  _createVNode($setup["TextButton"], {
                    label: "Update\n        home page",
                    onClick: $setup.editHomePage
                  })
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.selectedTab === 'admin')
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createVNode($setup["AdminPage"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    ($setup.editingUserName !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          title: "Update name",
          "success-label": "Update",
          onSucceeded: $setup.finishEditUserName,
          onCancelled: _cache[3] || (_cache[3] = ($event: any) => ($setup.editingUserName = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "Name",
              modelValue: $setup.editingUserName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.editingUserName) = $event)),
              autoselect: true
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.editingChangePassword !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 1,
          title: "Change password",
          "success-label": "Update",
          onSucceeded: $setup.finishChangePassword,
          onCancelled: _cache[5] || (_cache[5] = ($event: any) => ($setup.editingChangePassword = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "New password",
              modelValue: $setup.editingChangePassword.newPassword,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.editingChangePassword.newPassword) = $event)),
              autoselect: true,
              autocomplete: "new-password"
            }, null, 8 /* PROPS */, ["modelValue"]),
            ($setup.editingChangePassword.error)
              ? (_openBlock(), _createBlock($setup["MessageBar"], {
                  key: 0,
                  mode: "error"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString($setup.editingChangePassword.error), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.editingHomePage !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 2,
          title: "Update home page",
          "success-label": "Update",
          onSucceeded: $setup.finishEditHomePage,
          onCancelled: _cache[7] || (_cache[7] = ($event: any) => ($setup.editingHomePage = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "Home page path",
              modelValue: $setup.editingHomePage,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.editingHomePage) = $event)),
              autoselect: true
            }, null, 8 /* PROPS */, ["modelValue"]),
            _cache[13] || (_cache[13] = _createTextVNode(" Enter a custom URL path to determine where you are taken upon login (e.g., /reader). "))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}