import { defineComponent as _defineComponent } from 'vue'
import IconButton from "@/common/components/IconButtonV2.vue";
import { toRefs } from "vue";
import { IconSize } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'Disclosure',
  props: {
    expanded: { type: Boolean, required: true },
    size: { type: String, required: false, default: "l" }
  },
  emits: ["toggle"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { expanded, size } = toRefs(props);

const emit = __emit;

const __returned__ = { props, expanded, size, emit, IconButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})