import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import MessageBar from "@/common/components/MessageBar.vue";
import TextButton from "@/common/components/TextButton.vue";
import { AsyncFailed, asyncResultOr, AsyncStatus } from "@/common/lib/async";
import Disclosure from "@/common/components/Disclosure.vue";
import Spinner from "@/common/components/Spinner.vue";
import { Catalog, Schema, Table } from "@/common/stores/catalog";
import { SourceSelection, SystemTable, useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import pluralize from "pluralize";
import { computed, onMounted, toRefs } from "vue";
import { Tooltip } from "floating-vue";
import { every, find } from "lodash";
import { environment } from "../environments/environmentLoader";
import { formatBytes } from "@/common/lib/text";
import { getMapSection, MapSectionKey, SourceType } from "@/common/lib/map";
import Textbox from "./Textbox.vue";
import useDataset from "@/editor/composables/useDataset";
import { useAppStore } from "@/editor/stores/app";


export default /*@__PURE__*/_defineComponent({
  __name: 'SourceCatalog',
  props: {
    workspaceId: { type: String, required: true }
  },
  emits: ["select-tables"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { workspaceId } = toRefs(props);

const emit = __emit;

const sourceBrowserStore = useSourceBrowserStore();
const appStore = useAppStore();

const status = computed(() => sourceBrowserStore.catalogs.status);
const provider = computed(() => sourceBrowserStore.provider);
const catalogs = computed(() => {
  const catalogs = asyncResultOr(sourceBrowserStore.catalogs, []);
  const filteredCatalogs = filterCatalogs(catalogs, sourceBrowserStore.search);
  return filteredCatalogs;
});
const hasSearch = computed(() => sourceBrowserStore.search.length > 0);

function filterCatalogs(catalogs: Catalog[], searchString: string): Catalog[] {
  if (searchString === "") {
    return catalogs;
  }
  return catalogs.flatMap((catalog) => {
    const schemas = catalog.schemas.flatMap((s) => filterSchema(s, searchString, catalog.name));
    if (schemas.length === 0) {
      return [];
    }
    catalog = Object.assign({}, catalog, { schemas });
    return [catalog];
  });
}

function filterSchema(schema: Schema, searchString: string, catalogName: string): Schema[] {
  const tables = schema.tables.filter((t) =>
    filterTable(t.name, searchString, catalogName, schema.name)
  );
  if (tables.length === 0) {
    return [];
  }
  schema = Object.assign({}, schema, { tables });
  return [schema];
}

function listSystemTables(provider: SourceType, catalog: Catalog, schema: Schema): SystemTable[] {
  return schema.tables.map((t) => toSystemTable(provider, catalog, schema, t));
}

function toSystemTable(
  provider: SourceType,
  catalog: Catalog,
  schema: Schema,
  table: Table
): SystemTable {
  const loaded = loadedPaths.value.includes(`${catalog.name}.${schema.name}.${table.name}`);
  return {
    provider,
    catalog: catalog.name,
    schema: schema.name,
    group: `${catalog.name}:${schema.name}`,
    name: table.name,
    table,
    loaded,
  };
}

function filterTable(
  tableName: string,
  searchString: string,
  catalogName: string,
  schemaName: string
): boolean {
  const searchTokens = searchString.toLowerCase().split(/ +/);
  const names = [tableName.toLowerCase()];
  if (environment.requireBoolean("SOURCES_SEARCH_CATALOGS_AND_SCHEMAS")) {
    names.push(schemaName.toLowerCase(), catalogName.toLowerCase());
  }
  return every(searchTokens, (token) => find(names, (name) => name.includes(token)));
}

function toggleSelected(table: SystemTable) {
  if (table.loaded) {
    return;
  }
  sourceBrowserStore.toggleSelected(table);
}

function toggleGroupCollapsed(group: string) {
  sourceBrowserStore.toggleGroupCollapsed(group);
}

const isSelected = (table: SystemTable) => sourceBrowserStore.isSelected(table);

const isGroupExpanded = (group: string) => sourceBrowserStore.collapsedGroups.includes(group);

function rowCount(rowCount: number): string | undefined {
  if (!rowCount) {
    return undefined;
  }
  return `${rowCount.toLocaleString()} ${pluralize("row", rowCount)}`;
}

function refreshTables() {
  sourceBrowserStore.loadSystemTables(workspaceId.value, true);
}

function commitTables() {
  emit("select-tables", sourceBrowserStore.selection);
}

const selectionCount = computed(() => sourceBrowserStore.selection.length);

const loadedPaths = computed(() => {
  const clauses = getMapSection(appStore.mapOrEmptyMap, MapSectionKey.InEncodings);
  return Object.keys(clauses).map(toCatalogPath);
});

function toCatalogPath(id: string) {
  const fullDataset = useDataset(() => id);
  const path = fullDataset.catalogPath();
  if (!path) {
    return "";
  }
  return `${path.catalog}.${path.schema}.${path.table}`;
}

onMounted(function () {
  sourceBrowserStore.loadSystemTables(workspaceId.value);
});

const __returned__ = { props, workspaceId, emit, sourceBrowserStore, appStore, status, provider, catalogs, hasSearch, filterCatalogs, filterSchema, listSystemTables, toSystemTable, filterTable, toggleSelected, toggleGroupCollapsed, isSelected, isGroupExpanded, rowCount, refreshTables, commitTables, selectionCount, loadedPaths, toCatalogPath, Icon, MessageBar, TextButton, get AsyncStatus() { return AsyncStatus }, Disclosure, Spinner, get pluralize() { return pluralize }, get Tooltip() { return Tooltip }, get formatBytes() { return formatBytes }, Textbox }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})