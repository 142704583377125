import { defineComponent as _defineComponent } from 'vue'
import { useAppStore } from "@/editor/stores/app";
import { onMounted, ref, toRefs } from "vue";
import { useResizeListener } from "@/common/composables/useResizeListener";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResizeWrapper',
  props: {
    horizontal: { type: String, required: false },
    vertical: { type: String, required: false },
    initialWidth: { type: String, required: false },
    initialHeight: { type: String, required: false },
    minimumLength: { type: Number, required: false },
    maximumLength: { type: Number, required: false },
    identifier: { type: String, required: false }
  },
  emits: ["update:height", "update:width"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const appStore = useAppStore();

const height = ref(-1);
const resizeStartY = ref(0);
const resizeStartHeight = ref(0);

const width = ref(-1);
const resizeStartX = ref(0);
const resizeStartWidth = ref(0);

const userModified = ref(false);

const props = __props;

const { horizontal, vertical, initialWidth, initialHeight, minimumLength, maximumLength } =
  toRefs(props);

const emit = __emit; // Define emits

__expose({ setWidth });

onMounted(() => {
  if (props.identifier && appStore.resizeWrapperStates[props.identifier]) {
    width.value = appStore.resizeWrapperStates[props.identifier].width;
    height.value = appStore.resizeWrapperStates[props.identifier].height;
  }
});

function startResizeNS(event: MouseEvent) {
  // Only start resizing on left click
  if (event.button != 0) {
    return;
  }

  resizeStartY.value = event.pageY;
  resizeStartHeight.value =
    (event.target as HTMLElement).parentElement?.getBoundingClientRect().height ?? 0; // should never need to fall back on 0

  document.addEventListener("mousemove", continueResizeNS);
  document.addEventListener("mouseup", finishResizeNS);
}

function continueResizeNS(event: MouseEvent) {
  userModified.value = true;
  if (vertical?.value == "top") {
    height.value = resizeStartHeight.value - (event.pageY - resizeStartY.value);
  } else {
    height.value = resizeStartHeight.value + (event.pageY - resizeStartY.value);
  }
  if (minimumLength.value) {
    height.value = Math.max(height.value, minimumLength.value);
  }
  if (maximumLength.value) {
    height.value = Math.min(height.value, maximumLength.value);
  }

  emit("update:height", height.value); // Emit new height value

  if (props.identifier) {
    appStore.resizeWrapperStates[props.identifier] = { width: width.value, height: height.value };
  }

  event.preventDefault();
}

function finishResizeNS() {
  document.removeEventListener("mouseup", finishResizeNS);
  document.removeEventListener("mousemove", continueResizeNS);
}

function startResizeEW(event: MouseEvent) {
  // Only start resizing on left click
  if (event.button != 0) {
    return;
  }

  resizeStartX.value = event.pageX;
  resizeStartWidth.value =
    (event.target as HTMLElement).parentElement?.getBoundingClientRect().width ?? 0; // should never need to fall back on 0

  document.addEventListener("mousemove", continueResizeEW);
  document.addEventListener("mouseup", finishResizeEW);
}

function continueResizeEW(event: MouseEvent) {
  userModified.value = true;
  if (horizontal?.value == "left") {
    width.value = resizeStartWidth.value - (event.pageX - resizeStartX.value);
  } else {
    width.value = resizeStartWidth.value + (event.pageX - resizeStartX.value);
  }

  if (minimumLength.value) {
    width.value = Math.max(width.value, minimumLength.value);
  }
  if (maximumLength.value) {
    width.value = Math.min(width.value, maximumLength.value);
  }

  emit("update:width", width.value); // Emit new width value

  if (props.identifier) {
    appStore.resizeWrapperStates[props.identifier] = { width: width.value, height: height.value };
  }

  event.preventDefault();
}

function finishResizeEW() {
  document.removeEventListener("mouseup", finishResizeEW);
  document.removeEventListener("mousemove", continueResizeEW);
}

function setWidth(newWidth: number) {
  if (userModified.value) {
    return;
  }
  width.value = newWidth;
}
const emitNewSizes = () => {
  emit("update:width", width.value);
  emit("update:height", height.value); // Emit new height value
};
useResizeListener(emitNewSizes);

const __returned__ = { appStore, height, resizeStartY, resizeStartHeight, width, resizeStartX, resizeStartWidth, userModified, props, horizontal, vertical, initialWidth, initialHeight, minimumLength, maximumLength, emit, startResizeNS, continueResizeNS, finishResizeNS, startResizeEW, continueResizeEW, finishResizeEW, setWidth, emitNewSizes }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})