import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import { Tooltip } from "floating-vue";
import { IconColor, IconSize } from "../lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'IconButton',
  props: {
    name: { type: String, required: true },
    size: { type: String, required: false, default: "m" },
    color: { type: String, required: false },
    tooltip: { type: String, required: false },
    highlighted: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const __returned__ = { Icon, get Tooltip() { return Tooltip } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})