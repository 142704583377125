<template>
  <div class="flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0">
    <div
      class="item-start flex justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100"
    >
      <span> Enter your StarRocks credentials. </span>
    </div>
    <form class="flex flex-col items-start gap-10 self-stretch">
      <Textbox
        label="Workspace nickname"
        v-model="workspace.metadata.nickname"
        :required="true"
        :disabled="saving"
      />
      <Textbox
        label="Host"
        v-model="workspace.credentials.host"
        :required="true"
        :disabled="saving"
      />
      <Textbox
        label="Port"
        v-model="workspace.credentials.port"
        :required="true"
        type="number"
        :disabled="saving"
      />
      <Textbox
        label="User"
        v-model="workspace.credentials.user"
        :required="true"
        autocomplete="username"
        :disabled="saving"
      />
      <Textbox
        label="Password"
        v-model="workspace.credentials.password"
        :required="true"
        type="password"
        autocomplete="current-password"
        :disabled="saving"
      />
      <Textbox
        label="Personal access token"
        v-model="workspace.credentials.personal_access_token"
        :required="true"
        type="password"
        autocomplete="off"
        :disabled="saving"
      />
    </form>
    <div class="flex items-center justify-end gap-10 self-stretch">
      <TextButton label="Cancel" @click="cancelEditWorkspace()" :disabled="saving" />
      <TextButton
        :label="commitLabel"
        v-if="!saving"
        :disabled="!saveEnabled || saving"
        @click="saveWorkspace()"
      />
      <SpinnerButton v-else :label="commitLabel" />
    </div>
  </div>

  <Dialog
    v-if="errorDialogMessage"
    :cancel-label="undefined"
    title="Connection Error"
    icon="error"
    @succeeded="errorDialogMessage = undefined"
  >
    {{ errorDialogMessage }}
  </Dialog>
</template>

<script lang="ts" setup>
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { FullWorkspace, ProviderType, useWorkspaceStore } from "@/common/stores/workspaceStore";
import { computed, onMounted, ref } from "vue";
import SpinnerButton from "../SpinnerButton.vue";
import Dialog from "@/common/components/Dialog.vue";
import { BackendError } from "@/common/http/http";
import { isObject, isString } from "lodash";

const workspaceStore = useWorkspaceStore();
const workspace = ref<StarRocksWorkspace>(newWorkspace());

const props = defineProps<{ currentWorkspace?: FullWorkspace; mode: "edit" | "create" }>();
const emits = defineEmits(["saveWorkspace", "cancel"]);

const saving = ref(false);
const errorDialogMessage = ref<string | undefined>();

const saveEnabled = computed(() => {
  const metadata = workspace.value.metadata;
  const creds = workspace.value.credentials;
  return (
    metadata.nickname && creds.host && creds.user && creds.password && creds.personal_access_token
  );
});

const commitLabel = computed(() => {
  if (saving.value) {
    return props.mode === "edit" ? "Updating…" : "Connecting…";
  }
  return props.mode === "edit" ? "Update" : "Connect";
});

function cancelEditWorkspace() {
  emits("cancel");
}

async function saveWorkspace() {
  saving.value = true;
  try {
    workspace.value.metadata.id = "starrocks-" + workspace.value.credentials.host;
    await workspaceStore.saveWorkspace(workspace.value);
    emits("saveWorkspace");
  } catch (error: unknown) {
    if (error instanceof BackendError) {
      const response = error.failure?.response;
      if (isObject(response) && "cause" in response && isString(response.cause)) {
        errorDialogMessage.value = response.cause;
      }
      return;
    }
  } finally {
    saving.value = false;
  }
}

interface StarRocksWorkspace extends FullWorkspace {
  credentials: {
    host: string;
    port: number;
    user: string;
    password: string;
    personal_access_token: string;
  };
}

function newWorkspace(): StarRocksWorkspace {
  return {
    metadata: {
      nickname: "",
      id: "",
      connected: true,
      current_warehouse_id: undefined,
      provider: ProviderType.StarRocks,
    },
    credentials: {
      host: "",
      port: 9030,
      user: "",
      password: "",
      personal_access_token: "",
    },
  };
}

onMounted(() => {
  if (props.currentWorkspace) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    workspace.value = props.currentWorkspace as any;
  }
});
</script>
