import { defineComponent as _defineComponent } from 'vue'
import StatusPill from "@/common/components/StatusPill.vue";
import { Tooltip } from "floating-vue";
import { computed, toRefs } from "vue";
import { Warehouse } from "../stores/workspaceStore";
import { capitalize, upperCase } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarehouseStatus',
  props: {
    warehouse: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const { warehouse } = toRefs(props);

const warehouseInfo = computed(() => getWarehouseInfo(warehouse.value));

const warehouseStatusColor = computed(() => {
  switch (warehouse.value.state) {
    case "RUNNING":
      return "green";
    case "STARTING":
      return "yellow";
    case "STOPPED":
    case "STOPPING":
      return "red";
    case "DELETED":
    case "DELETING":
      return "black";
  }
  return "black";
});

function getWarehouseInfo(warehouse: Warehouse) {
  return {
    status: warehouse.state,
    name: warehouse.name,
    size: warehouse.cluster_size,
  };
}

const __returned__ = { props, warehouse, warehouseInfo, warehouseStatusColor, getWarehouseInfo, StatusPill, get Tooltip() { return Tooltip }, get capitalize() { return capitalize }, get upperCase() { return upperCase } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})