<template>
  <Start title="Welcome">
    <div class="flex flex-col gap-10">
      <div>
        <Textbox
          label="Email"
          v-model="email"
          autocomplete="email"
          id="email-field"
          :disabled="connecting"
          :error-text="emailError"
          @focus="emailError = ''"
          @blur="blurEmail"
        />
        <Textbox
          label="Password"
          v-model="password"
          type="password"
          autocomplete="current-password"
          id="password-field"
          :disabled="connecting"
          @focus="passwordError = ''"
          @blur="blurPassword"
          :error-text="passwordError"
        />
      </div>
      <div v-if="showResetPasswordLink" class="text-lg text-white">
        <RouterLink to="/reset-password" class="text-orange">Forgot your password?</RouterLink>
      </div>
      <div v-if="showSignupLink" class="text-lg text-white">
        Don't have a claritype account?
        <RouterLink to="/signup" class="text-orange">Let's get you set up.</RouterLink>
      </div>
    </div>
    <template #footer>
      <TextButton
        v-if="!connecting"
        label="Log in"
        :disabled="!formValidated"
        @click="tryLogin()"
      />
      <SpinnerButton v-else label="Logging in" />
    </template>
  </Start>
</template>

<script lang="ts" setup>
import Textbox from "@/common/components/Textbox.vue";
import Start from "@/home/views/Start.vue";
import { computed, ref } from "vue";
import { isEmpty } from "lodash";
import { useBackendAuth } from "@/common/auth/backendAuth";
import { useAuthProvider } from "@/common/auth/authStore";
import { BackendAuthProvider } from "@/common/auth/backendAuthProvider";
import { environment } from "@/common/environments/environmentLoader";
import TextButton from "@/common/components/TextButton.vue";
import SpinnerButton from "@/common/components/SpinnerButton.vue";

const email = ref("");
const password = ref("");

const backendAuth = useBackendAuth();
const backendProvider = useAuthProvider();
const connecting = ref(false);

async function tryLogin() {
  connecting.value = true;
  try {
    if (!(backendProvider instanceof BackendAuthProvider)) {
      throw new Error("Login page required backend auth provider");
    }
    if (isEmpty(email.value) || isEmpty(password.value)) {
      return;
    }
    // Send login request to backend
    const response = await backendAuth.login(email.value, password.value);
    if (response.success) {
      backendProvider.finishLogin({
        accessToken: response.response.auth.access_token,
        refreshToken: response.response.auth.refresh_token,
        user: response.response.user,
      });
    } else {
      connecting.value = false;
      passwordError.value = response.error;
    }
  } catch (error: unknown) {
    connecting.value = false;
  }
}

const showSignupLink = computed(() => environment.requireBoolean("AUTH_BACKEND_SIGNUP"));
const showResetPasswordLink = computed(() =>
  environment.requireBoolean("AUTH_BACKEND_RESET_PASSWORD")
);

const emailError = ref<string | undefined>(undefined);
function validateEmail() {
  if (email.value.trim().length <= 3 || !email.value.includes("@")) {
    return false;
  }
  return true;
}

function blurEmail() {
  if (validateEmail()) {
    emailError.value = undefined;
  } else {
    emailError.value = "Invalid email address";
  }
}

const passwordError = ref<string | undefined>(undefined);
function validatePassword() {
  if (password.value.length === 0) {
    return false;
  }
  return true;
}

function blurPassword() {
  if (validatePassword()) {
    passwordError.value = undefined;
  } else {
    passwordError.value = "Password required";
  }
}

const formValidated = computed(() => {
  if (!validateEmail()) {
    return false;
  }
  if (!validatePassword()) {
    return false;
  }
  return true;
});
</script>
