import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-10 max-w-[275px] text-black dark:text-white" }
const _hoisted_2 = { class: "my-5" }
const _hoisted_3 = { class: "flex flex-row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString($props.label) + ": ", 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["TextButton"], {
        label: "Cancel",
        secondary: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('cancel'))),
        size: "l",
        class: "mr-5"
      }),
      _createVNode($setup["TextButton"], {
        label: "Set Filter",
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('commit'))),
        disabled: !$props.valid
      }, null, 8 /* PROPS */, ["disabled"])
    ])
  ]))
}