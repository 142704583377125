<template>
  <div class="absolute left-0 right-0 top-0 z-0 h-[24px] bg-gray-200 dark:bg-gray-800"></div>
  <div class="absolute inset-0 z-0 overflow-auto" data-test="table">
    <table class="z-10 border-collapse">
      <thead>
        <tr>
          <th
            v-for="(colDetails, index) in columns"
            :key="index"
            class="sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline"
          >
            <div
              class="flex h-[25px] items-center justify-between whitespace-nowrap border-b border-r border-white px-10 dark:border-gray-900"
              :class="{ 'justify-end text-right': colDetails.isQuantitative }"
            >
              <div class="mx-auto">{{ colDetails.label }}</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <SubtableComponent :subtable="table" :columns="columns" :depth="0" />
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import {
  generateValue,
  generateValues,
  generatorName,
  generatorOutputType,
  TableVisualization,
  TableVisualizationGroup,
  ValueGenerator,
} from "@/reader/lib/visualization";
import { computed, toRefs } from "vue";
import { last, tail } from "lodash";
import { stringifyValueOrCompositeValue } from "@/common/lib/graph";
import { GraphValue, QUANTITATIVE_VALUE_TYPES, toValue } from "@/common/lib/value";
import SubtableComponent from "./table/Subtable.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";
import { UseQueryResult } from "@/reader/composables/useQuery";

const props = defineProps<{
  visualization: TableVisualization;
  results: UseQueryResult[];
  width: number;
  height: number;
}>();

defineEmits<{ select: [alias: string, value: GraphValue | null] }>();

const { visualization, results } = toRefs(props);

export interface Subtable {
  rows?: (ValueWithFormattedValue | null)[][];
  groups?: TableGroup[];
}

export interface Column {
  generator: ValueGenerator;
  label: string;
  isQuantitative: boolean;
}

interface TableGroup {
  id: string;
  name: GraphValue;
  contents: Subtable;
}

const columns = computed(function (): Column[] {
  const query = visualization.value.query;
  return visualization.value.config.columns.map(function (colspec) {
    const valueType = generatorOutputType(colspec.value, query);
    return {
      generator: colspec.value,
      label: colspec.label ?? generatorName(colspec.value, query),
      isQuantitative: valueType ? QUANTITATIVE_VALUE_TYPES.includes(valueType) : false,
    };
  });
});

function buildRows(results: UseQueryResult[]) {
  const query = visualization.value.query;
  return results.map(function (row) {
    return columns.value.map((col) => generateValue(col.generator, row, query));
  });
}

function buildGroups(
  results: UseQueryResult[],
  groupspecs: TableVisualizationGroup[]
): TableGroup[] {
  const query = visualization.value.query;
  const groupspec = groupspecs[0];
  const rowGroups: UseQueryResult[][] = [];
  let currentId: string | null | undefined = undefined;
  for (const row of results) {
    const category = row.valuesByAlias[groupspec.category];
    const id = category ? stringifyValueOrCompositeValue(category[0]) : null;
    if (id !== currentId) {
      currentId = id;
      rowGroups.push([]);
    }
    last(rowGroups)!.push(row);
  }
  return rowGroups.map(function (rowGroup) {
    const firstRow = rowGroup[0];
    const values = generateValues({ ...groupspec }, firstRow, query);
    return {
      id: values.category ? stringifyValueOrCompositeValue(values.category.originalValue) : "",
      name: (values.category_name ?? values.category)?.formattedValue ?? toValue("-"),
      contents: buildSubtable(rowGroup, tail(groupspecs)),
    };
  });
}

function buildSubtable(results: UseQueryResult[], groupspecs: TableVisualizationGroup[]): Subtable {
  if (groupspecs.length == 0) {
    return { rows: buildRows(results) };
  } else {
    return { groups: buildGroups(results, groupspecs) };
  }
}

const table = computed(() => buildSubtable(results.value, visualization.value.config.groups ?? []));
</script>
