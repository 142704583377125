import { defineComponent as _defineComponent } from 'vue'
import Spinner from "@/common/components/Spinner.vue";
import { computed, inject, Ref, toRefs } from "vue";
import { DarkMode } from "@/common/lib/keys";


export default /*@__PURE__*/_defineComponent({
  __name: 'SpinnerButton',
  props: {
    label: { type: String, required: true },
    icon: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

/*
 * This isn't actually a button, but a status block that is meant to replace a button.
 */
const props = __props;

const { icon, label } = toRefs(props);
const darkMode = inject(DarkMode) as Ref<boolean>;

const textColor = computed(function () {
  if (icon.value) {
    return darkMode.value ? "gray-200" : "gray-700";
  }
  return "orange";
});

const __returned__ = { props, icon, label, darkMode, textColor, Spinner }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})