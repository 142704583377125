import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import SourceCatalog from "@/common/components/SourceCatalog.vue";
import { SourceSelection, useSourceBrowserStore } from "@/common/stores/sourceBrowser";
import { computed, onMounted, ref, watch } from "vue";
import { ProviderType, useWorkspaceStore, WorkspacesState } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import IconButton from "./IconButtonV2.vue";
import { environment } from "@/common/environments/environmentLoader";
import ResizeWrapper from "@/editor/components/ResizeWrapper.vue";
import { AsyncStatus } from "../lib/async";
import Dialog from "@/common/components/Dialog.vue";
import { useRoute } from "vue-router";
import { useNavigation } from "@/common/composables/useNavigation";
import MessageBar from "./MessageBar.vue";
import WorkspaceChooser from "./WorkspaceChooser.vue";
import WarehouseChooser from "./WarehouseChooser.vue";
import WarehouseStatus from "./WarehouseStatus.vue";
import Spinner from "./Spinner.vue";
import TextButton from "./TextButton.vue";
import { useDynamicTimer } from "../composables/useDynamicTimer";

const HEADER_HEIGHT = 32;
const CONNECTIONS_URL = "/home/connections";


export default /*@__PURE__*/_defineComponent({
  __name: 'SourceBrowser',
  props: {
    startExpanded: { type: Boolean, required: false, default: true },
    parentHeight: { type: Number, required: false },
    allowWorkspaceSwitching: { type: Boolean, required: false, default: false }
  },
  emits: ["select-tables", "expanded"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const height = ref(props.parentHeight ?? 0);
const showServerDetails = ref(false);
const deletedWarehouseWarning = ref(false);
const sourceBrowserExpanded = ref(props.startExpanded);
const maxHeight = ref(props.parentHeight);

const route = useRoute();
const sourceBrowserStore = useSourceBrowserStore();
const workspaceStore = useWorkspaceStore();
const workspaceStoreRefs = storeToRefs(workspaceStore);
const {
  currentWarehouse,
  warehouses,
  workspaces,
  currentWorkspaceId,
  currentWorkspace,
  workspacesState,
} = workspaceStoreRefs;

__expose({ sourceBrowserExpanded });

function handleHeightUpdate(newHeight: number) {
  height.value = newHeight - HEADER_HEIGHT;
}

function toggleExpanded() {
  sourceBrowserExpanded.value = !sourceBrowserExpanded.value;
  emit("expanded", sourceBrowserExpanded.value);
}

function toggleServerDetails() {
  showServerDetails.value = !showServerDetails.value;
}

const workspace = computed(() => {
  return workspaceStoreRefs.currentWorkspaceSync?.value;
});

const workspaceIcon = computed(() => providerIcon(workspace.value?.provider));

function reconnect() {
  useNavigation().goToUrl(CONNECTIONS_URL);
}

function providerIcon(provider?: ProviderType): string | undefined {
  const key = "PROVIDER_LOGO_" + provider?.toUpperCase();
  return environment.get(key) ?? environment.require("PROVIDER_LOGO_DEFAULT");
}

function refreshTables() {
  const workspaceId = currentWorkspaceId?.value;
  if (!workspaceId) {
    return;
  }
  sourceBrowserStore.loadSystemTables(workspaceId, true);
}

function selectTables(selection: SourceSelection[]) {
  emit("select-tables", selection);
}

const timer = useDynamicTimer(environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS"));

watch(
  () => props.parentHeight,
  (newVal) => {
    maxHeight.value = newVal;
    height.value = newVal ?? 0;
  }
);

watch(
  () => workspace,
  () => {
    refreshTables;
  }
);

watch(
  () => currentWarehouse?.value?.state,
  (newWarehouseState, oldWarehouseState) => {
    if (
      newWarehouseState === "DELETED" &&
      newWarehouseState !== oldWarehouseState &&
      route.path !== "/connections"
    ) {
      deletedWarehouseWarning.value = true;
    }
  }
);

onMounted(async () => {
  await workspaceStore.initializeWorkspaceState();
  timer.start(async () => {
    await workspaceStore.refreshCurrentWarehouse();
    if (workspaceStore.currentWarehouse?.state === "RUNNING") {
      return environment.requireNumber("WAREHOUSE_PERIODIC_CHECK_WAIT_MS");
    }
    return environment.requireNumber("WAREHOUSE_LOADING_CHECK_WAIT_MS");
  });

  maxHeight.value = props.parentHeight ?? 0;
  height.value = maxHeight.value * 0.85;
});

const __returned__ = { HEADER_HEIGHT, CONNECTIONS_URL, props, emit, height, showServerDetails, deletedWarehouseWarning, sourceBrowserExpanded, maxHeight, route, sourceBrowserStore, workspaceStore, workspaceStoreRefs, currentWarehouse, warehouses, workspaces, currentWorkspaceId, currentWorkspace, workspacesState, handleHeightUpdate, toggleExpanded, toggleServerDetails, workspace, workspaceIcon, reconnect, providerIcon, refreshTables, selectTables, timer, Icon, SourceCatalog, get WorkspacesState() { return WorkspacesState }, IconButton, ResizeWrapper, get AsyncStatus() { return AsyncStatus }, Dialog, get useNavigation() { return useNavigation }, MessageBar, WorkspaceChooser, WarehouseChooser, WarehouseStatus, Spinner, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})