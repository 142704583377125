import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import { IconColorSpec } from "@/common/lib/icons";


export default /*@__PURE__*/_defineComponent({
  __name: 'Start',
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: false, default: "claritype" },
    iconColor: { type: [Object, String], required: false, default: "orange" }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const __returned__ = { Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})