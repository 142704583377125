import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import Spinner from "@/common/components/Spinner.vue";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { AsyncStatus, asyncFailureMessage, asyncNotStarted } from "@/common/lib/async";
import { useExploreStore } from "@/reader/stores/explore";
import { trim } from "lodash";
import { computed, ref } from "vue";
import { KeyCommand } from "@/common/lib/modifierKeys";
import { environment } from "@/common/environments/environmentLoader";
import { Tooltip } from "floating-vue";
import TextButton from "@/common/components/TextButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AskBar',
  setup(__props, { expose: __expose }) {
  __expose();

const inputText = ref("");
const askBar = ref<HTMLTextAreaElement | undefined>(undefined);
const askBarKeyCommand = KeyCommand.readKeyCommand(environment.require("KEY_COMMAND_ASK_BAR"));
useKeyHandler().registerHandler(askBarKeyCommand, () => askBar.value?.select(), true, false);

const exploreStore = useExploreStore();

const sanitizedInput = computed(() => trim(inputText.value));
const hasInput = computed(() => sanitizedInput.value.length > 0);
const inProgress = computed(() => exploreStore.askResponse.status === AsyncStatus.InProgress);
const succeeded = computed(() => exploreStore.askResponse.status === AsyncStatus.Succeeded);
const errorMessage = computed(() => asyncFailureMessage(exploreStore.askResponse));

function submitQuestion() {
  exploreStore.askQuestion(sanitizedInput.value);
}

function prepareForNewQuestion() {
  if (exploreStore.askResponse.status != AsyncStatus.NotStarted) {
    askBar.value?.select();
    exploreStore.askResponse = asyncNotStarted();
  }
}

const __returned__ = { inputText, askBar, askBarKeyCommand, exploreStore, sanitizedInput, hasInput, inProgress, succeeded, errorMessage, submitQuestion, prepareForNewQuestion, Icon, Spinner, get Tooltip() { return Tooltip }, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})