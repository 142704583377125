<template>
  <div class="h-full bg-gray-200 dark:bg-gray-800">
    <div class="user-details">
      <div class="flex bg-gray-100 dark:bg-gray-900">
        <TabSelect :tabs="availableTabs" v-model="selectedTab" />
      </div>
      <div
        class="m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200"
        v-if="selectedTab === 'user-settings'"
      >
        <div class="flex flex-col items-start gap-15" v-if="user">
          <div class="text-xl">Name</div>
          <div>{{ user.name }}</div>
          <div><TextButton label="Update name" @click="editUserName" /></div>
        </div>
        <div class="flex flex-col items-start gap-15" v-if="user">
          <div class="text-xl">Email</div>
          <div class="flex flex-col gap-5">
            <span>{{ user.email }}</span
            ><span v-if="user.email_verified" class="text-500 italic">verified</span>
          </div>
          <TextButton label="Resync" @click="resync" />
        </div>
        <div class="flex flex-col items-start gap-15" v-if="user">
          <div class="text-xl">Password</div>
          <div>
            <TextButton
              label="Change password"
              @click="editingChangePassword = { newPassword: '', confirmNewPassword: '' }"
            />
          </div>
        </div>
        <div class="flex flex-col items-start gap-15">
          <div class="text-xl">Session</div>
          <TextButton label="Logout" @click="logout" />
        </div>
      </div>
    </div>
    <div
      class="m-30 flex flex-col gap-30 text-gray-800 dark:text-gray-200"
      v-if="selectedTab === 'connection'"
    >
      <ConnectionSettings />
    </div>
    <div class="m-30 text-gray-800 dark:text-gray-200" v-if="selectedTab === 'preferences'">
      <div class="flex flex-col items-start gap-15" v-if="user">
        <div class="text-xl">Home Page</div>
        <div v-if="userSettings?.default_homepage">{{ userSettings?.default_homepage }}</div>
        <div v-else class="italic">Home page not set.</div>
        <TextButton
          label="Update
        home page"
          @click="editHomePage"
        />
      </div>
    </div>
    <div class="m-30 text-gray-800 dark:text-gray-200" v-if="selectedTab === 'admin'">
      <AdminPage />
    </div>
  </div>

  <Dialog
    v-if="editingUserName !== undefined"
    title="Update name"
    success-label="Update"
    @succeeded="finishEditUserName"
    @cancelled="editingUserName = undefined"
  >
    <Textbox label="Name" v-model="editingUserName" :autoselect="true" />
  </Dialog>

  <Dialog
    v-if="editingChangePassword !== undefined"
    title="Change password"
    success-label="Update"
    @succeeded="finishChangePassword"
    @cancelled="editingChangePassword = undefined"
  >
    <Textbox
      label="New password"
      v-model="editingChangePassword.newPassword"
      :autoselect="true"
      autocomplete="new-password"
    />
    <MessageBar mode="error" v-if="editingChangePassword.error">
      <div>{{ editingChangePassword.error }}</div>
    </MessageBar>
  </Dialog>

  <Dialog
    v-if="editingHomePage !== undefined"
    title="Update home page"
    success-label="Update"
    @succeeded="finishEditHomePage"
    @cancelled="editingHomePage = undefined"
  >
    <Textbox label="Home page path" v-model="editingHomePage" :autoselect="true" />
    Enter a custom URL path to determine where you are taken upon login (e.g., /reader).
  </Dialog>
</template>

<script lang="ts" setup>
import { useAuthProvider } from "@/common/auth/authStore";
import Dialog from "@/common/components/Dialog.vue";
import TabSelect from "@/common/components/TabSelect.vue";
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { environment } from "@/common/environments/environmentLoader";
import { useSettingsStore, Routes } from "@/common/stores/settingsStore";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import ConnectionSettings from "@/common/components/settings/ConnectionSettings.vue";
import { useRouter } from "vue-router";
import MessageBar from "./MessageBar.vue";
import AdminPage from "@/common/components/admin/AdminPage.vue";

const auth = useAuthProvider();
const editingUserName = ref<string | undefined>();
const editingHomePage = ref<string | undefined>();
const editingChangePassword = ref<ChangePassword | undefined>();

const userStore = useUserStore();
const router = useRouter();
const { user, userSettings } = storeToRefs(userStore);

const { availableTabs, selectedTab } = storeToRefs(useSettingsStore());

async function resync() {
  await userStore.resyncUser();
}

function logout() {
  auth.logout(window.location.origin + environment.require("AUTH0_LOGOUT_PATH"));
}

function editUserName() {
  editingUserName.value = user?.value?.name;
}

async function finishEditUserName() {
  if (!editingUserName.value) {
    return;
  }
  const success = await userStore.updateUserName(editingUserName.value);
  if (!success) {
    return;
  }
  editingUserName.value = undefined;
}

interface ChangePassword {
  newPassword: string;
  confirmNewPassword: string;
  error?: string;
}

async function finishChangePassword() {
  if (!editingChangePassword.value) {
    return;
  }
  editingChangePassword.value.error = undefined;
  const result = await userStore.updateUserPassword(editingChangePassword.value.newPassword);
  if (result.error) {
    editingChangePassword.value.error = result.error;
    return;
  }
  editingChangePassword.value = undefined;
}

function editHomePage() {
  editingHomePage.value = userSettings?.value?.default_homepage ?? "";
}

async function finishEditHomePage() {
  userSettings.value ??= {};
  userSettings.value.default_homepage =
    editingHomePage.value?.trim() === "" ? undefined : editingHomePage.value;
  const success = await userStore.updateUserSettings(userSettings.value);
  if (!success) {
    return;
  }
  editingHomePage.value = undefined;
}

watch(selectedTab, (newValue) => {
  switch (newValue) {
    case "connection":
      router.replace({ name: Routes.Connections });
      break;
    case "preferences":
      router.replace({ name: Routes.Preferences });
      break;
    case "admin":
      router.replace({ name: Routes.Admin });
      break;
    default:
      router.replace({ name: Routes.UserSettings });
      break;
  }
});
onMounted(() => {
  userStore.reloadUser();
});
</script>
