import { defineComponent as _defineComponent } from 'vue'
import useKnowledge from "@/common/composables/useKnowledge";
import { computed, toRefs } from "vue";
import { PropertyKnowledgeRef } from "@/common/lib/knowledge";
import GraphValueComponent from "@/common/components/GraphValue.vue";
import { ValueWithFormattedValue } from "@/common/lib/format";


export default /*@__PURE__*/_defineComponent({
  __name: 'ValueGroup',
  props: {
    values: { type: Array, required: true },
    propertyType: { type: null, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { values, propertyType } = toRefs(props);

const { typeLabel } = useKnowledge();

const propTypeLabel = computed(() => typeLabel(propertyType.value));

const __returned__ = { props, values, propertyType, typeLabel, propTypeLabel, GraphValueComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})