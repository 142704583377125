import { defineComponent as _defineComponent } from 'vue'
import { Warehouse } from "@/common/stores/workspaceStore";
import { Async, asyncNotStarted, AsyncStatus } from "@/common/lib/async";
import { useAdminStore } from "@/common/stores/adminStore";
import { onMounted, ref, toRefs } from "vue";
import { isNil } from "lodash";
import Select from "../Select.vue";
import Label from "../Label.vue";
import Spinner from "../Spinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminWarehouseChooser',
  props: {
    userId: { type: String, required: true },
    workspaceId: { type: String, required: true },
    currentWarehouseId: { type: String, required: false }
  },
  emits: ["warehouseUpdated"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { userId, workspaceId } = toRefs(props);
const emits = __emit;
const currentWarehouseId = ref<string | undefined>();

const adminStore = useAdminStore();

const warehouses = ref<Async<Warehouse[]>>(asyncNotStarted());

async function updateWarehouse() {
  if (isNil(currentWarehouseId.value)) {
    return;
  }
  await adminStore.setCurrentWarehouse({
    userId: userId.value,
    workspaceId: workspaceId.value,
    warehouseId: currentWarehouseId.value,
  });
  emits("warehouseUpdated");
}

onMounted(async () => {
  currentWarehouseId.value = props.currentWarehouseId;
  warehouses.value = await adminStore.listWarehouses(workspaceId.value);
});

const __returned__ = { props, userId, workspaceId, emits, currentWarehouseId, adminStore, warehouses, updateWarehouse, get AsyncStatus() { return AsyncStatus }, Select, Label, Spinner }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})