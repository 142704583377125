import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "absolute inset-0 z-0 overflow-auto",
  "data-test": "table"
}
const _hoisted_2 = { class: "z-10 border-collapse" }
const _hoisted_3 = { class: "mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute left-0 right-0 top-0 z-0 h-[24px] bg-gray-200 dark:bg-gray-800" }, null, -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (colDetails, index) => {
              return (_openBlock(), _createElementBlock("th", {
                key: index,
                class: "sticky top-0 z-10 bg-gray-200 p-0 text-center align-baseline"
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex h-[25px] items-center justify-between whitespace-nowrap border-b border-r border-white px-10 dark:border-gray-900", { 'justify-end text-right': colDetails.isQuantitative }])
                }, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(colDetails.label), 1 /* TEXT */)
                ], 2 /* CLASS */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("tbody", null, [
          _createVNode($setup["SubtableComponent"], {
            subtable: $setup.table,
            columns: $setup.columns,
            depth: 0
          }, null, 8 /* PROPS */, ["subtable", "columns"])
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}