import { defineComponent as _defineComponent } from 'vue'
import TabSelect from "@/common/components/TabSelect.vue";
import { computed } from "vue";
import { Tool, useExploreStore } from "@/reader/stores/explore";
import { ConceptKnowledgeRef } from "@/common/lib/knowledge";
import ConceptPage from "../concept/ConceptPage.vue";
import { QueryFilter } from "@/common/lib/query";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExploreTools',
  setup(__props, { expose: __expose }) {
  __expose();

const exploreStore = useExploreStore();

const tabs = computed(() => [
  { label: "Insights", key: Tool.Insights },
  { label: "Concept", key: Tool.Concept },
]);

function pivot(ctype: ConceptKnowledgeRef, filters: QueryFilter[]) {
  exploreStore.pivot(ctype, filters[0]); // This will fail on multi-key merges
}

const __returned__ = { exploreStore, tabs, pivot, TabSelect, get Tool() { return Tool }, ConceptPage }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})