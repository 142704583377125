import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("label", { class: "mb-5 block" }, "Largest value of:", -1 /* HOISTED */)),
    _createVNode($setup["DerivedPropertyDef"], {
      "parent-op": $setup.OP,
      modelValue: $setup.term,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.term) = $event))
    }, null, 8 /* PROPS */, ["parent-op", "modelValue"])
  ]))
}