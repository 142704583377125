import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from "vue";
import Icon from "./Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuListItem',
  props: {
    label: { type: String, required: true },
    selected: { type: Boolean, required: true },
    icon: { type: String, required: true }
  },
  emits: ["selected"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

const hovered = ref(false);

const iconColor = computed(() => {
  if (props.selected) {
    return "black";
  }
  if (hovered.value) {
    return "orange";
  }
  return "white";
});

const __returned__ = { props, emit, hovered, iconColor, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})