import { defineComponent as _defineComponent } from 'vue'
import Dialog from "@/common/components/Dialog.vue";
import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import Textbox from "@/common/components/Textbox.vue";
import { useNavigation } from "@/common/composables/useNavigation";
import { environment } from "@/common/environments/environmentLoader";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import {
  moduleHasType,
  ModuleMetadata,
  ModuleType,
  useUserModuleStore,
} from "@/common/stores/userModuleStore";
import { Dropdown, Tooltip } from "floating-vue";
import { DateTime } from "luxon";
import { computed, onMounted, ref, toRefs } from "vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";

export default /*@__PURE__*/_defineComponent({
  __name: 'Module',
  props: {
    module: { type: Object, required: true }
  },
  emits: ["openModule", "deleteModule"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const {
  duplicateModule,
  renameModule,
  publishModule,
  publishLocalModule,
  publishReaderView,
  unshareReaderView,
} = useUserModuleStore();
const props = __props;
const { module } = toRefs(props);
const emit = __emit;

const renamingModule = ref<string | undefined>();
const duplicateProject = ref<string | undefined>();
const deletingModule = ref(false);
const assigningTag = ref(false);
const tagName = ref("");

const menuItems = computed(() => {
  const items = [];
  if (module.value.moduleType === ModuleType.User) {
    items.push({
      key: "rename",
      label: "Rename",
      icon: "md:text_select_start",
      action: () => (renamingModule.value = module.value.name),
    });
    if (module.value.databricks_host) {
      items.push({
        key: "duplicate",
        label: "Duplicate",
        icon: "md:file_copy",
        action: () => (duplicateProject.value = module.value.name),
      });
    }
  }
  if (module.value.moduleType === ModuleType.User && module.value.databricks_host) {
    if (environment.requireBoolean("ENABLE_PUBLISHING")) {
      items.push({
        key: "publish",
        label: "Publish module",
        icon: "md:publish",
        action: () => publishModule(module.value.id),
      });
    }
    if (environment.requireBoolean("READER_VIEW_PUBLISHING_ENABLED")) {
      const readerPublished = module.value.published_reader_view;
      items.push({
        key: "publish_reader",
        label: readerPublished ? "Unshare Reader view" : "Share Reader view",
        icon: "md:bar_chart",
        action: () => toggleReaderView(readerPublished),
      });
      items.push({
        key: "tag_project",
        label: "Tag project",
        icon: "md:sell",
        action: () => (assigningTag.value = true),
      });
    }
    if (environment.requireBoolean("LOCAL_EXPORTS")) {
      items.push({
        key: "export-local",
        label: "Export locally",
        icon: "download",
        action: () => publishLocalModule(module.value.id),
      });
    }
    if (environment.requireBoolean("VIEW_IN_EXPLORER")) {
      items.push({
        key: "view-in-explorer",
        label: "View in Reader",
        icon: "explore-outline",
        action: () => useNavigation().openUrl(getReaderUrl()),
      });
    }
  }
  if (isDeletable) {
    items.push({
      key: "delete",
      label: "Delete",
      icon: "md:delete",
      action: () => (deletingModule.value = true),
    });
  }

  if (isUnshareable) {
    items.push({
      key: "unshare_reader",
      label: "Unshare Reader view",
      icon: "md:bar_chart",
      action: () => unshareReaderView(module.value.id),
    });
  }
  return items;
});

const updatedDate = computed(() => formatDate(module.value.updated));

function formatDate(date: DateTime): string | null {
  return date.toRelative();
}

const isDeletable =
  module.value.moduleType === ModuleType.User ||
  (moduleHasType(module.value, ModuleType.Published) && module.value.owned);

const showPublishedBy = moduleHasType(
  module.value,
  ModuleType.Published,
  ModuleType.Local,
  ModuleType.Reader
);

const isUnshareable = module.value.moduleType === ModuleType.Reader && user?.value?.global_admin;

async function finishRenameModule() {
  if (!renamingModule.value) {
    return;
  }
  await renameModule(module.value.id, module.value.name, renamingModule.value);
  renamingModule.value = undefined;
}

async function finishDuplicatingProject() {
  if (!duplicateProject.value) {
    return;
  }
  await duplicateModule(module.value.id, duplicateProject.value);
}

function toggleSelected() {
  module.value.selected = !module.value.selected;
}

async function toggleReaderView(readerPublished: boolean | null) {
  await publishReaderView(module.value.id, readerPublished ?? false);
  // TODO: add to return value
  module.value.published_reader_view = !readerPublished;
}

async function setModuleTag() {
  if (tagName.value.trim().length === 0) {
    return;
  }
  await useUserModuleStore().tagModule(tagName.value, module.value.id);
  module.value.tag = tagName.value;
  tagName.value = "";
  assigningTag.value = false;
}

function getReaderUrl() {
  const moduleId = module.value.tag ?? module.value.id;
  return `/reader/modules/${moduleId}/explore`;
}

onMounted(() => useWorkspaceStore().initializeWorkspaceState());

const __returned__ = { userStore, user, duplicateModule, renameModule, publishModule, publishLocalModule, publishReaderView, unshareReaderView, props, module, emit, renamingModule, duplicateProject, deletingModule, assigningTag, tagName, menuItems, updatedDate, formatDate, isDeletable, showPublishedBy, isUnshareable, finishRenameModule, finishDuplicatingProject, toggleSelected, toggleReaderView, setModuleTag, getReaderUrl, Dialog, IconButton, Menu, Textbox, get Dropdown() { return Dropdown }, get Tooltip() { return Tooltip } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})