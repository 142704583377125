import useKnowledge from "@/common/composables/useKnowledge";
import { VueSelectInstance } from "vue-select";
import { KnowledgeItem, knowledgeItemId } from "./knowledge";

function longestCommonPrefix(s1: string, s2: string): number {
  let prefixLength = 0;

  const minLength = Math.min(s1.length, s2.length);
  for (let i = 0; i < minLength; i++) {
    if (s1[i] == s2[i]) {
      prefixLength += 1;
    } else {
      break;
    }
  }

  return prefixLength;
}

function longestCommonPrefix_parts(s1: string, s2: string): number[] {
  let s1Parts = s1.split(" ");
  let s2Parts = s2.split(" ");

  // TODO: is this rigamarole actually necessary?
  if (s1Parts.length < s2Parts.length) {
    const tmpParts = s1Parts;
    s1Parts = s2Parts;
    s2Parts = tmpParts;

    const tmpStr = s1;
    s1 = s2;
    s2 = tmpStr;
  }

  const prefixLengths = [];

  for (let i = 0; i <= s1Parts.length - s2Parts.length; i++) {
    const substring = s1Parts.slice(i).join(" ");
    const substringPrefix = longestCommonPrefix(substring, s2);
    prefixLengths.push(substringPrefix);
  }

  return prefixLengths;
}

// VueSelectOption is not exported, so define an option type here
export type DropdownOption = string | object;

/**
 * Sort items within a search box.
 * @param search the current search string (can be empty string)
 * @param strTransform function from option to a string (usually a display string), for sorting
 * @param knowledgeTransform function to extract knowledge ID from option;
 *                           optional, if not present, assumes we are not comparing knowledge
 * @param adHocLast If sorting knowledge, should ad hoc values be last? Defaults to true
 * @returns
 */
export function sortHeuristic<T>(
  search: string,
  strTransform: (x: T) => string,
  knowledgeTransform?: (x: T) => string,
  adHocLast = true
) {
  const { isLocalType, isDeprecated } = useKnowledge();

  // s1, s2 are potential dropdown values
  return function (lhs: T, rhs: T): number {
    // Usually need to go from some kind of key to the human-readable form that's shown in the UI
    // Also want to ignore case as a qol feature
    const s1 = strTransform(lhs).toLowerCase();
    const s2 = strTransform(rhs).toLowerCase();

    if (s1 === s2) {
      return 0;
    }

    // Force ad-hoc properties to the bottom
    if (knowledgeTransform) {
      const s1Id = knowledgeTransform(lhs);
      const s2Id = knowledgeTransform(rhs);

      const s1Deprecated = isDeprecated(s1Id);
      const s2Deprecated = isDeprecated(s2Id);

      if (s1Deprecated && !s2Deprecated) {
        return 1;
      }
      if (s2Deprecated && !s1Deprecated) {
        return -1;
      }
      if (adHocLast) {
        const s1AdHoc = isLocalType(s1Id);
        const s2AdHoc = isLocalType(s2Id);
        if (s1AdHoc && !s2AdHoc) {
          return 1;
        } else if (s2AdHoc && !s1AdHoc) {
          return -1;
        }
      }
    }

    // First check is the longest common prefix, counting each space break as a new string to check against
    const w1 = longestCommonPrefix_parts(s1, search);
    const w2 = longestCommonPrefix_parts(s2, search);

    const w1Max = Math.max(...w1);
    const w2Max = Math.max(...w2);

    if (w1Max != w2Max) {
      return w2Max - w1Max;
    } else {
      // In case of a tie, whichever string matches the search starting the earliest wins
      let i = 0;
      while (i < w1.length && i < w2.length) {
        if (w1[i] == w1Max && w2[i] != w2Max) {
          return -1;
        } else if (w2[i] == w2Max && w1[i] != w1Max) {
          return 1;
        }

        i += 1;
      }
    }

    // If it's still a tie, just use normal string comparison
    return s1.localeCompare(s2);
  };
}

// Copy the default vSelect filter, but add a sort in there
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function vsFilterSort(knowledgeTransform?: (x: any) => string) {
  return function (this: VueSelectInstance, options: Array<DropdownOption>, search: string) {
    const filteredOptions = options
      .filter((option) => {
        const label = this.getOptionLabel(option);
        return this.filterBy(option, label, search);
      })
      .sort(sortHeuristic(search, this.getOptionLabel.bind(this), knowledgeTransform));

    return filteredOptions;
  };
}

/**
 * A version of sortHeuristic just for sorting knowledge items.
 */
export function knowledgeSortHeuristic(lhs: KnowledgeItem, rhs: KnowledgeItem): number {
  return sortHeuristic<KnowledgeItem>("", (k) => k.label, knowledgeItemId, false)(lhs, rhs);
}
