import { defineComponent as _defineComponent } from 'vue'
import Textbox from "@/common/components/Textbox.vue";
import TextButton from "@/common/components/TextButton.vue";
import { FullWorkspace, ProviderType, useWorkspaceStore } from "@/common/stores/workspaceStore";
import { computed, onMounted, ref } from "vue";
import SpinnerButton from "../SpinnerButton.vue";
import Dialog from "@/common/components/Dialog.vue";
import { BackendError } from "@/common/http/http";
import { isObject, isString } from "lodash";

interface StarRocksWorkspace extends FullWorkspace {
  credentials: {
    host: string;
    port: number;
    user: string;
    password: string;
    personal_access_token: string;
  };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StarRocksConnectionSettings',
  props: {
    currentWorkspace: { type: Object, required: false },
    mode: { type: String, required: true }
  },
  emits: ["saveWorkspace", "cancel"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const workspaceStore = useWorkspaceStore();
const workspace = ref<StarRocksWorkspace>(newWorkspace());

const props = __props;
const emits = __emit;

const saving = ref(false);
const errorDialogMessage = ref<string | undefined>();

const saveEnabled = computed(() => {
  const metadata = workspace.value.metadata;
  const creds = workspace.value.credentials;
  return (
    metadata.nickname && creds.host && creds.user && creds.password && creds.personal_access_token
  );
});

const commitLabel = computed(() => {
  if (saving.value) {
    return props.mode === "edit" ? "Updating…" : "Connecting…";
  }
  return props.mode === "edit" ? "Update" : "Connect";
});

function cancelEditWorkspace() {
  emits("cancel");
}

async function saveWorkspace() {
  saving.value = true;
  try {
    workspace.value.metadata.id = "starrocks-" + workspace.value.credentials.host;
    await workspaceStore.saveWorkspace(workspace.value);
    emits("saveWorkspace");
  } catch (error: unknown) {
    if (error instanceof BackendError) {
      const response = error.failure?.response;
      if (isObject(response) && "cause" in response && isString(response.cause)) {
        errorDialogMessage.value = response.cause;
      }
      return;
    }
  } finally {
    saving.value = false;
  }
}

function newWorkspace(): StarRocksWorkspace {
  return {
    metadata: {
      nickname: "",
      id: "",
      connected: true,
      current_warehouse_id: undefined,
      provider: ProviderType.StarRocks,
    },
    credentials: {
      host: "",
      port: 9030,
      user: "",
      password: "",
      personal_access_token: "",
    },
  };
}

onMounted(() => {
  if (props.currentWorkspace) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    workspace.value = props.currentWorkspace as any;
  }
});

const __returned__ = { workspaceStore, workspace, props, emits, saving, errorDialogMessage, saveEnabled, commitLabel, cancelEditWorkspace, saveWorkspace, newWorkspace, Textbox, TextButton, SpinnerButton, Dialog }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})