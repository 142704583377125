import { defineComponent as _defineComponent } from 'vue'
import { GraphValue, stringifyValue } from "@/common/lib/value";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StringValue',
  props: {
    value: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { value } = toRefs(props);

const string = computed(() => stringifyValue(value.value));

const __returned__ = { props, value, string }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})