import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, useSlots } from "vue";
import Icon from "./Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Toggle',
  props: {
    label: { type: String, required: false },
    size: { type: String, required: false, default: "s" },
    modelValue: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

const slots = useSlots();

const showDescription = ref(false);

function toggleDescription() {
  showDescription.value = !showDescription.value;
}

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const __returned__ = { props, emit, slots, showDescription, toggleDescription, value, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})