import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "input peer flex w-full flex-col items-start self-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label && ($setup.value || $props.placeholder))
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["text-s flex items-start gap-5 pb-5 pr-0 pt-5 font-bold text-gray-500 peer-focus:text-orange", $setup.requiredText])
        }, _toDisplayString($props.label), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["vSelect"], {
      class: "dropdown-options self-stretch",
      options: $props.options,
      modelValue: $setup.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
      placeholder: $props.placeholder || $props.label,
      label: $setup.isString($props.optionLabel) ? $props.optionLabel : undefined,
      "get-option-label": $setup.isFunction($props.optionLabel) ? $props.optionLabel : undefined,
      reduce: $props.optionValue,
      disabled: $props.disabled,
      clearable: $props.clearable,
      multiple: $props.multiple,
      filter: $props.filter
    }, {
      option: _withCtx((option) => [
        _renderSlot(_ctx.$slots, "option-data", _normalizeProps(_guardReactiveProps(option)))
      ]),
      "selected-option": _withCtx((option) => [
        ($setup.slots.selectedOptionData)
          ? _renderSlot(_ctx.$slots, "selected-option-data", _normalizeProps(_mergeProps({ key: 0 }, option)))
          : _renderSlot(_ctx.$slots, "option-data", _normalizeProps(_mergeProps({ key: 1 }, option)))
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["options", "modelValue", "placeholder", "label", "get-option-label", "reduce", "disabled", "clearable", "multiple", "filter"])
  ]))
}