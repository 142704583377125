import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  class: "dialog",
  style: {"z-index":"3000"}
}
const _hoisted_4 = { class: "failure" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "flex gap-10 break-all text-xxl font-bold" }
const _hoisted_7 = { class: "mx-0 my-10 max-h-[300px] overflow-y-scroll text-xxl" }
const _hoisted_8 = {
  key: 0,
  class: "mx-0 my-10 text-xxl"
}
const _hoisted_9 = { class: "buttons" }
const _hoisted_10 = { class: "buttons" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "buttons" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.failure)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#modal-container"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "shade" }, null, -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode($setup["Icon"], {
                      name: "error",
                      size: "xl"
                    }),
                    _createTextVNode(_toDisplayString($setup.failure.title), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_7, _toDisplayString($setup.failure.message), 1 /* TEXT */),
                  ($setup.environment.requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, " We track these errors automatically, but please feel free to add more information about what caused the error. "))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", _hoisted_9, [
                    ($setup.environment.requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING'))
                      ? (_openBlock(), _createBlock($setup["TextButton"], {
                          key: 0,
                          label: "Add Details",
                          icon: "settings_applications",
                          selected: $setup.showBugReporter,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showBugReporter = !$setup.showBugReporter))
                        }, null, 8 /* PROPS */, ["selected"]))
                      : _createCommentVNode("v-if", true),
                    ($setup.environment.requireBoolean('FAILURE_DIALOG_SHOW_ERROR_DETAILS'))
                      ? (_openBlock(), _createBlock($setup["TextButton"], {
                          key: 1,
                          label: "View Details",
                          icon: "settings_applications",
                          selected: $setup.showErrorDetails,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showErrorDetails = !$setup.showErrorDetails))
                        }, null, 8 /* PROPS */, ["selected"]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_10, [
                      (!$setup.failure.hideUndo && $setup.canUndo())
                        ? (_openBlock(), _createBlock($setup["TextButton"], {
                            key: 0,
                            label: "Undo",
                            onClick: $setup.handleUndo
                          }))
                        : _createCommentVNode("v-if", true),
                      _createVNode($setup["TextButton"], {
                        label: "Ok",
                        onClick: $setup.close
                      })
                    ])
                  ])
                ]),
                ($setup.environment.requireBoolean('FAILURE_DIALOG_SHOW_ERROR_DETAILS'))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      ($setup.showErrorDetails)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _withDirectives(_createElementVNode("textarea", {
                              class: "details",
                              ref: "copyableText",
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.details) = $event))
                            }, null, 512 /* NEED_PATCH */), [
                              [_vModelText, $setup.details]
                            ]),
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode($setup["TextButton"], {
                                label: "Copy Bug Report",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.copyReport()))
                              })
                            ])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
                ($setup.environment.requireBoolean('FAILURE_DIALOG_SHOW_USER_REPORTING'))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      ($setup.showBugReporter && $setup.failure.eventId)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode($setup["Textbox"], {
                              textarea: true,
                              "min-height": "100px",
                              label: "Details",
                              modelValue: $setup.userBugReport,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.userBugReport) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode($setup["TextButton"], {
                                label: "Submit Bug Report",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.submitBugReport($setup.failure!.eventId!))),
                                disabled: $setup.userBugReport === ''
                              }, null, 8 /* PROPS */, ["disabled"])
                            ])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}