import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Label',
  props: {
    label: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    focus: { type: Boolean, required: false },
    required: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const requiredText = computed(() => {
  if (props.required) {
    return 'after:content-["*"]';
  } else {
    return "";
  }
});

const __returned__ = { props, requiredText }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})