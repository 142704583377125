import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import IconButton from "@/common/components/IconButton.vue";
import useKnowledge from "@/common/composables/useKnowledge";
import { ConceptAddress, conceptAddress, readerConceptTitle } from "@/reader/lib/concept";
import pluralize from "pluralize";
import { computed, toRefs } from "vue";
import useQuery from "@/reader/composables/useQuery";
import { useAppStore } from "@/reader/stores/app";
import { AsyncStatus } from "@/common/lib/async";
import Spinner from "@/common/components/Spinner.vue";
import { Query } from "@/common/lib/query";


export default /*@__PURE__*/_defineComponent({
  __name: 'NeighborhoodSummary',
  props: {
    query: { type: Object, required: true }
  },
  emits: ["navigate", "pivot"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const { query } = toRefs(props);
const emit = __emit;

const { getConceptIconName, typeLabel } = useKnowledge();
const appStore = useAppStore();

const { queryResults, rootConcepts } = useQuery(
  appStore.module!,
  () => query.value,
  () => appStore.map
);

const conceptType = () => query.value.root_concept_type;

const conceptCount = computed(() => rootConcepts().length);
const conceptIconName = computed(() => getConceptIconName(conceptType()));
const conceptTypeLabel = computed(() => pluralize(typeLabel(conceptType()), conceptCount.value));

const neighbors = computed(function () {
  return rootConcepts().map((concept) => ({
    key: concept.id,
    title: readerConceptTitle(concept),
    address: conceptAddress(concept),
  }));
});

function clickLink(address?: ConceptAddress) {
  if (address != null) emit("navigate", address);
}

const __returned__ = { props, query, emit, getConceptIconName, typeLabel, appStore, queryResults, rootConcepts, conceptType, conceptCount, conceptIconName, conceptTypeLabel, neighbors, clickLink, Icon, IconButton, get AsyncStatus() { return AsyncStatus }, Spinner }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})