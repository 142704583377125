import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { rootColumns } from "@/reader/lib/explore";
import { computed, inject, Ref } from "vue";
import vSelect from "vue-select";
import { DarkMode } from "@/common/lib/keys";
import { propertyName } from "@/common/lib/derived";
import { QueryGroupBy } from "@/common/lib/query";


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupBy',
  props: {
    "modelValue": { type: [Object, null], ...{ required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const darkMode = inject(DarkMode) as Ref<boolean>;
const model = _useModel<QueryGroupBy | null>(__props, "modelValue");

const options = computed(() =>
  rootColumns().map((column) => ({
    property_type: column.property_type,
  }))
);

function groupByLabel(groupBy: QueryGroupBy) {
  return propertyName(groupBy.property_type, groupBy.path);
}

const __returned__ = { darkMode, model, options, groupByLabel, get vSelect() { return vSelect } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})