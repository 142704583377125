import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { useExploreStore } from "@/reader/stores/explore";
import { Ref, computed, inject, ref, watchEffect } from "vue";
import vSelect from "vue-select";
import { find } from "lodash";
import IconButtonV2 from "@/common/components/IconButtonV2.vue";
import { DarkMode } from "@/common/lib/keys";
import { QueryColumn, QueryOrderBy } from "@/common/lib/query";
import { FetchNOrderBy } from "@/common/lib/fetchApi";
import { columnName } from "@/common/lib/query";


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderBy',
  props: {
    "modelValue": { type: [Object, null], ...{ required: true } },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const darkMode = inject(DarkMode) as Ref<boolean>;

const orderBy: Ref<Partial<QueryOrderBy>> = ref({});
const model = _useModel<QueryOrderBy | null>(__props, "modelValue");

const exploreStore = useExploreStore();

const selectedColumn = computed(function () {
  if (orderBy.value.on == null) return undefined;
  return find(exploreStore.query!.columns, { alias: orderBy.value.on });
});

function toggleAsc() {
  updateOrderBy({ asc: !orderBy.value.asc });
}

function updateColumn(col: QueryColumn) {
  updateOrderBy({ on: col.alias });
}

function updateOrderBy(changes: Partial<FetchNOrderBy>) {
  const newValue = { ...orderBy.value, ...changes };
  orderBy.value = newValue;
  if (newValue.asc != null && newValue.on != null) model.value = newValue as FetchNOrderBy;
}

watchEffect(
  () =>
    (orderBy.value = {
      on: model.value?.on,
      asc: model.value?.asc ?? true,
    })
);

const __returned__ = { darkMode, orderBy, model, exploreStore, selectedColumn, toggleAsc, updateColumn, updateOrderBy, get vSelect() { return vSelect }, IconButtonV2, get columnName() { return columnName } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})