import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-0 flex items-center px-[6px] py-0" }
const _hoisted_2 = { class: "h-[32px] w-[32px] rounded-2xl p-4 hover:bg-gray-200 dark:hover:bg-gray-900" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
          id: $props.id,
          class: "mr-10"
        }, null, 8 /* PROPS */, _hoisted_3), [
          [_vModelCheckbox, $setup.value]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString($props.label), 1 /* TEXT */)
    ])
  ]))
}