import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["TextButton"], {
          secondary: true,
          label: "Cancel",
          onClick: $setup.cancel
        }),
        _createVNode($setup["TextButton"], {
          label: $props.mode === 'create' ? 'Create' : 'Save',
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('save'))),
          secondary: !$props.needsSave,
          disabled: !$props.canSave
        }, null, 8 /* PROPS */, ["label", "secondary", "disabled"])
      ])
    ]),
    ($setup.showConfirmDialog)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          title: "Discard changes?",
          "success-label": "Discard",
          onSucceeded: $setup.handleConfirmClose,
          onCancelled: $setup.handleCancelClose,
          icon: "md:warning"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.label ? `You have unsaved changes to ${$props.label}.` : "You have unsaved changes.") + " ", 1 /* TEXT */),
            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
            _cache[2] || (_cache[2] = _createTextVNode(" Are you sure you want to discard them? "))
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}