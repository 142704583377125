import { defineComponent as _defineComponent } from 'vue'
import { isNil } from "lodash";
import { computed, ref } from "vue";
import TextButton from "../TextButton.vue";
import Dialog from "../Dialog.vue";
import Textbox from "../Textbox.vue";
import Spinner from "../Spinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminSetting',
  props: {
    label: { type: String, required: true },
    value: { type: String, required: false },
    readonly: { type: Boolean, required: false, default: false },
    copyable: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false }
  },
  emits: ["update"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const editMode = ref(false);
const valueToEdit = ref("");

const formattedValue = computed(() => props.value ?? "None");

function saveDialog() {
  emit("update", valueToEdit.value);
  closeDialog();
}

function openDialog() {
  editMode.value = true;
  valueToEdit.value = props.value ?? "";
}

function closeDialog() {
  editMode.value = false;
}

const __returned__ = { props, emit, editMode, valueToEdit, formattedValue, saveDialog, openDialog, closeDialog, get isNil() { return isNil }, TextButton, Dialog, Textbox, Spinner }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})