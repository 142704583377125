import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, ref, watch, Ref, inject } from "vue";
import Icon from "./Icon.vue";
import { DarkMode } from "../lib/keys";

export default /*@__PURE__*/_defineComponent({
  __name: 'Textbox',
  props: {
    label: { type: String, required: false },
    helperText: { type: String, required: false },
    errorText: { type: String, required: false },
    modelValue: { type: [String, Number], required: false },
    type: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    textarea: { type: Boolean, required: false },
    minHeight: { type: String, required: false },
    maxHeight: { type: String, required: false },
    autocomplete: { type: String, required: false },
    autoselect: { type: Boolean, required: false },
    required: { type: Boolean, required: false },
    id: { type: String, required: false }
  },
  emits: ["update:modelValue", "blur", "keyup.enter", "focus"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const emit = __emit;
const el = ref<HTMLTextAreaElement | undefined>(undefined);
const darkMode = inject(DarkMode) as Ref<boolean>;

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

if (props.textarea) {
  watch([value, el], () => {
    if (!el.value) {
      return;
    }
    // Make tiny to force shrinking when text is deleted
    el.value.style["height"] = "1px";
    const height = `${el.value.scrollHeight}px`;
    el.value.style["height"] = height;
  });
}

function focus() {
  el.value?.focus();
  el.value?.select();
}

__expose({
  focus,
});

onMounted(() => {
  if (props.autoselect && el.value) {
    focus();
  }
});

const requiredText = computed(() => {
  if (props.required) {
    return 'after:content-["*"]';
  } else {
    return "";
  }
});

const __returned__ = { props, emit, el, darkMode, value, focus, requiredText, Icon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})