import { defineComponent as _defineComponent } from 'vue'
import { Async, AsyncStatus } from "@/common/lib/async";
import { toRefs } from "vue";
import Spinner from "./Spinner.vue";
import WarehouseLoading from "./WarehouseLoading.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AsyncWrapper',
  props: {
    async: { type: null, required: true },
    overlay: { type: Boolean, required: false, default: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { async } = toRefs(props);

const __returned__ = { props, async, get AsyncStatus() { return AsyncStatus }, Spinner, WarehouseLoading }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})