import { defineComponent as _defineComponent } from 'vue'
import { useAuthProvider } from "@/common/auth/authStore";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Logout',
  setup(__props, { expose: __expose }) {
  __expose();

const authProvider = useAuthProvider();

async function goHome() {
  await authProvider.loginWithRedirect();
}

const __returned__ = { authProvider, goHome, Start, TextButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})