import { defineComponent as _defineComponent } from 'vue'
import { loadHubspotTracking } from "@/common/lib/hubspot";
import FailureDialog from "@/common/components/FailureDialog.vue";
import { onMounted, provide, ref } from "vue";
import { DarkMode } from "@/common/lib/keys";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props, { expose: __expose }) {
  __expose();

const darkMode = ref(true);
provide(DarkMode, darkMode);

onMounted(() => {
  if (darkMode.value) document.querySelector("body")?.classList.add("dark");
  loadHubspotTracking();
});

const __returned__ = { darkMode, FailureDialog }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})