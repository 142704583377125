import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "absolute inset-0 flex flex-col dark:bg-gray-800" }
const _hoisted_2 = { class: "relative basis-full overflow-y-auto p-10" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "p-20 text-center text-xl text-gray-500"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TabSelect"], {
      tabs: $setup.tabs,
      modelValue: $setup.exploreStore.activeTool,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.exploreStore.activeTool) = $event)),
      class: "border-b border-b-gray-200 light:bg-gray-100 dark:border-b-gray-900"
    }, null, 8 /* PROPS */, ["tabs", "modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      ($setup.exploreStore.activeTool === $setup.Tool.Concept)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            ($setup.exploreStore.conceptPage)
              ? (_openBlock(), _createBlock($setup["ConceptPage"], {
                  key: 0,
                  address: $setup.exploreStore.conceptPage,
                  onNavigate: $setup.exploreStore.showConceptPage,
                  onPivot: $setup.pivot
                }, null, 8 /* PROPS */, ["address", "onNavigate"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, " Click on an underlined value and choose the \"View\" menu item to see Concept details here. "))
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.exploreStore.activeTool === $setup.Tool.Insights)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "p-20 text-center text-xl text-gray-500" }, " No visualizations are available for the current perspective. ", -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}