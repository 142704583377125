import { createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-10" }
const _hoisted_2 = { class: "my-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("label", { class: "mb-5 block" }, "Dividend:", -1 /* HOISTED */)),
      _createVNode($setup["DerivedPropertyDef"], {
        "parent-op": $setup.OP,
        modelValue: $setup.dividend,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dividend) = $event))
      }, null, 8 /* PROPS */, ["parent-op", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { class: "mb-5 block" }, "Divisor:", -1 /* HOISTED */)),
      _createVNode($setup["DerivedPropertyDef"], {
        "parent-op": $setup.OP,
        modelValue: $setup.divisor,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.divisor) = $event))
      }, null, 8 /* PROPS */, ["parent-op", "modelValue"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}