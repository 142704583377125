import { defineComponent as _defineComponent } from 'vue'
import { ExistenceFilter } from "@/common/lib/fetchApi";
import { QueryFilter } from "@/common/lib/query";
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExistenceFilter',
  props: {
    filter: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { filter } = toRefs(props);

const opDescription = computed(function () {
  return filter.value.negated ? "has no value" : "has any value";
});

const __returned__ = { props, filter, opDescription }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})