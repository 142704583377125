import { defineComponent as _defineComponent } from 'vue'
import Icon from "@/common/components/Icon.vue";
import { ProviderType, useWorkspaceStore, Warehouse, Workspace } from "../stores/workspaceStore";
import { storeToRefs } from "pinia";
import { environment } from "@/common/environments/environmentLoader";
import { AsyncStatus } from "../lib/async";
import Select from "@/common/components/Select.vue";
import Spinner from "./Spinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkspaceChooser',
  setup(__props, { expose: __expose }) {
  __expose();

const workspaceStore = useWorkspaceStore();
const { workspaces, currentWorkspaceId } = storeToRefs(workspaceStore);

function providerIcon(provider?: ProviderType): string | undefined {
  const key = "PROVIDER_LOGO_" + provider?.toUpperCase();
  return environment.get(key) ?? environment.require("PROVIDER_LOGO_DEFAULT");
}

async function selectWorkspace(workspaceName: string) {
  await workspaceStore.setCurrentWorkspace(workspaceName);
}

const __returned__ = { workspaceStore, workspaces, currentWorkspaceId, providerIcon, selectWorkspace, Icon, get AsyncStatus() { return AsyncStatus }, Select, Spinner }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})