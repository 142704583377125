import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0"
}
const _hoisted_2 = { class: "item-start flex flex-col justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100" }
const _hoisted_3 = {
  key: 0,
  class: "m-10 flex flex-col justify-between gap-10"
}
const _hoisted_4 = {
  key: 1,
  class: "m-10 flex flex-col justify-between gap-10"
}
const _hoisted_5 = { class: "flex items-center justify-end gap-10 self-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.mode === 'create')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[9] || (_cache[9] = _createElementVNode("span", null, " Select your data provider: ", -1 /* HOISTED */)),
            (!$setup.selectedProvider)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  ($setup.enabledProviders.includes('dbx'))
                    ? (_openBlock(), _createBlock($setup["ProviderButton"], {
                        key: 0,
                        "img-src": "/images/providers/dbx/databricks-wide.svg",
                        height: "40px",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.selectedProvider = $setup.ProviderType.Databricks))
                      }))
                    : _createCommentVNode("v-if", true),
                  ($setup.enabledProviders.includes('snowflake'))
                    ? (_openBlock(), _createBlock($setup["ProviderButton"], {
                        key: 1,
                        "img-src": "/images/providers/snowflake/snowflake-wide.svg",
                        height: "50px",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.selectedProvider = $setup.ProviderType.Snowflake))
                      }))
                    : _createCommentVNode("v-if", true),
                  ($setup.enabledProviders.includes('mysql'))
                    ? (_openBlock(), _createBlock($setup["ProviderButton"], {
                        key: 2,
                        "img-src": "/images/providers/mysql/mysql-wide.svg",
                        height: "100px",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.selectedProvider = $setup.ProviderType.MySQL))
                      }))
                    : _createCommentVNode("v-if", true),
                  ($setup.enabledProviders.includes('starrocks'))
                    ? (_openBlock(), _createBlock($setup["ProviderButton"], {
                        key: 3,
                        "img-src": "/images/providers/starrocks/starrocks-wide.svg",
                        height: "35px",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.selectedProvider = $setup.ProviderType.StarRocks))
                      }))
                    : _createCommentVNode("v-if", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  ($setup.selectedProvider === $setup.ProviderType.Databricks)
                    ? (_openBlock(), _createBlock($setup["ProviderButton"], {
                        key: 0,
                        "img-src": "/images/providers/dbx/databricks-wide.svg",
                        height: "40px",
                        static: true
                      }))
                    : _createCommentVNode("v-if", true),
                  ($setup.selectedProvider === $setup.ProviderType.Snowflake)
                    ? (_openBlock(), _createBlock($setup["ProviderButton"], {
                        key: 1,
                        "img-src": "/images/providers/snowflake/snowflake-wide.svg",
                        height: "50px",
                        static: true
                      }))
                    : _createCommentVNode("v-if", true),
                  ($setup.selectedProvider == $setup.ProviderType.MySQL)
                    ? (_openBlock(), _createBlock($setup["ProviderButton"], {
                        key: 2,
                        "img-src": "/images/providers/mysql/mysql-wide.svg",
                        height: "100px",
                        static: true
                      }))
                    : _createCommentVNode("v-if", true),
                  ($setup.selectedProvider == $setup.ProviderType.StarRocks)
                    ? (_openBlock(), _createBlock($setup["ProviderButton"], {
                        key: 3,
                        "img-src": "/images/providers/starrocks/starrocks-wide.svg",
                        height: "35px",
                        static: true
                      }))
                    : _createCommentVNode("v-if", true)
                ]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[10] || (_cache[10] = _createElementVNode("div", null, null, -1 /* HOISTED */)),
            ($setup.selectedProvider === undefined && $setup.workspacesState !== $setup.WorkspacesState.NoWorkspaces)
              ? (_openBlock(), _createBlock($setup["TextButton"], {
                  key: 0,
                  label: "Cancel",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.cancelEditWorkspace()))
                }))
              : _createCommentVNode("v-if", true)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.selectedProvider === 'dbx')
      ? (_openBlock(), _createBlock($setup["DbxEditConnectionSettings"], {
          key: 1,
          "current-workspace": $setup.workspaceSettings,
          mode: $props.mode,
          onCancel: $setup.cancelEdit,
          onSaveWorkspace: _cache[5] || (_cache[5] = ($event: any) => ($setup.cancelEditWorkspace()))
        }, null, 8 /* PROPS */, ["current-workspace", "mode"]))
      : _createCommentVNode("v-if", true),
    ($setup.selectedProvider === 'snowflake')
      ? (_openBlock(), _createBlock($setup["SnowflakeEditConnectionSettings"], {
          key: 2,
          "current-workspace": $setup.workspaceSettings,
          mode: $props.mode,
          onCancel: $setup.cancelEdit,
          onSaveWorkspace: _cache[6] || (_cache[6] = ($event: any) => ($setup.cancelEditWorkspace()))
        }, null, 8 /* PROPS */, ["current-workspace", "mode"]))
      : _createCommentVNode("v-if", true),
    ($setup.selectedProvider === 'mysql')
      ? (_openBlock(), _createBlock($setup["MySqlEditConnectionSettings"], {
          key: 3,
          "current-workspace": $setup.workspaceSettings,
          mode: $props.mode,
          onCancel: $setup.cancelEdit,
          onSaveWorkspace: _cache[7] || (_cache[7] = ($event: any) => ($setup.cancelEditWorkspace()))
        }, null, 8 /* PROPS */, ["current-workspace", "mode"]))
      : _createCommentVNode("v-if", true),
    ($setup.selectedProvider === 'starrocks')
      ? (_openBlock(), _createBlock($setup["StarRocksConnectionSettings"], {
          key: 4,
          "current-workspace": $setup.workspaceSettings,
          mode: $props.mode,
          onCancel: $setup.cancelEdit,
          onSaveWorkspace: _cache[8] || (_cache[8] = ($event: any) => ($setup.cancelEditWorkspace()))
        }, null, 8 /* PROPS */, ["current-workspace", "mode"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}