import { defineComponent as _defineComponent } from 'vue'
import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Metalink',
  props: {
    linkType: { type: String, required: true },
    fromX: { type: Number, required: true },
    fromY: { type: Number, required: true },
    toX: { type: Number, required: true },
    toY: { type: Number, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { fromX, toX, fromY, toY } = toRefs(props);

const path = computed(() => `M ${fromX.value},${fromY.value} L ${toX.value},${toY.value}`);

const __returned__ = { props, fromX, toX, fromY, toY, path }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})